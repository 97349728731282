import React, {useContext} from "react";
import {Alert} from "react-bootstrap";
import {useListSecurityPolicies} from "../../api/SecurityPolicy";
import AccountContext from "../../components/context/AccountContext";
import {ReactQuerySpinner} from "../../components/common/ReactQueryLoader";
import {useNavigate} from "react-router-dom";
import {buildRoute} from "../../routes/utils";
import {MainRoutes} from "../../routes/MainRoutes";
import {GovernanceRoutes} from "../../routes/GovernanceRoutes";
import {SecurityPolicyRoutes} from "../../routes/SecurityPolicyRoutes";
import {dateTimeCmp, sortKey} from "../../helpers/utils";
import {DateTime} from "luxon";

export default function SecurityPolicyPage() {
  const {account} = useContext(AccountContext);
  const navigate = useNavigate();
  const securityPoliciesResult = useListSecurityPolicies(
  undefined,
  {account_id: account.id},
  {
    onSuccess: (securityPoliciesPage) => {
      // Check for active security policies (there should only be one)
      const activeSecurityPolicies = securityPoliciesPage.results.filter(sp => sp.active);
      if (activeSecurityPolicies.length > 0) {
        navigate(buildRoute(MainRoutes.GOVERNANCE, GovernanceRoutes.SECURITY_POLICY, SecurityPolicyRoutes.DETAIL).replace(":securityPolicyId", activeSecurityPolicies[0].id))
      }

      // Then check for the most recently updated security policy
      const sortedByUpdatedDatetimeSecurityPolicies = securityPoliciesPage.results.sort((sp1, sp2) => {
        const sp1Dt = DateTime.fromISO(sp1.updated_datetime);
        const sp2Dt = DateTime.fromISO(sp2.updated_datetime);
        console.log("Comparing", sp1Dt, sp2Dt, dateTimeCmp(sp1Dt, sp2Dt));
        return -dateTimeCmp(DateTime.fromISO(sp1.updated_datetime), DateTime.fromISO(sp2.updated_datetime))
      })
      if (sortedByUpdatedDatetimeSecurityPolicies.length > 0) {
        navigate(buildRoute(MainRoutes.GOVERNANCE, GovernanceRoutes.SECURITY_POLICY, SecurityPolicyRoutes.DETAIL).replace(":securityPolicyId", sortedByUpdatedDatetimeSecurityPolicies[0].id), {replace: true})
      }
      else {
        navigate(buildRoute(MainRoutes.GOVERNANCE, GovernanceRoutes.SECURITY_POLICY, SecurityPolicyRoutes.NEW), {replace: true})
      }
    }
  })

  if (securityPoliciesResult.isError) {
    return (
      <main>
        <Alert variant="danger">
          An error occurred retrieving the security policies.
        </Alert>
      </main>
    )
  }

  return (
    <main>
      <div className="d-flex justify-content-center">
        <ReactQuerySpinner />
      </div>
    </main>
  )
}
