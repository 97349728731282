import {useLocation} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {Navbar} from "react-bootstrap";
import classNames from "classnames";
import Logo, {LogoLocation} from "../../common/Logo";
import TopNavRightSideNavItem from "./TopNavRightSideNavItem";
import AppContext from "../../../context/AppContext";

export default function NavbarTop() {
  const {
    showBurgerMenu,
    setShowBurgerMenu,
  } = useContext(AppContext);

  const { pathname } = useLocation();
  const isChat = pathname.includes('chat');

  const [showDropShadow, setShowDropShadow] = useState(false);

  const handleBurgerMenu = () => {
    setShowBurgerMenu(!showBurgerMenu);
  };

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  return (
    <Navbar
      className={classNames('navbar-glass  fs--1 navbar-top sticky-kit', {
        // 'navbar-glass-shadow': showDropShadow
        'navbar-glass-shadow': showDropShadow && !isChat
      })}
      expand={"lg"}
    >
      <Navbar.Toggle
        className={classNames('toggle-icon-wrapper me-md-3 me-2', {
          'd-xl-none': true,
        })}
        as="div"
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={handleBurgerMenu}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </Navbar.Toggle>

      <Logo at={LogoLocation.NavbarTop} width={40} id="topLogo" />

      {/*<Nav*/}
      {/*  navbar*/}
      {/*  className={`align-items-center d-none d-lg-block`}*/}
      {/*  as="ul"*/}
      {/*>*/}
      {/*  <Nav.Item as="li">*/}
      {/*    <SearchBox />*/}
      {/*  </Nav.Item>*/}
      {/*</Nav>*/}

      <TopNavRightSideNavItem />
    </Navbar>
  );
};
