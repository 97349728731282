import {Alert, Card, Dropdown} from "react-bootstrap";
import FalconCardHeader from "../falcon/common/FalconCardHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {Subscription} from "../../api/Subscription";
import {DateTime, Duration} from "luxon";
import {timeUntil} from "../../helpers/utils";
import {buildRoute} from "../../routes/utils";
import {MainRoutes} from "../../routes/MainRoutes";


function TrialEndsNotification({timeUntilEnd}: {timeUntilEnd: Duration}) {
  const daysLeft = timeUntilEnd.as("days");
  let notificationType;
  if (daysLeft > 7) {
    notificationType = "info";
  }
  else if (daysLeft > 2) {
    notificationType = "warning";
  }
  else {
    notificationType = "danger";
  }

  return (
    <div className="mb-4">
      <Alert variant={notificationType} className="rounded-0 border-start-0 border-end-0">{`Your trial ends in ${timeUntilEnd.reconfigure({locale: "en"}).toHuman()}`}</Alert>
    </div>
  )
}


export default function TrialInformation({subscription}: {subscription: Subscription}) {
  const endDate = subscription.end_date ? DateTime.fromISO(subscription.end_date) : null;
  const timeUntilEnd = endDate ? timeUntil(endDate, undefined, 1) : null;

  return (
    <Card>
      <FalconCardHeader title="You're on the free trial" titleTag="h4" titleClass="fw-bold" />
      <Card.Body className="px-0">
        {
          timeUntilEnd && (
            <TrialEndsNotification timeUntilEnd={timeUntilEnd} />
          )
        }
        <div className="px-4">
          <p>
            We hope you enjoy our services and tools.
          </p>
          <p>
            Select a subscription plan before the end of the trial to continue using them.
          </p>
          <p>
            The plan applies after the end of the trial.
          </p>
          <Dropdown>
            <Dropdown.Toggle
              variant="link"
              size="sm"
              className="ps-0"
              id="#change-plan"
            >
              Choose plan
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="py-3"
              align="start"
              style={{ minWidth: '15rem' }}
            >
              <Dropdown.Item as="div" className="px-3 py-2">
            <span className="d-flex justify-content-between fs--1 text-black">
              <span className="fw-semi-bold">Starter plan</span>
              <span className="text-decoration-line-through mx-2 text-600">€40.00</span>
              <span>€20.00</span>
            </span>
                <ul className="list-unstyled ps-1 my-2 fs--1">
                  <li>
                    <span className="text-success me-1"><FontAwesomeIcon icon={faCheck} /></span>
                    <span className="ms-1">1 account</span>
                  </li>
                  <li>
                    <span className="text-success me-1"><FontAwesomeIcon icon={faCheck} /></span>
                    <span className="ms-1">5 users</span>
                  </li>
                  <li>
                    <span className="text-success me-1"><FontAwesomeIcon icon={faCheck} /></span>
                    <span className="ms-1">standard support</span>
                  </li>
                </ul>
                <p className="fs--2 mb-0">
                  <Link to={buildRoute(MainRoutes.PRICING)}>View details</Link>
                </p>
              </Dropdown.Item>
              <Dropdown.Divider className="my-0" />
              <Dropdown.Item as="div" className="px-3 py-2">
            <span className="d-flex justify-content-between fs--1 text-black">
              <span className="fw-semi-bold">Enterprise plan</span>
              <span className="text-decoration-line-through mx-2 text-600">€200.00</span>
              <span>€100.00</span>
            </span>
                <ul className="list-unstyled ps-1 my-2 fs--1">
                  <li>
                    <span className="text-success me-1"><FontAwesomeIcon icon={faCheck} /></span>
                    <span className="ms-1">multiple accounts</span>
                  </li>
                  <li>
                    <span className="text-success me-1"><FontAwesomeIcon icon={faCheck} /></span>
                    <span className="ms-1">unlimited users</span>
                  </li>
                  <li>
                    <span className="text-success me-1"><FontAwesomeIcon icon={faCheck} /></span>
                    <span className="ms-1">premium support</span>
                  </li>
                </ul>
                <p className="fs--2 mb-0">
                  <Link to={buildRoute(MainRoutes.PRICING)}>View details</Link>
                </p>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card.Body>
    </Card>
  )
}
