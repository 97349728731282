import React from "react";
import {timeSince} from "../../helpers/utils";
import {DateTime} from "luxon";
import classNames from "classnames";
import Flex from "../falcon/common/Flex";
import Avatar from "../falcon/common/Avatar";
import {faCircleUser} from "@fortawesome/free-solid-svg-icons";
import SoftBadge from "../falcon/common/SoftBadge";
import {User} from "../../api/User";
import {UserWithUserRoles} from "../../api/UserRole";
import {MembersRowActiveRow} from "./MembersRowActiveRow";

interface MembersRowProps {
  user: User;
  userWithUserRoles?: UserWithUserRoles;
  isActive: boolean;
  onClick: () => void;
  isLast: boolean;
}

export function MembersRow({user, userWithUserRoles, isActive, onClick, isLast}: MembersRowProps) {

  const timeSinceLastLogin = user.last_login ? timeSince(DateTime.fromISO(user.last_login), undefined, 1) : null;
  let lastLoginColor: "warning" | "danger" | "success" | undefined = "danger";
  if (timeSinceLastLogin) {
    if (timeSinceLastLogin.as("days") < 14) {
      lastLoginColor = "success";
    } else if (timeSinceLastLogin.as("days") < 30) {
      lastLoginColor = "warning";
    }
  }

  const onClickInternal = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    onClick();
  }

  return (
    <>
      <tr
        className={
          classNames(
            "position-relative",
            {
              'border-bottom border-200': !isLast && !isActive,
              "bg-soft-primary": isActive,
            }
          )
        }
      >
        <td>
          <Flex alignItems="center">
            <a href="/settings/" className="stretched-link" onClick={onClickInternal}>
              <Avatar
                size="2xl"
                src={user.avatar_url}
                icon={faCircleUser}
              />
            </a>
            <div className="flex-1 ms-3">
              <h6 className="mb-0 fw-semi-bold">
                <span className="text-dark">
                  {
                    `${user.first_name} ${user.last_name}`.trim()
                  }
                </span>
              </h6>
              {
                userWithUserRoles && (
                  <p
                    className="fs--2 mb-0 text-500">{userWithUserRoles.user_roles.map(userRole => userRole.role_obj.name).join(", ")}</p>
                )
              }
            </div>
          </Flex>
        </td>
        <td className="align-middle">
          <Flex justifyContent="end">
            <SoftBadge pill bg={lastLoginColor}>
              {
                timeSinceLastLogin
                  ? `${timeSinceLastLogin.reconfigure({locale: "en"}).toHuman()} ago`
                  : "never"
              }
            </SoftBadge>
          </Flex>
        </td>
      </tr>
      {
        isActive && (
          <MembersRowActiveRow user={userWithUserRoles} />
        )
      }
    </>
  )
}