import {PlanFeature} from "../../pricing/PricingDefaultContainer";
import OptionSelectionCol from "../../common/OptionSelectionCol";


export function PriceComponent({price}: {price: number}) {
  return (
    <>
      <span className="fw-normal fs-2 ms-2">€</span>
      {price}
      <small className="fs--1 text-700">/ month</small>
    </>
  )
}

interface PriceHeroComponentProps {
  price: number | string;
  reducedPrice?: number | string;
}

function PriceHeroComponent({price, reducedPrice}: PriceHeroComponentProps) {
  const actualPrice = reducedPrice !== undefined ? reducedPrice : price;

  return (
    <h2 className="mt-4 fw-medium d-flex justify-content-center">
      {
        reducedPrice !== undefined && (
          <div className="text-decoration-line-through text-500">
            {
              typeof price === "string"
                ? price
                : (
                  <>
                    <span className="fw-normal fs-2">€</span>
                    {price}
                  </>
                )
            }
          </div>
        )
      }
      <div>
        {
          typeof actualPrice === "string"
            ? actualPrice
            : <PriceComponent price={actualPrice} />
        }
      </div>
    </h2>
  )
}

interface PricingDefaultCardProps {
  title: string;
  subTitle: string;
  price: number | string;
  reducedPrice?: number | string;
  buttonText?: string;
  isFeatured?: boolean;
  featureTitle?: string;
  colSize?: number;
  features: PlanFeature[];
  onSelect: () => void;
}

export default function PricingDefaultCard({
  title,
  subTitle,
  price,
  reducedPrice,
  buttonText,
  isFeatured,
  featureTitle,
  colSize = 4,
  features,
  onSelect,
}: PricingDefaultCardProps) {
  return (
    <OptionSelectionCol
      title={title}
      description={subTitle}
      heroComponent={<PriceHeroComponent price={price} reducedPrice={reducedPrice}/>}
      buttonText={buttonText}
      isDisabled={false}
      isFeatured={isFeatured}
      featureTitle={featureTitle}
      features={features}
      colSize={colSize}
      onSelect={onSelect}
    />
  )
};
