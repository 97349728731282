import React from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";


interface RootInformationCardProps {
  children: React.ReactNode;
  size?: "sm" | "lg" | "xl" | "xxl";
  title?: string;
  titleTo?: string;
  titleHref?: string;
}

export default function RootInformationCard({size, title, titleTo, titleHref, children}: RootInformationCardProps) {
  return (
    <section className="py-0">
      <div className="container">
        <div className="row flex-center min-vh-100 py-6">
          <div
            className={
            classNames(
              "col col-sm-12",
              {
                "col-xxl-10 col-xl-10 col-lg-12 col-md-12": size === "xxl",
                "col-xxl-8 col-xl-8 col-lg-10 col-md-12": size === "xl",
                "col-xxl-5 col-xl-6 col-lg-8 col-md-12": size === "lg",
                "col-xxl-4 col-xl-5 col-lg-6 col-md-8": size === "sm" || size === undefined,
              }
            )
          }
          >
            {
              title && (
                (titleTo && (
                  <Link className="text-decoration-none" to={titleTo}>
                    <div className="d-flex flex-center fw-bolder fs-5 mb-4">
                      <span className="font-sans-serif">{title}</span>
                    </div>
                  </Link>
                )) || (titleHref && (
                  <a className="text-decoration-none" href={titleHref}>
                    <div className="d-flex flex-center fw-bolder fs-5 mb-4">
                      <span className="font-sans-serif">{title}</span>
                    </div>
                  </a>
                )) || (
                  <span className="text-primary">
                    <div className="d-flex flex-center fw-bolder fs-5 mb-4">
                      <span className="font-sans-serif">{title}</span>
                    </div>
                  </span>
                )
              )
            }

            <div className="card">
              <div className="card-body p-4 p-sm-5">
                <>{children}</>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
