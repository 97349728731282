import {useLocation} from "react-router-dom";

export function buildRoute(...segments: string[]) {
  return segments.reduce((result, segment) => {
    if (segment.startsWith("/")) {
      segment = segment.slice(1);
    }
    if (segment.endsWith("/")) {
      segment = segment.slice(0, segment.length - 1);
    } else if (segment.endsWith("/*")) {
      segment = segment.slice(0, segment.length - 2);
    }
    return result + segment + (segment.length > 0 ? "/" : "");
  }, "/")
}

export function useIsActive(): (...segments: string[]) => boolean {
  const location = useLocation();
  return (...segments: string[]): boolean => {
    const route = buildRoute(...segments);
    if (segments[segments.length - 1].endsWith("*")) {
      return location.pathname.startsWith(route);
    } else {
      return location.pathname === route;
    }
  }
}