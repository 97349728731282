import React, {ComponentType} from "react";
import {useStyles} from "@jsonforms/vanilla-renderers";
import {findStyle, findStyleAsClassName} from "./styling";
import {JsonSchema} from "@jsonforms/core";

const withVanillaCellPropsForType = (type: string) => (
  Component: ComponentType<any>
) => (props: any) => {
  const inputClassName = props.config && props.config.addValidationClassName ? [
    props.isValid ? 'is-valid' : 'is-invalid'
  ] : []
  const styles = useStyles();
  const definedStyle = findStyleAsClassName(styles)(type);
  if (definedStyle) {
    inputClassName.push(definedStyle);
  }

  return (
    <Component
      {...props}
      getStyleAsClassName={findStyleAsClassName(styles)}
      getStyle={findStyle(styles)}
      className={inputClassName.join(' ')}
    />
  );
};

export const withVanillaCellProps = withVanillaCellPropsForType(
  'control.input'
);

export const withVanillaEnumCellProps = withVanillaCellPropsForType(
  'control.select'
);

export const withVanillaBooleanCellProps = withVanillaCellPropsForType(
  'control.checkbox'
);

export const getMinValue = (schema: JsonSchema): number | undefined => {
  if (schema.exclusiveMinimum === true || schema.exclusiveMinimum === false) {
    return schema.minimum;
  }
  else if (schema.minimum !== undefined) {
    return schema.minimum;
  }
  else {
    return schema.exclusiveMinimum;
  }
}

export const getMaxValue = (schema: JsonSchema): number | undefined => {
  if (schema.exclusiveMaximum === true || schema.exclusiveMaximum === false) {
    return schema.maximum;
  }
  else if (schema.maximum !== undefined) {
    return schema.maximum;
  }
  else {
    return schema.exclusiveMaximum
  }
}
