import {Alert, Card} from "react-bootstrap";
import FalconCardHeader from "../falcon/common/FalconCardHeader";
import {Subscription, SubscriptionPlan} from "../../api/Subscription";
import {DateTime, Duration} from "luxon";
import {timeUntil} from "../../helpers/utils";
import {Link} from "react-router-dom";
import {buildRoute} from "../../routes/utils";
import {MainRoutes} from "../../routes/MainRoutes";


function SubscriptionEndsNotification({timeUntilEnd}: {timeUntilEnd: Duration}) {
  const daysLeft = timeUntilEnd.as("days");
  let notificationType;
  if (daysLeft > 7) {
    notificationType = "info";
  }
  else if (daysLeft > 2) {
    notificationType = "warning";
  }
  else {
    notificationType = "danger";
  }

  return (
    <div className="mb-4">
      <Alert variant={notificationType} className="rounded-0 border-start-0 border-end-0">{`Your subscription ends in ${timeUntilEnd.reconfigure({locale: "en"}).toHuman()}`}</Alert>
    </div>
  )
}

function StarterPlanDetails() {
  return (
    <>
      <p>The starter plan is perfectly suited for smaller teams or organizations that are getting started with security management.</p>
      <Link to={buildRoute(MainRoutes.PRICING)}>Show details</Link>
    </>
  )
}


export default function SubscriptionInformation({subscription}: {subscription: Subscription}) {
  const endDate = subscription.end_date ? DateTime.fromISO(subscription.end_date) : null;
  const timeUntilEnd = endDate ? timeUntil(endDate, undefined, 1) : null;

  return (
    <Card>
      <FalconCardHeader title={`You're on the ${subscription.plan} plan`} titleTag="h4" titleClass="fw-bold" />
      <Card.Body className="px-0">
        {
          timeUntilEnd && (
            <SubscriptionEndsNotification timeUntilEnd={timeUntilEnd} />
          )
        }
        <div className="px-4">
          <p>
            We hope you enjoy our services and tools.
          </p>
          {
            subscription.plan === SubscriptionPlan.STARTER && <StarterPlanDetails />
          }
        </div>
      </Card.Body>
    </Card>
  )
}
