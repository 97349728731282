import React, {useState} from 'react';
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from "chart.js";
import {AuthProvider, AuthProviderProps} from 'react-oidc-context';
import './scss/secuma.scss';
import {QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
import AppContext from './components/context/AppContext';
import {RootRoutesComponent} from "./routes/RootRoutes";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const oidcConfig: AuthProviderProps = {
  authority: process.env.REACT_APP_AUTHORITY_URL!,
  client_id: "secuma_portal",
  redirect_uri: process.env.REACT_APP_REDIRECT_URL!,
};

const queryClient = new QueryClient();

function App() {
  const [showNavbar, setShowNavbar] = useState(true);
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);

  return (
    <AppContext.Provider
      value={{
        showNavbar: showNavbar,
        setShowNavbar: setShowNavbar,
        showBurgerMenu: showBurgerMenu,
        setShowBurgerMenu: setShowBurgerMenu,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false}/>
        <AuthProvider {...oidcConfig}>
          <RootRoutesComponent/>
        </AuthProvider>
      </QueryClientProvider>
    </AppContext.Provider>
  );
}

export default App;
