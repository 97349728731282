import {Col, Nav, Navbar, Row} from "react-bootstrap";
import Flex from "../../common/Flex";
import Logo, {LogoLocation} from "../../common/Logo";
import ToggleButton from "./ToggleButton";
import NavbarVerticalMenu from "./NavbarVerticalMenu";
import {SectionRoute} from "../../common/Route";
import {Fragment, useContext, useEffect} from "react";
import {capitalize} from "../../helpers/utils";
import {useListAccounts} from "../../../../api/Account";
import NavbarAccountMenuItem from "../../../common/NavbarAccountMenuItem";
import AppContext from "../../../context/AppContext";

interface NavbarVerticalProps {
  routes: SectionRoute[];
}

export default function NavbarVertical({routes}: NavbarVerticalProps) {
  const {
    showNavbar,
    showBurgerMenu,
  } = useContext(AppContext);

  const accounts = useListAccounts();

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (!showNavbar) {
      HTMLClassList.add('navbar-vertical-collapsed');
    } else {
      HTMLClassList.remove('navbar-vertical-collapsed');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [showBurgerMenu, showNavbar, HTMLClassList]);

  //Control mouseEnter event
  let time: number = 0;
  const handleMouseEnter = () => {
    if (!showNavbar) {
      time = window.setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    window.clearTimeout(time);
    HTMLClassList.remove('navbar-vertical-collapsed-hover');
  };

  const NavbarLabel = ({ label }: {label: string}) => (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {label}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );

  return (
    <Navbar
      expand="xl"
      className="navbar-vertical"
      // className={classNames('navbar-vertical', {
      //   [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
      // })}
      variant="light"
    >
      <Flex alignItems="center">
        <ToggleButton />
        <Logo at={LogoLocation.NavbarVertical} width={40} />
      </Flex>
      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        // style={{
        //   backgroundImage:
        //     navbarStyle === 'vibrant'
        //       ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
        //       : 'none'
        // }}
      >
        <div className="navbar-vertical-content scrollbar">
          <Nav className="flex-column" as="ul">
            {routes.map(route => (
              <Fragment key={route.label}>
                {!route.labelDisable && (
                  <NavbarLabel label={capitalize(route.label)} />
                )}
                <NavbarVerticalMenu routes={route.children} />
              </Fragment>
            ))}
            {
              accounts.data && (
                accounts.data.results.length > 1 && (
                  <Fragment key="Accounts">
                    <NavbarLabel label={capitalize("Accounts")} />
                    {
                      accounts.data.results.map(account => {
                        return (
                          <NavbarAccountMenuItem key={account.id} account={account} />
                        )
                      })
                    }
                    {/*<NavbarVerticalMenu routes={accounts.data.results.map(account => {*/}
                    {/*  return {*/}
                    {/*    name: account.name,*/}
                    {/*    to: `/accounts/${account.id}/`,*/}
                    {/*  }*/}
                    {/*})} />*/}
                  </Fragment>
                )
              )
            }
          </Nav>

          <>
            <div className={`d-md-none`}>
              <div className="navbar-vertical-divider">
                <hr className="navbar-vertical-hr my-2" />
              </div>
              <Nav navbar>
                {/*<NavbarTopDropDownMenus />*/}
              </Nav>
            </div>
          </>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};