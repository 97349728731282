import RootInformationCard from "../../components/common/RootInformationCard";
import InvitationHeader from "../../components/invitation/InvitationHeader";
import {InvitationCodeQueryResultComponent} from "../../components/invitation/InvitationCodeQueryResultComponent";


export default function InvitationCodePage() {
  return (
    <RootInformationCard title="Secuma">
      <InvitationHeader />
      <InvitationCodeQueryResultComponent />
    </RootInformationCard>
  )
}
