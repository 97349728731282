import {UseFormReturn} from "react-hook-form/dist/types";
import {Col, Form, Row} from "react-bootstrap";
import CountryField from "../common/CountryField";
import {SHOW_VALID_STYLE} from "../../helpers/utils";

export default function CreateOrganizationForm({form}: { form: UseFormReturn }) {
  const {
    register,
    control,
    formState: {
      errors,
    }
  } = form;

  return (
    <>
      <Form.Group className="mb-2 d-flex flex-column">
        <Form.Label className="mb-0 fw-bold">Name</Form.Label>
        <Form.Text>The name of your organization</Form.Text>
        <Form.Control
          type="input"
          {
            ...register(
              "organizationName",
              {
                required: "A name is required",
              }
            )
          }
          isInvalid={errors["organizationName"] !== undefined}
          isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors["organizationName"] === undefined}
        />
        <Form.Control.Feedback type="invalid">
          {errors["organizationName"]?.message?.toString()}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2 d-flex flex-column">
        <Form.Label className="mb-0 fw-bold">Email</Form.Label>
        <Form.Text>The email address of the organization</Form.Text>
        <Form.Control
          type="email"
          {
            ...register(
              "organizationEmail",
              {
                required: "An email address is required",
              }
            )
          }
          isInvalid={errors["organizationEmail"] !== undefined}
          isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors["organizationEmail"] === undefined}
        />
        <Form.Control.Feedback type="invalid">
          {errors["organizationEmail"]?.message?.toString()}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2 d-flex flex-column">
        <Form.Label className="mb-0 fw-bold">Address</Form.Label>
        <Form.Text>The address of your organization</Form.Text>
        <Form.Control
          placeholder="Address line 1"
          type="input"
          {
            ...register(
              "organizationAddress1",
              {
                required: "An address is required",
              }
            )
          }
          isInvalid={errors["organizationAddress1"] !== undefined}
          isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors["organizationAddress1"] === undefined}
        />
        <Form.Control.Feedback type="invalid">
          {errors["organizationAddress1"]?.message?.toString()}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2 d-flex flex-column">
        <Form.Control
          type="input"
          placeholder="Address line 2 (optional)"
          {
            ...register(
              "organizationAddress2",
            )
          }
          isInvalid={errors["organizationAddress2"] !== undefined}
          isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors["organizationAddress2"] === undefined}
        />
        <Form.Control.Feedback type="invalid">
          {errors["organizationAddress2"]?.message?.toString()}
        </Form.Control.Feedback>
      </Form.Group>
      <Row className="g-2">
        <Form.Group as={Col} className="col-4 mb-2 d-flex flex-column">
          <Form.Control
            type="input"
            placeholder="Postal code"
            {
              ...register(
                "organizationPostalCode",
                {
                  required: "A postal code or zip code is required",
                }
              )
            }
            isInvalid={errors["organizationPostalCode"] !== undefined}
            isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors["organizationPostalCode"] === undefined}
          />
          <Form.Control.Feedback type="invalid">
            {errors["organizationPostalCode"]?.message?.toString()}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} className="mb-2 d-flex flex-column">
          <Form.Control
            type="input"
            placeholder="City"
            {
              ...register(
                "organizationCity",
                {
                  required: "A city is required",
                }
              )
            }
            isInvalid={errors["organizationCity"] !== undefined}
            isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors["organizationCity"] === undefined}
          />
          <Form.Control.Feedback type="invalid">
            {errors["organizationCity"]?.message?.toString()}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Form.Group className="mb-2 d-flex flex-column">
        <CountryField
          name="organizationCountry"
          control={control}
          rules={{ required: "A country is required"}}
          isInvalid={errors["organizationCountry"] !== undefined}
          isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors["organizationCountry"] === undefined}

        />
        <Form.Control.Feedback type="invalid">
          {errors["organizationCountry"]?.message?.toString()}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2 d-flex flex-column">
        <Form.Label className="mb-0 fw-bold">Tax number</Form.Label>
        <Form.Text>The tax number of your business (optional)</Form.Text>
        <Form.Control
          type="input"
          {
            ...register(
              "organizationTaxNumber",
            )
          }
          isInvalid={errors["organizationTaxNumber"] !== undefined}
          isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors["organizationTaxNumber"] === undefined}
        />
        <Form.Control.Feedback type="invalid">
          {errors["organizationTaxNumber"]?.message?.toString()}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  )
}
