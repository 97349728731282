import {Button, OverlayTrigger, Tooltip, TooltipProps} from "react-bootstrap";
import {useContext} from "react";
import AppContext from "../../../context/AppContext";

function renderTooltip (props: TooltipProps) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      Toggle Navigation
    </Tooltip>
  )
}

export default function ToggleButton() {
  const {
    showNavbar,
    setShowNavbar,
  } = useContext(AppContext);

  const handleClick = () => {
    document
      .getElementsByTagName('html')[0]
      .classList.toggle('navbar-vertical-collapsed');
    setShowNavbar(!showNavbar);
  };

  return (
    <OverlayTrigger
      placement='right'
      overlay={renderTooltip}
    >
      <div className="toggle-icon-wrapper">
        <Button
          variant="link"
          className="navbar-toggler-humburger-icon navbar-vertical-toggle"
          id="toggleNavigationTooltip"
          onClick={handleClick}
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </Button>
      </div>
    </OverlayTrigger>
  );
};
