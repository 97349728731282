import {SecurityPolicy, useUpdateSecurityPolicy} from "../../api/SecurityPolicy";
import React, {useEffect, useState} from "react";
import DevelopmentSecurityPracticesFrameworkSelectionController
  from "./DevelopmentSecurityPracticesFrameworkSelectionController";
import {RiskClassificationScheme, useRetrieveRiskClassificationScheme} from "../../api/RiskClassificationScheme";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import Flex from "../falcon/common/Flex";
import {ReactQuerySpinner} from "../common/ReactQueryLoader";
import {
  DevelopmentSecurityPracticesFramework,
  useRetrieveDevelopmentSecurityPracticesFramework
} from "../../api/DevelopmentSecurityPracticesFramework";
import {JsonFormsCore} from "@jsonforms/core";
import {JsonForms} from "@jsonforms/react";
import {bootstrapCells, bootstrapRenderers} from "../jsonforms-renderers";


interface DevelopmentSecurityPracticesFrameworkDetailProps {
  securityPolicy: SecurityPolicy;
  developmentSecurityPracticesFramework: DevelopmentSecurityPracticesFramework;
}

function DevelopmentSecurityPracticesFrameworkDetail({securityPolicy, developmentSecurityPracticesFramework}: DevelopmentSecurityPracticesFrameworkDetailProps) {
  const securityPolicyMutation = useUpdateSecurityPolicy();
  const [formData, setFormData] = useState(securityPolicy.development_security_practices_framework_parameters);
  const [formErrors, setFormErrors] = useState<any[] | undefined>([]);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    setFormData(securityPolicy.development_security_practices_framework_parameters)
  }, [securityPolicy.id])

  const onChange = (state: Pick<JsonFormsCore, 'data' | 'errors'>) => {
    setFormData(state.data);
    setFormErrors(state.errors)
  }

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (!formErrors || formErrors.length === 0) {
      securityPolicyMutation.mutate({
        ...securityPolicy,
        development_security_practices_framework_parameters: formData,
      })
    }

    setSubmitted(true);
  }


  return (
    <>
      <h5 className="mb-4 fw-bold">{developmentSecurityPracticesFramework.name}</h5>
      <p>
        {developmentSecurityPracticesFramework.description}
      </p>
      <Form onSubmit={onSubmit} noValidate>
        <Row className="mb-4">
          <Col xxl={8}>
            {
              securityPolicyMutation.isError && (
                <Alert variant="danger" dismissible onClose={() => {securityPolicyMutation.reset()}}>Could not save data. An error occurred.</Alert>
              )
            }
            {
              securityPolicyMutation.isSuccess && (
                <Alert variant="success" dismissible onClose={() => {securityPolicyMutation.reset()}}>Data saved successfully!</Alert>
              )
            }
            <JsonForms
              schema={developmentSecurityPracticesFramework.parameters_json_schema}
              cells={bootstrapCells}
              data={formData}
              renderers={bootstrapRenderers}
              config={{
                hideRequiredAsterisk: true,
                showUnfocusedDescription: true,
                addValidationClassName: submitted,
              }}
              onChange={onChange}
              readonly={securityPolicyMutation.isLoading}
            />
          </Col>
        </Row>

        <Button type="submit" disabled={securityPolicyMutation.isLoading}>
          {
            securityPolicyMutation.isLoading
              ? <ReactQuerySpinner size="sm" />
              : "Save"
          }
        </Button>
      </Form>
    </>
  )
}

function DevelopmentSecurityPracticesFrameworkDetailController({securityPolicy}: { securityPolicy: SecurityPolicy }) {
  const developmentSecurityPracticesFrameworkQuery = useRetrieveDevelopmentSecurityPracticesFramework(securityPolicy.development_security_practices_framework_id);

  if (developmentSecurityPracticesFrameworkQuery.isError || (developmentSecurityPracticesFrameworkQuery.data && !developmentSecurityPracticesFrameworkQuery.data.parameters_json_schema)) {
    return (
      <Alert variant="danger">The Development Security Practices Framework information could not be retrieved.</Alert>
    )
  }
  else if (developmentSecurityPracticesFrameworkQuery.isSuccess) {
    return (
      <DevelopmentSecurityPracticesFrameworkDetail securityPolicy={securityPolicy} developmentSecurityPracticesFramework={developmentSecurityPracticesFrameworkQuery.data} />
    )
  }
  else {
    return (
      <Flex justifyContent="center"><ReactQuerySpinner/></Flex>
    )
  }
}


export default function DevelopmentSecurityPracticesCardController({securityPolicy}: { securityPolicy: SecurityPolicy }) {
  if (!securityPolicy.development_security_practices_framework_id || securityPolicy.development_security_practices_framework_id === "") {
    return <DevelopmentSecurityPracticesFrameworkSelectionController securityPolicy={securityPolicy}/>
  } else {
    return <DevelopmentSecurityPracticesFrameworkDetailController securityPolicy={securityPolicy} />
  }
}
