import {Button} from "react-bootstrap";
import React from "react";
import {ButtonProps} from "react-bootstrap/Button";

interface SecurityPolicyActivationButtonProps extends ButtonProps {

}

export default function SecurityPolicyActivationButton(props: SecurityPolicyActivationButtonProps) {
  return (
    <Button size="sm" {...props}>Activate</Button>
  )
}
