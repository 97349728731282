import {ApiQueryParams, buildApiEndpoint, queryEnabled, queryParamsToCacheKeys} from "./baseQueryParams";
import {ApiError, deleteApi, fetchApi, FetchOptions, pagesResultToArray, postApi, putApi} from "./utils";
import {
  InfiniteData,
  QueryFunctionContext,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from "react-query";
import {merge} from "lodash";
import {UseInfiniteQueryOptions} from "react-query/types/react/types";
import {useAuth} from "react-oidc-context";
import {useEffect, useMemo} from "react";


export enum DefaultViewSetActions {
  LIST = "list",
  DETAIL = "detail",
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
}


export interface ApiViewSet {
  baseName: string;
  endpointPrefix?: string;
  endpoints?: {
    [key in string]?: string | null;
  };
}

export interface Page<T> {
  count: number,
  next: string | null,
  previous: string | null,
  results: T[]
}

export type DetailOptions = ({
  id: string | number;
  url?: never;
} | {
  id?: never;
  url: string;
}) & {
  customAction?: string;
}

// Has at least one of id or url
export type ResourceId = {
  id: string | number;
  url?: string;
} | {
  id?: string | number;
  url: string;
}

export function isResourceId(obj: unknown): obj is ResourceId {
  const resourceId = obj as ResourceId;
  return resourceId.id !== undefined || resourceId.url !== undefined;
}

export function getEndpoint(viewSet: ApiViewSet, action: DefaultViewSetActions | string, id?: string | number | null): string {
  let endpoint = viewSet.endpoints ? viewSet.endpoints[action] : undefined;
  if (endpoint) {
    if (endpoint.includes("{id}") && id) {
      endpoint = endpoint.replace("{id}", `${id}`);
    }
    return endpoint;
  }

  if (action === DefaultViewSetActions.LIST || action === DefaultViewSetActions.CREATE) {
    return `${viewSet.endpointPrefix}/${viewSet.baseName}/`;
  }
  else if (action === DefaultViewSetActions.DETAIL || action === DefaultViewSetActions.UPDATE || action === DefaultViewSetActions.DELETE) {
    return `${viewSet.endpointPrefix}/${viewSet.baseName}/${id}/`;
  }
  else {
    console.warn('Unexpected viewset action', action);
    return `${viewSet.endpointPrefix}/${viewSet.baseName}/`;
  }
}


export function apiList<T, S extends string | number>(viewSet: ApiViewSet, queryParams?: ApiQueryParams<S> | null, fetchOptions?: FetchOptions): () => Promise<Page<T>> {
  return async (context?: QueryFunctionContext): Promise<Page<T>> => {
    let baseEndpoint = getEndpoint(viewSet, DefaultViewSetActions.LIST);
    const finalQueryParams = {...queryParams};
    if (context && context.pageParam) {
      if (context.pageParam.startsWith("http")) {
        baseEndpoint = context.pageParam;
      }
      else {
        finalQueryParams.page = context.pageParam;
      }
    }
    const endpoint = buildApiEndpoint(
      baseEndpoint,
      finalQueryParams,
    );
    console.debug("Performing list operation", endpoint);
    let response = await fetchApi(endpoint, undefined, fetchOptions);
    if (!response.ok) {
      let json;
      try {
        json = await response.json();
      } catch (e) {
        throw new ApiError(`Error fetching list of ${viewSet.baseName}`, response.status)
      }
      throw new ApiError(`Error fetching list of ${viewSet.baseName}`, response.status, json);
    }
    return await response.json();
  };
}

interface ApiDetailProps<TQueryParams extends string | number> {
  viewSet: ApiViewSet;
  options: DetailOptions;
  queryParams?: ApiQueryParams<TQueryParams>;
  fetchOptions?: FetchOptions;
}

export function apiDetail<T, TQueryParams extends string | number>({viewSet, queryParams, options, fetchOptions}: ApiDetailProps<TQueryParams>): () => Promise<T> {
  return async (): Promise<T> => {
    let baseEndpoint = options.url;
    if (!baseEndpoint) {
      if (!options.id) {
        console.trace('Unexpected empty url and id', viewSet, options);
      }
      baseEndpoint = getEndpoint(viewSet, options?.customAction ? options.customAction : DefaultViewSetActions.DETAIL, options.id);
    }
    const endpoint = buildApiEndpoint(
      baseEndpoint,
      queryParams,
    );
    console.debug("Performing detail operation", endpoint);
    let response = await fetchApi(endpoint, undefined, fetchOptions);
    if (!response.ok) {
      let json;
      try {
        json = await response.json();
      } catch (e) {
        throw new ApiError(`Error fetching detail of ${viewSet.baseName}`, response.status)
      }
      throw new ApiError(`Error fetching detail of ${viewSet.baseName}`, response.status, json);
    }
    return await response.json();
  }
}

export function apiCreate<TData, TResult>(viewSet: ApiViewSet, fetchOptions?: FetchOptions): (variables: TData) => Promise<TResult> {
  return async (variables: TData): Promise<TResult> => {
    const endpoint = getEndpoint(viewSet, DefaultViewSetActions.CREATE);

    console.debug("Performing create operation", endpoint);
    let response = await postApi(endpoint, variables, fetchOptions);
    if (!response.ok) {
      let json;
      try {
        json = await response.json();
      } catch (e) {
        throw new ApiError(`Error creating ${viewSet.baseName}`, response.status)
      }
      throw new ApiError(`Error creating ${viewSet.baseName}`, response.status, json);
    }
    return await response.json();
  }
}

export function apiUpdate<TData extends ResourceId, TResult>(viewSet: ApiViewSet, fetchOptions?: FetchOptions): (variables: TData) => Promise<TResult> {
  return async (variables: TData): Promise<TResult> => {
    let endpointUrl = variables.url;
    if (!endpointUrl) {
      if (!variables.id) {
        console.trace('Unexpected empty url and id', viewSet, variables);
      }
      endpointUrl = getEndpoint(viewSet, DefaultViewSetActions.UPDATE, variables.id);
    }

    console.debug("Performing update operation", endpointUrl);
    let response = await putApi(endpointUrl, variables, fetchOptions);
    if (!response.ok) {
      let json;
      try {
        json = await response.json();
      } catch (e) {
        throw new ApiError(`Error updating ${viewSet.baseName}`, response.status)
      }
      throw new ApiError(`Error updating ${viewSet.baseName}`, response.status, json);
    }
    return await response.json();
  }
}

export function apiDelete<TData extends ResourceId>(viewSet: ApiViewSet, fetchOptions?: FetchOptions): (variables: TData) => Promise<void> {
  return async (variables: TData): Promise<void> => {
    let endpointUrl = variables.url;
    if (!endpointUrl) {
      if (!variables.id) {
        console.trace('Unexpected empty url and id', viewSet, variables);
      }
      endpointUrl = getEndpoint(viewSet, DefaultViewSetActions.DELETE, variables.id);
    }

    console.debug("Performing delete operation", endpointUrl);
    let response = await deleteApi(endpointUrl, variables, fetchOptions);
    if (!response.ok) {
      let json;
      try {
        json = await response.json();
      } catch (e) {
        throw new ApiError(`Error deleting ${viewSet.baseName}`, response.status)
      }
      throw new ApiError(`Error deleting ${viewSet.baseName}`, response.status, json);
    }
  }
}

interface UseListProps<TQueryParams extends string | number, TData extends ResourceId> {
  viewSet: ApiViewSet,
  queryParamsEnum: {[key in string]: string},
  fetchFn: (queryParams?: ApiQueryParams<TQueryParams> | null, fetchOptions?: FetchOptions) => () => Promise<Page<TData>>,
  url?: string,
  queryParams?: ApiQueryParams<TQueryParams> | null,
  queryOptions?: UseQueryOptions<Page<TData>>,
  fetchOptions?: FetchOptions
}

export function useList<TQueryParams extends string | number, TData extends ResourceId>(
  {
    viewSet,
    queryParamsEnum,
    fetchFn,
    url,
    queryParams,
    queryOptions,
    fetchOptions,
  }: UseListProps<TQueryParams, TData>
) {
  const queryClient = useQueryClient();
  const auth = useAuth();

  const resultQueryParams: ApiQueryParams<TQueryParams> = {}
  if (url) {
    const urlComponents = new URL(url);
    const urlQueryParams = new URLSearchParams(urlComponents.search);
    merge(resultQueryParams, Object.fromEntries(urlQueryParams.entries()));
  }
  if (queryParams) {
    merge(resultQueryParams, queryParams);
  }

  const config = {
    enabled: queryEnabled(resultQueryParams),
    onSuccess: (resultsPage: Page<TData>) => {
      // Set the slot detail cache as well
      for (const result of resultsPage.results) {
        if (result.id) {
          queryClient.setQueryData([viewSet.baseName, result.id], result);
        }
        if (result.url) {
          queryClient.setQueryData([viewSet.baseName, result.url], result);
        }
      }
    },
    ...queryOptions,
  };

  if (auth.user) {
    fetchOptions = {
      accessToken: auth.user.access_token,
      ...fetchOptions,
    };
  }

  return useQuery<Page<TData>>(
    [viewSet.baseName, queryParamsToCacheKeys(queryParamsEnum, resultQueryParams)],
    fetchFn(resultQueryParams, fetchOptions),
    config,
  );
}

interface UseInfiniteListProps<TQueryParams extends string | number, TData extends ResourceId> {
  viewSet: ApiViewSet,
  queryParamsEnum: {[key in string]: string},
  fetchFn: (queryParams?: ApiQueryParams<TQueryParams> | null, fetchOptions?: FetchOptions) => () => Promise<Page<TData>>,
  url?: string,
  queryParams?: ApiQueryParams<TQueryParams> | null,
  queryOptions?: UseInfiniteQueryOptions<Page<TData>>,
  fetchOptions?: FetchOptions
}

export function useListInfinite<TQueryParams extends string | number, TData extends ResourceId>(
  {
    viewSet,
    queryParamsEnum,
    fetchFn,
    url,
    queryParams,
    queryOptions,
    fetchOptions,
  }: UseInfiniteListProps<TQueryParams, TData>
): UseInfiniteQueryResult<Page<TData>> {
  const queryClient = useQueryClient();
  const auth = useAuth();

  const resultQueryParams: ApiQueryParams<TQueryParams> = {}
  if (url) {
    const urlComponents = new URL(url);
    const urlQueryParams = new URLSearchParams(urlComponents.search);
    merge(resultQueryParams, Object.fromEntries(urlQueryParams.entries()));
  }
  if (queryParams) {
    merge(resultQueryParams, queryParams);
  }

  const config: UseInfiniteQueryOptions<Page<TData>> = {
    enabled: queryEnabled(resultQueryParams),
    getNextPageParam: lastPage => lastPage.next,
    ...queryOptions,
    onSuccess: (results: InfiniteData<Page<TData>>) => {
      // Set the slot detail cache as well
      for (const result of results.pages[results.pages.length - 1].results) {
        if (result.id) {
          queryClient.setQueryData([viewSet.baseName, result.id], result);
        }
        if (result.url) {
          queryClient.setQueryData([viewSet.baseName, result.url], result);
        }
      }
      if (queryOptions?.onSuccess) {
        queryOptions.onSuccess(results);
      }
    },
  };

  if (auth.user) {
    fetchOptions = {
      accessToken: auth.user.access_token,
      ...fetchOptions,
    };
  }

  return useInfiniteQuery<Page<TData>>(
    [viewSet.baseName, queryParamsToCacheKeys(queryParamsEnum, resultQueryParams)],
    fetchFn(resultQueryParams, fetchOptions),
    config,
  );
}

export function useAll<TData extends ResourceId>(query: UseInfiniteQueryResult<Page<TData>>): TData[] | null {
  useEffect(() => {
    if (query.hasNextPage) {
      query.fetchNextPage();
    }
  }, [query.hasNextPage, query.fetchNextPage])

  const result = useMemo(() => {
    return query.data ? pagesResultToArray(query.data.pages) : null;
  }, [query.data]);

  if (query.hasNextPage || !result ) {
    return null;
  }
  return result;
}

interface UseRetrieveProps<TData extends ResourceId, TQueryParams extends string | number> {
  viewSet: ApiViewSet,
  queryParamsEnum: {[key in string]: string},
  fetchFn: (detail: DetailOptions, queryParams?: ApiQueryParams<TQueryParams>, fetchOptions?: FetchOptions) => () => Promise<TData>,
  detail?: DetailOptions | null;
  queryParams?: ApiQueryParams<TQueryParams>;
  queryOptions?: UseQueryOptions<TData, ApiError>;
  fetchOptions?: FetchOptions;
}

export function useRetrieve<TData extends ResourceId, TQueryParams extends string | number>(
  {
    viewSet,
    queryParamsEnum,
    fetchFn,
    detail,
    queryParams,
    queryOptions,
    fetchOptions,
  }: UseRetrieveProps<TData, TQueryParams>
) {
  const auth = useAuth();
  const queryClient = useQueryClient();

  if (auth.user) {
    fetchOptions = {
      accessToken: auth.user.access_token,
      ...fetchOptions,
    };
  }

  const queryKey = detail ? (detail.id ? detail.id : detail.url ? detail.url : "") : "";

  const config: UseQueryOptions<TData, ApiError> = {
    enabled: !!detail,
    ...queryOptions,
    onSuccess: (result: TData) => {
      // Make sure to set both queryKey for id and url. By default, only one or the other would be set.
      if (result.id) {
        queryClient.setQueryData([viewSet.baseName, result.id], result);
      }
      if (result.url) {
        queryClient.setQueryData([viewSet.baseName, result.url], result);
      }
      if (queryOptions?.onSuccess) {
        queryOptions.onSuccess(result);
      }
    }
  }

  return useQuery<TData, ApiError>({
    queryKey: [viewSet.baseName, queryKey, queryParamsToCacheKeys(queryParamsEnum, queryParams)],
    queryFn: fetchFn(detail ? detail : {url: ""}, queryParams, fetchOptions),
    ...config,
  });
}

interface UseCreatePropsBase<TData, TCreateData = TData> {
  viewSet: ApiViewSet;
  options?: UseMutationOptions<TData, ApiError, TCreateData>;
  fetchOptions?: FetchOptions;
  fetchFn: (...args: any[]) => (variables: TCreateData) => Promise<TData>;
}

interface UseCreateProps<TData, TCreateData = TData> extends UseCreatePropsBase<TData, TCreateData> {
  fetchFn: (fetchOptions?: FetchOptions) => (variables: TCreateData) => Promise<TData>;
}

interface UseCreatePropsWithDetailOptions<TData, TCreateData = TData> extends UseCreatePropsBase<TData, TCreateData> {
  fetchFn: (options: DetailOptions, fetchOptions?: FetchOptions) => (variables: TCreateData) => Promise<TData>;
  detailOptions: DetailOptions;
}

function isCreatePropsWithDetailOptions<TData, TCreateData = TData>(props: UseCreateProps<TData, TCreateData> | UseCreatePropsWithDetailOptions<TData, TCreateData>): props is UseCreatePropsWithDetailOptions<TData, TCreateData> {
  return (props as UseCreatePropsWithDetailOptions<TData, TCreateData>).detailOptions !== undefined;
}

export function useCreate<TData, TCreateData = TData>(props: UseCreateProps<TData, TCreateData>): UseMutationResult<TData, ApiError, TCreateData>;
export function useCreate<TData, TCreateData = TData>(props: UseCreatePropsWithDetailOptions<TData, TCreateData>): UseMutationResult<TData, ApiError, TCreateData>;

export function useCreate<TData, TCreateData = TData>(
  props: UseCreateProps<TData, TCreateData> | UseCreatePropsWithDetailOptions<TData, TCreateData>
) {
  let {
    viewSet,
    options,
    fetchOptions,
  } = props;
  const queryClient = useQueryClient();
  const auth = useAuth();

  if (auth.user) {
    fetchOptions = {
      accessToken: auth.user.access_token,
      ...fetchOptions,
    };
  }

  const config: UseMutationOptions<TData, ApiError, TCreateData> = {
    ...options,
    onSuccess: async (data, variables, context) => {
      if (isResourceId(data)) {
        if (data.id) {
          queryClient.setQueryData([viewSet.baseName, data.id], data);
        }
        if (data.url) {
          queryClient.setQueryData([viewSet.baseName, data.url], data);
        }
      }
      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
    onSettled: async (data, error, variables, context) => {
      await queryClient.invalidateQueries([viewSet.baseName]); // Invalidates the list data.
      if (options?.onSettled) {
        await options.onSettled(data, error, variables, context);
      }
    }
  };

  let mutationProps: UseMutationOptions<TData, ApiError, TCreateData>;
  if (isCreatePropsWithDetailOptions(props)) {
    mutationProps = {
      mutationFn: props.fetchFn(props.detailOptions, fetchOptions),
      ...config,
    }
  }
  else {
    mutationProps = {
      mutationFn: props.fetchFn(fetchOptions),
      ...config,
    }
  }

  return useMutation(mutationProps);
}

interface UseUpdateProps<TData, TUpdate extends ResourceId> {
  viewSet: ApiViewSet,
  updateFn: (fetchOptions?: FetchOptions) => (obj: TUpdate) => Promise<TData>,
  options?: UseMutationOptions<TData, unknown, TUpdate>,
  fetchOptions?: FetchOptions
}

export function useUpdate<TData, TUpdate extends ResourceId>(
  {
    viewSet,
    updateFn,
    options,
    fetchOptions,
  }: UseUpdateProps<TData, TUpdate>
) {  const queryClient = useQueryClient();
  const auth = useAuth();

  if (auth.user) {
    fetchOptions = {
      accessToken: auth.user.access_token,
      ...fetchOptions,
    };
  }

  const config: UseMutationOptions<TData, unknown, TUpdate> = {
    ...options,
    onSuccess: async (data, variables, context) => {
      if (isResourceId(data)) {
        if (data.id) {
          queryClient.setQueryData([viewSet.baseName, data.id], data);
        }
        if (data.url) {
          queryClient.setQueryData([viewSet.baseName, data.url], data);
        }
      }
      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
    onSettled: async () => {
      await queryClient.invalidateQueries([viewSet.baseName]); // Invalidates the list data.
    }
  };

  return useMutation(updateFn(fetchOptions), config);
}


interface UseDeleteProps<TData extends ResourceId> {
  viewSet: ApiViewSet,
  deleteFn: (fetchOptions?: FetchOptions) => (obj: TData) => Promise<void>,
  options?: UseMutationOptions<void, unknown, TData>,
  fetchOptions?: FetchOptions
}

export function useDelete<TData extends ResourceId>(
  {
    viewSet,
    deleteFn,
    options,
    fetchOptions,
  }: UseDeleteProps<TData>
) {
  const queryClient = useQueryClient();
  const auth = useAuth();

  if (auth.user) {
    fetchOptions = {
      accessToken: auth.user.access_token,
      ...fetchOptions,
    };
  }

  const config: UseMutationOptions<void, unknown, TData> = {
    ...options,
    onSettled: async (data, error, variables, context) => {
      await queryClient.invalidateQueries([viewSet.baseName]); // Invalidates the list data.
      if (options?.onSettled) {
        await options.onSettled(data, error, variables, context);
      }
    }
  };

  return useMutation(deleteFn(fetchOptions), config);
}
