import {ControlProps, EnumCellProps} from "@jsonforms/core";
import {TranslateProps, withJsonFormsEnumCellProps, withTranslateProps} from "@jsonforms/react";
import React from "react";
import {VanillaRendererProps} from "@jsonforms/vanilla-renderers";
import {Form} from "react-bootstrap";
import {withVanillaEnumCellProps} from "./utils";

const EnumCell = (props: EnumCellProps & VanillaRendererProps & TranslateProps & ControlProps) => {
  const { data, className, id, enabled, uischema, path, handleChange, options, required } = props;
  const noneOptionLabel = "-----"

  return (
    <Form.Select
      className={className}
      id={id}
      disabled={!enabled}
      autoFocus={uischema.options && uischema.options.focus}
      value={data || ''}
      onChange={ev => handleChange(path, ev.target.selectedIndex === 0 ? undefined : ev.target.value)}
      required={required === true}
    >
      {
        [<option value={''} key={'jsonforms.enum.none'}>{noneOptionLabel}</option>]
          .concat(
            options ? options.map(optionValue =>
              (
                <option value={optionValue.value} label={optionValue.label} key={optionValue.value}/>
              )
            ) : []
          )}
    </Form.Select>
  );
};

export default withJsonFormsEnumCellProps(withTranslateProps(withVanillaEnumCellProps(EnumCell)));
