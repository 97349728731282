import {SecurityPolicy} from "../../api/SecurityPolicy";
import React, {useState} from "react";
import {Card} from "react-bootstrap";
import FalconCardHeader from "../falcon/common/FalconCardHeader";
import {buildRoute} from "../../routes/utils";
import {MainRoutes} from "../../routes/MainRoutes";
import {GovernanceRoutes} from "../../routes/GovernanceRoutes";
import {SecurityPolicyRoutes} from "../../routes/SecurityPolicyRoutes";
import {SupportModal} from "../common/SupportModal";

export function ASVSInformationCard({securityPolicy}: { securityPolicy: SecurityPolicy }) {
  const [showModal, setShowModal] = useState(false);

  const onClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    setShowModal(true);
  }

  const onHide = () => {
    setShowModal(false);
  }

  return (
    <Card className="mb-4">
      <FalconCardHeader light titleTag="h5" title="Application Security Controls Framework"></FalconCardHeader>
      <Card.Body className="opacity-50">
        <h5 className="mb-4 fw-bold">Application Security Verification Standard 5.0 (ASVS) by <a
          href="https://owaspsamm.org">OWASP</a></h5>
        <p>The application security controls framework can currently not be selected and is not yet integrated with the
          platform. If you would like to have this functionality, <a
            href={buildRoute(MainRoutes.GOVERNANCE, GovernanceRoutes.SECURITY_POLICY, SecurityPolicyRoutes.DETAIL).replace(":securityPolicyId", securityPolicy.id)}
            onClick={onClick}
          >let us know</a>, and we can give it appropriate priority.</p>
      </Card.Body>
      <SupportModal showModal={showModal} onHide={onHide} onDone={onHide} defaultSubject="Interested in ASVS support"
                    additionalInfo="User is interested in ASVS (Security Policy detail page)."/>
    </Card>
  )
}
