import {Card, Col, Row} from "react-bootstrap";
import FalconCardHeader from "../falcon/common/FalconCardHeader";
import {Link} from "react-router-dom";
import {SAMMAssessment, useListSAMMAssessments} from "../../api/SAMMAssessment";
import ReactQueryLoader from "../common/ReactQueryLoader";
import classNames from "classnames";
import FalconCardFooterLink from "../falcon/common/FalconCardFooterLink";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import Flex from "../falcon/common/Flex";
import {useContext} from "react";
import AccountContext from "../context/AccountContext";
import {SAMMAssessmentRoutes} from "../../routes/SAMMAssessmentRoutes";
import {MainRoutes} from "../../routes/MainRoutes";
import {GovernanceRoutes} from "../../routes/GovernanceRoutes";
import {buildRoute} from "../../routes/utils";
import {SAMMAssessmentDetailRoutes} from "../../routes/SAMMAssessmentDetailRoutes";

function SAMMAssessmentRow({ assessment, isLast }: {assessment: SAMMAssessment, isLast?: boolean}) {
  return (
    <Row
      className={classNames('align-items-center py-2', {
        'border-bottom border-200 ': !isLast
      })}
    >
      <Col className="py-1">
        <Flex className="align-items-center">

        <Flex className="position-relative">
          <Flex tag="h6" alignItems="center" className="mb-0">
            <Link
              className="text-800 stretched-link"
              to={buildRoute(
                MainRoutes.GOVERNANCE,
                GovernanceRoutes.SAMM_ASSESSMENTS,
                SAMMAssessmentRoutes.SAMM_ASSESSMENT_DETAIL,
                SAMMAssessmentDetailRoutes.PREPARE,
              ).replace(":sammAssessmentId", assessment.id)}
            >
              {assessment.name}
            </Link>
            {/*<Badge pill bg="200" className="ms-2 text-primary">*/}
            {/*  {80}%*/}
            {/*</Badge>*/}
          </Flex>
        </Flex>
        </Flex>
      </Col>
    </Row>
  )
}

function SAMMAssessmentsCardEmpty() {
  return (
    <div className="py-3">
      <p className="text-center">
        No SAMM assessments found.
      </p>
      <p className="text-center mb-0">
        <Link
          className="btn btn-primary"
          to={buildRoute(
            MainRoutes.GOVERNANCE,
            GovernanceRoutes.SAMM_ASSESSMENTS,
            SAMMAssessmentRoutes.SAMM_ASSESSMENT_NEW,
          )}
        >Start new assessment</Link>
      </p>
    </div>
  )
}

export default function SAMMAssessmentsCard() {
  const {account} = useContext(AccountContext);
  const queryResult = useListSAMMAssessments({account_id: account.id});

  return (
    <Card>
      <FalconCardHeader
        title="SAMM assessments"
        light
        titleTag="h5"
      />

      <Card.Body className="py-0">
        <ReactQueryLoader queryResult={queryResult}>
          {
            !queryResult.data || queryResult.data.count === 0
              ? <SAMMAssessmentsCardEmpty/>
              : (
                queryResult.data.results.map((assessment, index) => {
                  return (
                    <SAMMAssessmentRow key={assessment.id} assessment={assessment} isLast={index === queryResult.data.results.length - 1}/>
                  )
                })
              )
          }
        </ReactQueryLoader>
      </Card.Body>

      {
        queryResult.data
        && queryResult.data.count > 0
        && (
          <FalconCardFooterLink
            title="Start a new assessment"
            size="sm"
            icon={faPlusCircle}
            iconPosition="before"
            to={buildRoute(
              MainRoutes.GOVERNANCE,
              GovernanceRoutes.SAMM_ASSESSMENTS,
              SAMMAssessmentRoutes.SAMM_ASSESSMENT_NEW,
            )}
          />
        )
      }
    </Card>
  )
}
