import React, {useContext} from "react";
import {SecurityPolicy, useListSecurityPolicies} from "../../api/SecurityPolicy";
import AccountContext from "../context/AccountContext";
import {Alert} from "react-bootstrap";
import {ReactQuerySpinner} from "../common/ReactQueryLoader";


interface ChooseActivePolicyComponentProps {
  activePolicies: SecurityPolicy[];
}

function ChooseActivePolicyComponent({activePolicies}: ChooseActivePolicyComponentProps) {
  return (
    <div>
      You have multiple active security policies. Please contact support to resolve the issue.
    </div>
  )
}

interface MultipleActivePoliciesInterceptProps {
  children?: React.ReactNode;
}

export default function MultipleActivePoliciesIntercept({children}: MultipleActivePoliciesInterceptProps) {
  const {account} = useContext(AccountContext);
  const securityPoliciesResult = useListSecurityPolicies(undefined, {account_id: account.id})

  if (securityPoliciesResult.isError) {
    return (
      <main>
        <Alert variant="danger">
          An error occurred retrieving the security policies.
        </Alert>
      </main>
    )
  }
  else if (securityPoliciesResult.isSuccess && securityPoliciesResult.data) {
    const activeSecurityPolicies = securityPoliciesResult.data.results.filter(sp => sp.active);
    if (activeSecurityPolicies.length > 1) {
      return <ChooseActivePolicyComponent activePolicies={activeSecurityPolicies}/>
    }
    else {
      return <>{children}</>;
    }
  }

  return (
    <main>
      <div className="d-flex justify-content-center">
        <ReactQuerySpinner />
      </div>
    </main>
  )
}
