import {CellProps} from "@jsonforms/core";
import {withJsonFormsCellProps} from "@jsonforms/react";
import React from "react";
import {VanillaRendererProps} from "@jsonforms/vanilla-renderers";
import {getMaxValue, getMinValue, withVanillaCellProps} from "./utils";
import {Form} from "react-bootstrap";
import classNames from "classnames";

const toNumber = (value: string) =>
  value === '' ? undefined : Number(value);

const NumberCellWithRange = (props: CellProps & VanillaRendererProps) => {
  const { data, className, id, enabled, uischema, schema, path, handleChange } = props;

  return (
    <>
      <Form.Control
        type='number'
        min={getMinValue(schema)}
        max={getMaxValue(schema)}
        step={schema.multipleOf || 0.1}
        value={data ?? ''}
        onChange={ev => handleChange(path, toNumber(ev.target.value))}
        className={classNames(className, "mb-2")}
        id={id}
        disabled={!enabled}
        autoFocus={uischema.options && uischema.options.focus}
      />
      <Form.Range
        min={getMinValue(schema)}
        max={getMaxValue(schema)}
        step={schema.multipleOf || 0.1}
        value={data ?? ''}
        onChange={ev => handleChange(path, toNumber(ev.target.value))}
        className={className}
        disabled={!enabled}
        autoFocus={uischema.options && uischema.options.focus}
      />
    </>
  );
};

export default withJsonFormsCellProps(withVanillaCellProps(NumberCellWithRange));
