import {UseFormReturn} from "react-hook-form/dist/types";
import {Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import {SHOW_VALID_STYLE} from "../../helpers/utils";
import {buildRoute} from "../../routes/utils";
import {RootRoutes} from "../../routes/RootRoutes";

export default function AdditionalInformationForm(
  {
    form,
    includeAccountName = true
  }: { form: UseFormReturn, includeAccountName?: boolean }
)
{
  const {
    register,
    formState: {
      errors,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      touchedFields, // This needs to be here for the field change to register correctly.
    }
  } = form;
  const {organizationName} = form.getValues();

  return (
    <>
      {
        includeAccountName && (
          <Form.Group className="d-flex flex-column">
            <Form.Label className="mb-0 fw-bold">Account name</Form.Label>
            <Form.Text>An organization can have multiple accounts. Choose a name for the first one.</Form.Text>
            <Form.Control
              type="input"
              placeholder={organizationName && organizationName !== "" ? `${organizationName} - Default` : undefined}
              {
                ...register(
                  "accountName",
                )
              }
              isInvalid={errors["accountName"] !== undefined}
              isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors["accountName"] === undefined}
            />
            <Form.Control.Feedback type="invalid">
              {errors["accountName"]?.message?.toString()}
            </Form.Control.Feedback>
          </Form.Group>
        )
      }
      <Form.Group className="mt-4 mb-2 d-flex flex-column">
        <Form.Check
          type="checkbox"
        >
          <Form.Check.Input
            {
              ...register(
                "termsAccepted",
                {
                  required: "You must accept the terms and conditions"
                }
              )
            }
            isInvalid={errors["termsAccepted"] !== undefined}
            isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors["termsAccepted"] === undefined}
          />
          <Form.Check.Label>I have read and accept the <Link to={buildRoute(RootRoutes.TERMS_AND_CONDITIONS)} target="_blank">terms and
            conditions</Link></Form.Check.Label>
          <Form.Control.Feedback type="invalid">
            {errors["termsAccepted"]?.message?.toString()}
          </Form.Control.Feedback>
        </Form.Check>
      </Form.Group>
    </>
  )
}
