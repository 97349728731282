import {apiDetail, apiList, ApiViewSet, DetailOptions, Page, useList, useRetrieve} from "./baseApi";
import {ApiQueryParams} from "./baseQueryParams";
import {ApiError, FetchOptions} from "./utils";
import {UseQueryOptions, UseQueryResult} from "react-query";


export interface DevelopmentSecurityPracticesFramework {
  url: string;
  id: string;
  account: string;
  account_id: string;
  name: string;
  description: string;
  tagline: string;
  features: string[];
  parameters_json_schema: any;
}

const developmentSecurityPracticesFrameworkViewSet: ApiViewSet = {
  baseName: 'development-security-practices-frameworks',
  endpointPrefix: '/security-policy',
}

enum DevelopmentSecurityPracticesFrameworkQueryParams {
}

function listDevelopmentSecurityPracticesFrameworks(queryParams?: ApiQueryParams<DevelopmentSecurityPracticesFrameworkQueryParams> | null, fetchOptions?: FetchOptions): () => Promise<Page<DevelopmentSecurityPracticesFramework>> {
  return apiList<DevelopmentSecurityPracticesFramework, DevelopmentSecurityPracticesFrameworkQueryParams>(developmentSecurityPracticesFrameworkViewSet, queryParams, fetchOptions);
}

function retrieveDevelopmentSecurityPracticesFramework(options: DetailOptions, queryParams?: ApiQueryParams<DevelopmentSecurityPracticesFrameworkQueryParams>, fetchOptions?: FetchOptions): () => Promise<DevelopmentSecurityPracticesFramework> {
  return apiDetail({
    viewSet: developmentSecurityPracticesFrameworkViewSet,
    options: options,
    queryParams: queryParams,
    fetchOptions: fetchOptions,
  });
}

export function useListDevelopmentSecurityPracticesFrameworks(url?: string, queryParams?: ApiQueryParams<DevelopmentSecurityPracticesFrameworkQueryParams> | null, queryOptions?: UseQueryOptions<Page<DevelopmentSecurityPracticesFramework>>, fetchOptions?: FetchOptions): UseQueryResult<Page<DevelopmentSecurityPracticesFramework>> {
  return useList({
    viewSet: developmentSecurityPracticesFrameworkViewSet,
    queryParamsEnum: DevelopmentSecurityPracticesFrameworkQueryParams,
    fetchFn: listDevelopmentSecurityPracticesFrameworks,
    url: url,
    queryParams: queryParams,
    queryOptions: queryOptions,
    fetchOptions: fetchOptions,
  });
}


export function useRetrieveDevelopmentSecurityPracticesFramework(developmentSecurityPracticesFrameworkId?: string | null, options?: UseQueryOptions<DevelopmentSecurityPracticesFramework, ApiError>, queryParams?: ApiQueryParams<DevelopmentSecurityPracticesFrameworkQueryParams>, fetchOptions?: FetchOptions) {
  return useRetrieve({
    viewSet: developmentSecurityPracticesFrameworkViewSet,
    queryParamsEnum: DevelopmentSecurityPracticesFrameworkQueryParams,
    queryParams: queryParams,
    fetchFn: retrieveDevelopmentSecurityPracticesFramework,
    detail: developmentSecurityPracticesFrameworkId ? {id: developmentSecurityPracticesFrameworkId} : undefined,
    fetchOptions: fetchOptions,
  })
}
