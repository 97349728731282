import {createContext} from "react";

interface AppContextInterface {
  showNavbar: boolean;
  setShowNavbar: (value: boolean) => void;
  showBurgerMenu: boolean;
  setShowBurgerMenu: (value: boolean) => void;
}

const AppContext = createContext<AppContextInterface>({
  showNavbar: true,
  setShowNavbar: () => {},
  showBurgerMenu: false,
  setShowBurgerMenu: () => {},
});

export default AppContext;
