import {QueryObserverResult} from "react-query/types/core/types";
import React from "react";
import {Spinner} from "react-bootstrap";
import {isApiError} from "../../api/utils";

interface ReactQueryLoaderProps<TData = unknown, TError = unknown> {
  queryResult: QueryObserverResult<TData, TError>;
  children?: React.ReactNode;
  errorMessage?: React.ReactNode;
  className?: string;
  classNameIdle?: string;
  classNameLoading?: string;
  classNameError?: string;
}

export function ReactQuerySpinner({ className, size }: {className?: string, size?: "sm"}) {
  return (
    <Spinner size={size} animation="border" role="status" className={className}>
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  )
}

export function ReactQueryError({ errorMessage, className }: {errorMessage?: React.ReactNode, className?: string}) {
  return (
    <div className={className}>
      {
        errorMessage
          ? errorMessage
          : "An error occurred"
      }
    </div>
  )
}

export default function ReactQueryLoader({ queryResult, errorMessage, children, className, classNameIdle, classNameLoading, classNameError }: ReactQueryLoaderProps) {
  if (queryResult.isIdle) {
    return <ReactQuerySpinner className={classNameIdle || className}/>
  }
  else if (queryResult.isLoading) {
    return <ReactQuerySpinner className={classNameLoading || className}/>
  }
  else if (queryResult.isSuccess) {
    return <>{children}</>;
  }
  else if (queryResult.isError) {
    if (isApiError(queryResult.error)) {
      if (queryResult.error.status === 403) {
        return <ReactQueryError errorMessage="Access denied" className={classNameError || className} />
      }
      else if (queryResult.error.status === 404) {
        return <ReactQueryError errorMessage="Item not found" className={classNameError || className} />
      }
    }
    return <ReactQueryError errorMessage={errorMessage} className={classNameError || className} />
  }
  else {
    return <ReactQueryError errorMessage={errorMessage} className={classNameError || className} />
  }
}
