import React from "react";
import {CloseButton} from "react-bootstrap";
import classNames from "classnames";

interface FalconCloseButtonProps {
  size?: 'sm' | 'lg';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  noOutline?: boolean;
  variant?: "white";
  className?: string;
}

export default function FalconCloseButton({
 size,
 onClick,
 noOutline,
 variant,
 className,
 ...rest
}: FalconCloseButtonProps) {
  return (
    <CloseButton
      variant={variant ? variant : undefined}
      className={classNames(className, {
        [`btn-${size}`]: size,
        'outline-none': noOutline
      })}
      onClick={onClick && onClick}
      {...rest}
    />
  );
};
