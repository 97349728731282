import {SecurityPolicy, useUpdateSecurityPolicy} from "../../api/SecurityPolicy";
import Flex from "../falcon/common/Flex";
import {ReactQuerySpinner} from "../common/ReactQueryLoader";
import {Alert} from "react-bootstrap";
import React from "react";
import {
  DevelopmentSecurityPracticesFramework,
  useListDevelopmentSecurityPracticesFrameworks
} from "../../api/DevelopmentSecurityPracticesFramework";
import DevelopmentSecurityPracticesFrameworkSelectionComponent
  from "./DevelopmentSecurityPracticesFrameworkSelectionComponent";


export default function DevelopmentSecurityPracticesFrameworkSelectionController({securityPolicy}: { securityPolicy: SecurityPolicy }) {
  const developmentSecurityPracticesFrameworksQuery = useListDevelopmentSecurityPracticesFrameworks()
  const updateSecurityPolicyMutation = useUpdateSecurityPolicy();

  const onSelectDevelopmentSecurityPracticesFramework = (framework: DevelopmentSecurityPracticesFramework) => {
    updateSecurityPolicyMutation.mutate({
      ...securityPolicy,
      development_security_practices_framework_id: framework.id,
    })
  }

  if (updateSecurityPolicyMutation.isLoading) {
    return (
      <Flex justifyContent="center"><ReactQuerySpinner/></Flex>
    )
  } else if (updateSecurityPolicyMutation.isError) {
    return (
      <Alert variant="danger" dismissible={true} onClose={() => {
        updateSecurityPolicyMutation.reset()
      }}>Could not select risk classification scheme.</Alert>
    )
  }
  if (developmentSecurityPracticesFrameworksQuery.isError) {
    return (
      <Alert variant="danger">Could not retrieve available Development Security Practices Frameworks.</Alert>
    )
  } else if (developmentSecurityPracticesFrameworksQuery.isSuccess) {
    return (
      <DevelopmentSecurityPracticesFrameworkSelectionComponent
        developmentSecurityPracticesFrameworks={developmentSecurityPracticesFrameworksQuery.data.results}
        onSelect={onSelectDevelopmentSecurityPracticesFramework}
      />
    )
  } else {
    return (
      <Flex justifyContent="center"><ReactQuerySpinner/></Flex>
    )
  }

}
