import React, {useEffect, useState} from "react";
import {SecurityPolicy, useUpdateSecurityPolicy} from "../../api/SecurityPolicy";
import {RiskClassificationSchemeSelectionController} from "./RiskClassificationSchemeSelectionController";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import {RiskClassificationScheme, useRetrieveRiskClassificationScheme} from "../../api/RiskClassificationScheme";
import Flex from "../falcon/common/Flex";
import {ReactQuerySpinner} from "../common/ReactQueryLoader";
import {JsonForms} from "@jsonforms/react";
import {bootstrapCells, bootstrapRenderers} from "../jsonforms-renderers";
import {JsonFormsCore} from "@jsonforms/core";

interface RiskClassificationDetailProps {
  securityPolicy: SecurityPolicy;
  riskClassificationScheme: RiskClassificationScheme;
}

function RiskClassificationDetail({securityPolicy, riskClassificationScheme}: RiskClassificationDetailProps) {
  const securityPolicyMutation = useUpdateSecurityPolicy();
  const [formData, setFormData] = useState(securityPolicy.risk_classification_parameters);
  const [formErrors, setFormErrors] = useState<any[] | undefined>([]);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    setFormData(securityPolicy.risk_classification_parameters)
  }, [securityPolicy.id])

  const onChange = (state: Pick<JsonFormsCore, 'data' | 'errors'>) => {
    setFormData(state.data);
    setFormErrors(state.errors)
  }

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    console.log("Form data", formData);

    if (!formErrors || formErrors.length === 0) {
      console.log("No errors, submit now!", formData)
      securityPolicyMutation.mutate({
        ...securityPolicy,
        risk_classification_parameters: formData,
      })
    }

    setSubmitted(true);
  }


  return (
    <>
      <h5 className="mb-4 fw-bold">{riskClassificationScheme.name}</h5>
      <p>
        {riskClassificationScheme.description}
      </p>
      <Form onSubmit={onSubmit} noValidate>
        <Row className="mb-4">
          <Col xxl={8}>
            {
              securityPolicyMutation.isError && (
                <Alert variant="danger" dismissible onClose={() => {securityPolicyMutation.reset()}}>Could not save data. An error occurred.</Alert>
              )
            }
            {
              securityPolicyMutation.isSuccess && (
                <Alert variant="success" dismissible onClose={() => {securityPolicyMutation.reset()}}>Data saved successfully!</Alert>
              )
            }
            <JsonForms
              schema={riskClassificationScheme.parameters_json_schema}
              cells={bootstrapCells}
              data={formData}
              renderers={bootstrapRenderers}
              config={{
                hideRequiredAsterisk: true,
                showUnfocusedDescription: true,
                addValidationClassName: submitted,
              }}
              onChange={onChange}
              readonly={securityPolicyMutation.isLoading}
            />
          </Col>
        </Row>

        <Button type="submit" disabled={securityPolicyMutation.isLoading}>
          {
            securityPolicyMutation.isLoading
              ? <ReactQuerySpinner size="sm" />
              : "Save"
          }
        </Button>
      </Form>
    </>
  )
}


function RiskClassificationDetailController({securityPolicy}: { securityPolicy: SecurityPolicy }) {
  const riskClassificationSchemeQuery = useRetrieveRiskClassificationScheme(securityPolicy.risk_classification_scheme_id);

  if (riskClassificationSchemeQuery.isError || (riskClassificationSchemeQuery.data && !riskClassificationSchemeQuery.data.parameters_json_schema)) {
    return (
      <Alert variant="danger">The risk classification scheme information could not be retrieved.</Alert>
    )
  }
  else if (riskClassificationSchemeQuery.isSuccess) {
    return (
      <RiskClassificationDetail securityPolicy={securityPolicy} riskClassificationScheme={riskClassificationSchemeQuery.data} />
    )
  }
  else {
    return (
      <Flex justifyContent="center"><ReactQuerySpinner/></Flex>
    )
  }
}


export function RiskClassificationCardController({securityPolicy}: { securityPolicy: SecurityPolicy }) {
  if (!securityPolicy.risk_classification_scheme_id || securityPolicy.risk_classification_scheme_id === "") {
    return <RiskClassificationSchemeSelectionController securityPolicy={securityPolicy}/>
  } else {
    return <RiskClassificationDetailController securityPolicy={securityPolicy} />
  }
}
