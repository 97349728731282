import {BrowserRouter, Route, Routes} from "react-router-dom";
import PrivateRoute from "../components/common/PrivateRoute";
import LoginPage from "../pages/login";
import {MainRoutesComponent} from "./MainRoutesComponent";
import {InvitationRoutesComponent} from "./InvitationRoutesComponent";
import TermsAndConditionsPage from "../pages/TermsAndConditionsPage";

export enum RootRoutes {
  LOGIN = "/login/",
  INVITATION = "/invitation/*",
  TERMS_AND_CONDITIONS = "/terms_and_conditions/",
}

export function RootRoutesComponent() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={RootRoutes.LOGIN} element={<LoginPage/>} />
        <Route path={RootRoutes.INVITATION} element={<InvitationRoutesComponent/>} />
        <Route path={RootRoutes.TERMS_AND_CONDITIONS} element={<TermsAndConditionsPage/>} />
        <Route path="*" element={<PrivateRoute><MainRoutesComponent/></PrivateRoute>}>
        </Route>
      </Routes>
    </BrowserRouter>

  )
}
