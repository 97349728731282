import {
  apiDetail,
  apiList,
  apiUpdate,
  ApiViewSet,
  DetailOptions,
  Page,
  ResourceId,
  useList,
  useRetrieve,
  useUpdate
} from "./baseApi";
import {ApiQueryParams} from "./baseQueryParams";
import {FetchOptions} from "./utils";
import {UseMutationOptions, UseQueryOptions, UseQueryResult} from "react-query";

export type UpdateAccount = {
  name: string;
} & ResourceId;

export interface Account {
  url: string;
  id: string;
  name: string;
}

const accountViewSet: ApiViewSet = {
  baseName: 'accounts',
  endpointPrefix: '/auth',
}

enum AccountQueryParams {
}

function listAccounts(queryParams?: ApiQueryParams<AccountQueryParams> | null, fetchOptions?: FetchOptions): () => Promise<Page<Account>> {
  return apiList<Account, AccountQueryParams>(accountViewSet, queryParams, fetchOptions);
}

function retrieveAccount(options: DetailOptions, queryParams?: ApiQueryParams<AccountQueryParams>, fetchOptions?: FetchOptions): () => Promise<Account> {
  return apiDetail({viewSet: accountViewSet, options: options, queryParams: queryParams, fetchOptions: fetchOptions});
}

function updateAccount(fetchOptions?: FetchOptions): (variables: UpdateAccount) => Promise<Account> {
  return apiUpdate<UpdateAccount, Account>(accountViewSet, fetchOptions);
}

export function useListAccounts(url?: string, queryParams?: ApiQueryParams<AccountQueryParams> | null, queryOptions?: UseQueryOptions<Page<Account>>, fetchOptions?: FetchOptions): UseQueryResult<Page<Account>> {
  return useList({
    viewSet: accountViewSet,
    queryParamsEnum: AccountQueryParams,
    fetchFn: listAccounts,
    url: url,
    queryParams: queryParams,
    queryOptions: queryOptions,
    fetchOptions: fetchOptions,
  });
}

export function useRetrieveAccount(options: DetailOptions | null, fetchOptions?: FetchOptions) {
  return useRetrieve({
    viewSet: accountViewSet,
    queryParamsEnum: AccountQueryParams,
    fetchFn: retrieveAccount,
    detail: options,
    fetchOptions: fetchOptions,
  });
}

export function useUpdateAccount(options?: UseMutationOptions<Account, unknown, UpdateAccount>, fetchOptions?: FetchOptions) {
  return useUpdate({
    viewSet: accountViewSet,
    updateFn: updateAccount,
    options: options,
    fetchOptions: fetchOptions,
  })
}
