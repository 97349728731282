import classNames from "classnames";
import React, {CSSProperties} from "react";

interface SoftBadgeProps {
  bg?: "primary" | "secondary" | 'success' | 'info' | 'warning' | 'danger' | 'light' | 'dark';
  pill?: boolean;
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  button?: boolean;
}

const SoftBadge = React.forwardRef<HTMLDivElement, SoftBadgeProps>(({ bg = 'primary', pill, children, className, style, button}, ref) => {
  return (
    <div
      ref={ref}
      className={classNames(className, `badge badge-soft-${bg}`, {
        'rounded-pill': pill,
        'btn btn-link': button,
      })}
      style={style}
    >
      {children}
    </div>
  );
});

export default SoftBadge;

// export default function SoftBadge({ bg = 'primary', pill, children, className, style, as: Tag = "div" }: SoftBadgeProps) {
//   return (
//     <Tag
//       className={classNames(className, `badge badge-soft-${bg}`, {
//         'rounded-pill': pill,
//         'btn btn-link': Tag === "button",
//       })}
//       style={style}
//     >
//       {children}
//     </Tag>
//   );
// };
