import {createContext} from "react";
import {Account} from "../../api/Account";

interface AccountContextInterface {
  account: Account;
  setAccount: (account: Account) => void;
}

const AccountContext = createContext<AccountContextInterface>({
  account: {
    id: "",
    url: "",
    name: "",
  },
  setAccount: (account: Account) => {}
});

export default AccountContext;
