import React, { Suspense } from "react";
import RootInformationCard from "../components/common/RootInformationCard";
import {ReactQuerySpinner} from "../components/common/ReactQueryLoader";
import Flex from "../components/falcon/common/Flex";

const TermsAndConditionsContent = React.lazy(() => import("../components/common/TermsAndConditionsContent"));

function LoadingTermsAndConditions() {
  return (
    <RootInformationCard>
      <Flex justifyContent="center">
        <ReactQuerySpinner />
      </Flex>
    </RootInformationCard>
  )
}

export default function TermsAndConditionsPage() {
  return (
    <Suspense fallback={<LoadingTermsAndConditions />}>
      <TermsAndConditionsContent />
    </Suspense>
  )
}
