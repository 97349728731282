import {Card} from "react-bootstrap";
import FalconCardHeader from "../../falcon/common/FalconCardHeader";

export default function GovernanceDocumentation() {
  return (
    <Card>
      {/*<FalconCardHeader light titleTag="h3" title="Risk management documentation" endEl={<CloseButton />}></FalconCardHeader>*/}
      <FalconCardHeader light titleTag="h5" title="Documentation"></FalconCardHeader>
      <Card.Body>
        <p>
          Governance, also known as <span className="fw-bold">Secu</span>rity
          <span className="fw-bold"> ma</span>nagement, is a core activity in Secuma. It includes all activities to
          define, direct, support and evaluate the security efforts of the organization. These efforts are often
          performed by top-level management such as the CTO, CISO or even the board of directors in very large
          organizations. The activities are meant to evaluate the processes and tools in use and direct their
          improvement where necessary. Often these requirements are mandated by legal or industry regulations.
        </p>
        <p>
          Secuma currently provides a tool to perform an <a href="https://owaspsamm.org/model/">OWASP SAMM </a>
          assessment. This assessment aims to provide a measurable understanding of the current security posture of the
          organization in regard to software development. Afterwards a list of activities is presented for the
          organization to improve their security posture. While the tool is most effective when used by a security
          professional, we took great care to make it usable by developers less familiar with security practices. This
          allows the tool to be useful with the use of existing organizational resources.
        </p>
        <p>
          The use of the tool and the implementation of its suggested improvements will measurably increase the
          security posture of the software produced by the company and thus increase its value for customers. However,
          because this is notoriously difficult to communicate to customers, some organizations will feel the need to
          obtain independent and external certification of their security efforts. Common examples are ISO, NIST or CSA
          certifications. Secuma supports this goal and will mark all improvements that work towards these certifications so
          they can be prioritized when choosing which improvements to implement.
        </p>
      </Card.Body>
    </Card>
  )
}
