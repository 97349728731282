export function capitalize(str: string): string {
  return (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, ' ');
}

export function isIterableArray<T>(array: any): array is Array<T> {
  return Array.isArray(array) && !!array.length;
}

export function getColor(name: string, dom = document.documentElement) {
  return getComputedStyle(dom).getPropertyValue(`--falcon-${name}`).trim();
}
