import Select, {StylesConfig} from "react-select";
import {useMemo} from "react";
import countryList from "react-select-country-list";
import {useController, UseControllerProps} from "react-hook-form";
import classNames from "classnames";


const countryData = countryList().getData();

type CountryFieldProps = UseControllerProps & {
  isValid: boolean;
  isInvalid: boolean;
}

export default function CountryField(controllerProps: CountryFieldProps) {
  const options = useMemo(() => countryData, []);
  const {field} = useController(controllerProps);

  const customStyles: StylesConfig = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? base.borderColor : (controllerProps.isInvalid ? "var(--falcon-danger) !important" : (controllerProps.isValid ? "var(--falcon-success) !important" : base.borderColor)),
      "&:hover": {
        borderColor: state.isFocused ? base.borderColor : (controllerProps.isInvalid ? "var(--falcon-danger) !important" : (controllerProps.isValid ? "var(--falcon-success) !important" : base.borderColor)),
      }
    })
  }

  return (
    <Select
      classNamePrefix="react-select"
      isMulti={false}
      className={
        classNames({"is-invalid": controllerProps.isInvalid})
      }
      styles={customStyles}
      options={options}
      {...field}
      placeholder="Country"
    />
  )
}
