import React, {useContext} from "react";
import AccountContext from "../context/AccountContext";
import {Alert, Button, Form} from "react-bootstrap";
import {SubmitHandler, useForm} from "react-hook-form";
import {SHOW_VALID_STYLE} from "../../helpers/utils";
import {ReactQuerySpinner} from "./ReactQueryLoader";
import {useCreateSupportRequest} from "../../api/SupportRequest";

interface SupportFormInput {
  subject: string;
  message: string;
}

interface SupportFormComponentProps {
  onDone?: () => void;
  defaultSubject?: string;
  defaultMessage?: string;
  additionalInfo?: string;
}


export default function SupportFormComponent({onDone, defaultSubject, defaultMessage, additionalInfo}: SupportFormComponentProps) {
  const {account} = useContext(AccountContext);
  const {
    register,
    handleSubmit,
    formState: {
      errors,
    }
  } = useForm<SupportFormInput>({
    defaultValues: {
      "subject": defaultSubject,
      "message": defaultMessage,
    }
  });
  const createMutation = useCreateSupportRequest();

  const onSubmit: SubmitHandler<SupportFormInput> = (data, event) => {
    event && event.stopPropagation();
    createMutation.mutate({
      account_id: account.id,
      subject: data.subject,
      message: data.message,
      additional_info: additionalInfo,
    }, {
      onSuccess: () => {
        onDone && onDone();
      }
    })
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {
        createMutation.isError && (
          <Alert variant="danger">Error sending message.</Alert>
        )
      }
      {
        createMutation.isSuccess && (
          <Alert variant="success">Message successfully sent.</Alert>
        )
      }
      <Form.Group className="mb-4 d-flex flex-column">
        <Form.Label className="mb-0 fw-bold">Subject</Form.Label>
        <Form.Control
          type="input"
          disabled={createMutation.isLoading || createMutation.isSuccess}
          {...register('subject', {required: 'A subject is required'})}
          isInvalid={errors['subject'] !== undefined}
          isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors['subject'] === undefined}
        />
        <Form.Control.Feedback type="invalid">
          {errors['subject']?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-4 d-flex flex-column">
        <Form.Label className="mb-0 fw-bold">Message</Form.Label>
        <Form.Control
          as="textarea"
          rows={8}
          disabled={createMutation.isLoading || createMutation.isSuccess}
          {...register('message', {required: 'A message is required'})}
          isInvalid={errors['message'] !== undefined}
          isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors['message'] === undefined}
        />
        <Form.Control.Feedback type="invalid">
          {errors['message']?.message}
        </Form.Control.Feedback>
      </Form.Group>
      {
        createMutation.isLoading
          ? <Button disabled={true}><ReactQuerySpinner/></Button>
          : <Button type="submit" disabled={createMutation.isSuccess}>Send message</Button>
      }
    </Form>
  )
}
