import {apiDetail, apiList, ApiViewSet, DetailOptions, Page, useList, useRetrieve} from "./baseApi";
import {ApiQueryParams} from "./baseQueryParams";
import {FetchOptions} from "./utils";
import {UseQueryOptions, UseQueryResult} from "react-query";


export interface User {
  url: string;
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  avatar_url: string;
  last_login?: string | null;
}


const userViewSet: ApiViewSet = {
  baseName: 'users',
  endpointPrefix: '/auth',
}

enum UserQueryParams {
  ACCOUNT_ID = "account_id"
}

function listUsers(queryParams?: ApiQueryParams<UserQueryParams> | null, fetchOptions?: FetchOptions): () => Promise<Page<User>> {
  return apiList<User, UserQueryParams>(userViewSet, queryParams, fetchOptions);
}

function retrieveUser(options: DetailOptions, queryParams?: ApiQueryParams<UserQueryParams>, fetchOptions?: FetchOptions): () => Promise<User> {
  return apiDetail({
    viewSet: userViewSet,
    options: options,
    queryParams: queryParams,
    fetchOptions: fetchOptions,
  });
}

export function useListUsers(url?: string, queryParams?: ApiQueryParams<UserQueryParams> | null, queryOptions?: UseQueryOptions<Page<User>>, fetchOptions?: FetchOptions): UseQueryResult<Page<User>> {
  return useList({
    viewSet: userViewSet,
    queryParamsEnum: UserQueryParams,
    fetchFn: listUsers,
    url: url,
    queryParams: queryParams,
    queryOptions: queryOptions,
    fetchOptions: fetchOptions,
  });
}

export function useRetrieveUser(options: DetailOptions | null, fetchOptions?: FetchOptions) {
  return useRetrieve({
    viewSet: userViewSet,
    queryParamsEnum: UserQueryParams,
    fetchFn: retrieveUser,
    detail: options,
    fetchOptions: fetchOptions,
  });
}

export function userFullName(user: User) {
  return `${user.first_name} ${user.last_name}`.trim();
}
