import {RiskClassificationScheme} from "../../api/RiskClassificationScheme";
import React, {useContext} from "react";
import SubscriptionContext from "../context/SubscriptionContext";
import {getRiskClassificationSchemesForSubscription} from "../../api/Subscription";
import {Alert, Row} from "react-bootstrap";
import OptionSelectionCol from "../common/OptionSelectionCol";

interface RiskClassificationSchemeSelectionComponentProps {
  riskClassificationSchemes: RiskClassificationScheme[];
  onSelect: (riskClassificationScheme: RiskClassificationScheme) => void;
}

export function RiskClassificationSchemeSelectionComponent({
                                                       riskClassificationSchemes,
                                                       onSelect
                                                     }: RiskClassificationSchemeSelectionComponentProps) {
  const {subscription} = useContext(SubscriptionContext)
  const riskClassificationSchemesAvailable = getRiskClassificationSchemesForSubscription(subscription) || [];
  const featuredItem = riskClassificationSchemes.find((item) => {
    return riskClassificationSchemesAvailable.includes(item.id);
  })

  return (
    <>
      <p>
        Choose a risk classification scheme. Some commonly used examples are shown below.
      </p>
      {
        riskClassificationSchemes.length > 0
          ? (
            <Row className="g-0">
              {
                riskClassificationSchemes.map((riskClassificationScheme, index) => {
                  const isAvailable = riskClassificationSchemesAvailable.includes(riskClassificationScheme.id)
                  return (
                    <OptionSelectionCol
                      title={riskClassificationScheme.name}
                      description={riskClassificationScheme.description}
                      onSelect={() => {
                        onSelect(riskClassificationScheme)
                      }}
                      isFeatured={featuredItem === riskClassificationScheme}
                      isDisabled={!isAvailable}
                      disabledDescription={!isAvailable ? "Not available on your current plan." : undefined}
                      featureTitle={riskClassificationScheme.tagline}
                      features={riskClassificationScheme.features.map(feature => {
                        return {
                          id: feature,
                          title: feature,
                        }
                      })}
                    />
                  )
                })
              }
            </Row>
          )
          : (
            <Alert variant="warning">No usable risk classification schemes available.</Alert>
          )
      }
    </>
  )
}
