import {Alert, Button, Modal} from "react-bootstrap";
import {SubscriptionPlan, useCreateSubscription, useListSubscriptions} from "../../api/Subscription";
import {useContext} from "react";
import AccountContext from "../context/AccountContext";
import FalconCloseButton from "../falcon/common/FalconCloseButton";
import {Plan} from "./PricingDefaultContainer";
import {ReactQuerySpinner} from "../common/ReactQueryLoader";
import SupportButton from "../common/SupportButton";
import Flex from "../falcon/common/Flex";
import {timeUntil} from "../../helpers/utils";
import {DateTime} from "luxon";
import {PriceComponent} from "../falcon/pricing/PricingDefaultCard";

interface SubscriptionPurchaseModalProps {
  plan?: Plan | null;
  show?: boolean;
  onHide?: () => void;
}

export default function SubscriptionPurchaseModal({plan, show, onHide}: SubscriptionPurchaseModalProps) {
  const {account} = useContext(AccountContext);
  const subscriptionQuery = useListSubscriptions(undefined, {active: "1", account_id: account.id});
  const createSubscriptionMutation = useCreateSubscription();

  const onHideInternal = () => {
    createSubscriptionMutation.reset();
    onHide && onHide();
  }

  let content: React.ReactNode;
  if (subscriptionQuery.isError) {
    content = (
      <>
        <Alert variant="danger">Unexpected error. Please try again later.</Alert>
        <Flex justifyContent="center">
          <SupportButton additionalInfo={`Error during subscription create: ${JSON.stringify(subscriptionQuery.error)}`}>Contact us</SupportButton>
        </Flex>
      </>
    )
  }
  else if (!plan || !subscriptionQuery.data) {
    content = <ReactQuerySpinner />;
  }
  else {
    const actualPrice = plan.reducedPrice !== undefined ? plan.reducedPrice : plan.price;

    const onConfirmPlan = () => {
      createSubscriptionMutation.mutate({
        account_id: account.id,
        plan: plan.type,
      })
    }

    const intro = <p>You have selected the <span className="fw-bold">{plan.title}</span> plan for the <span className="fw-bold">{account.name ? account.name : account.id}</span> account.</p>
    if (subscriptionQuery.data.results.length > 0 && subscriptionQuery.data.results[0].plan === plan.type) {
      content = (
        <>
          {intro}
          <p>This is already your current plan. No change is required.</p>
          <Button variant="secondary" className="w-100" onClick={onHideInternal}>Close</Button>
        </>
      )
    }
    else {
      content = (
        <>
          {
            createSubscriptionMutation.isError && (
              <Alert variant="danger">Unable to confirm subscription.</Alert>
            )
          }
          {
            createSubscriptionMutation.isSuccess && (
              <Alert variant="success">Your request to change subscription has been submitted. We will notify you when the new subscription will apply.</Alert>
            )
          }
          {intro}
          <h2 className="my-4 fw-medium d-flex justify-content-center">
            {
              plan.reducedPrice !== undefined && (
                <div className="text-decoration-line-through text-500">
                  {
                    typeof plan.price === "string"
                      ? plan.price
                      : (
                        <>
                          <span className="fw-normal fs-2">€</span>
                          {plan.price}
                        </>
                      )
                  }
                </div>
              )
            }
            {
              typeof actualPrice === "string"
                ? actualPrice
                : <div><PriceComponent price={actualPrice} /></div>
            }
          </h2>
          {
            subscriptionQuery.data.results.length > 0 && subscriptionQuery.data.results[0].plan === SubscriptionPlan.TRIAL && subscriptionQuery.data.results[0].end_date && (
              <p>The new plan will apply after your trial ends in {timeUntil(DateTime.fromISO(subscriptionQuery.data.results[0].end_date), undefined, 1).reconfigure({locale: "en"}).toHuman()}.</p>
            )
          }
          <Button className="w-100" onClick={onConfirmPlan} disabled={createSubscriptionMutation.isLoading || createSubscriptionMutation.isSuccess}>Confirm plan</Button>
        </>
      )
    }
  }


  return (
    <Modal show={show} onHide={onHideInternal}>
      <Modal.Body className="p-4">
        <FalconCloseButton
          size="sm"
          className="position-absolute top-0 end-0 me-2 mt-2"
          onClick={onHideInternal}
        />
        {content}
      </Modal.Body>
    </Modal>
  )
}
