import {Invitation} from "../../api/Invitations";
import {useAuth} from "react-oidc-context";
import {useRetrieveUser} from "../../api/User";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Alert, Button} from "react-bootstrap";
import {buildRoute} from "../../routes/utils";
import {RootRoutes} from "../../routes/RootRoutes";
import {InvitationRoutes} from "../../routes/InvitationRoutes";
import {ReactQuerySpinner} from "../common/ReactQueryLoader";
import NewAccountCheckComponent from "./NewAccountCheckComponent";

interface LoginRequiredCheckComponentProps {
  invitation: Invitation;
  code: string;
}

export default function LoginRequiredCheckComponent({invitation, code}: LoginRequiredCheckComponentProps) {
  const auth = useAuth();
  const userQuery = useRetrieveUser(auth.isAuthenticated && invitation.user_id ? {id: "me"} : null);
  const navigate = useNavigate();
  const location = useLocation();

  if (!auth.isAuthenticated) {
    if (invitation.user_id) {
      // Not logged in but user locked, show redirect to login
      return (
        <>
          <Alert variant="warning mb-4">Please login to use this code</Alert>
          <Button className="w-100 mb-2" variant="primary" onClick={() => {
            navigate("/login", {state: {from: location.pathname}});
          }}>Continue to login</Button>
          <Link to={buildRoute(RootRoutes.INVITATION, InvitationRoutes.INDEX)}
                className="btn btn-outline-primary w-100">Enter other code</Link>
        </>
      )
    } else {
      // Not logged in and not user locked. Continue with NewAccountCheck.
      return <NewAccountCheckComponent invitation={invitation} code={code}/>
    }
  } else {
    if (invitation.user_id) {
      // We are authenticated and the invitation is user locked. Now check if we are the correct user.
      if (userQuery.isLoading) {
        return <div className="d-flex justify-content-center"><ReactQuerySpinner/></div>;
      }
      if (userQuery.isError) {
        return (
          <>
            <Alert variant="danger mb-4">Unexpected problem</Alert>
            <Button className="w-100 mb-2" variant="primary" onClick={() => {
              userQuery.refetch()
            }}>Try again</Button>
            <Link to={buildRoute(RootRoutes.INVITATION, InvitationRoutes.INDEX)}
                  className="btn btn-outline-primary w-100">Enter other code</Link>
          </>
        )
      }
      if (!userQuery.isSuccess) {
        return (
          <>
            <Alert variant="danger mb-4">Unexpected problem. Please wait...</Alert>
            <Link to={buildRoute(RootRoutes.INVITATION, InvitationRoutes.INDEX)}
                  className="btn btn-outline-primary w-100">...or enter new code</Link>
          </>
        )
      }

      if (userQuery.data.id !== invitation.user_id) {
        return (
          <>
            <Alert variant="warning mb-4">This invitation is not for you. Login as the recipient.</Alert>
            <Button className="w-100 mb-2" variant="primary" onClick={() => {
              navigate("/login", {state: {from: location.pathname}});
            }}>Continue to login</Button>
            <Link to={buildRoute(RootRoutes.INVITATION, InvitationRoutes.INDEX)}
                  className="btn btn-outline-primary w-100">Enter other code</Link>
          </>
        )
      } else {
        return <NewAccountCheckComponent invitation={invitation} code={code}/>
      }
    } else {
      // We are authenticated although this is not necessary for this code. Continue...
      return <NewAccountCheckComponent invitation={invitation} code={code}/>
    }
  }
}
