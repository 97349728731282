import {Button} from "react-bootstrap";
import {useEffect, useRef, useState} from "react";
import {ButtonProps} from "react-bootstrap/Button";
import {SupportModal} from "./SupportModal";


interface SupportButtonProps extends ButtonProps {
  defaultSubject?: string;
  defaultMessage?: string;
  additionalInfo?: string;
}

export default function SupportButton({defaultSubject, defaultMessage, additionalInfo, children, ...buttonProps}: SupportButtonProps) {
  const [showModal, setShowModal] = useState(false);
  const timerRef = useRef<number | null>(null);

  useEffect(() => {
    return () => {
      timerRef.current && window.clearTimeout(timerRef.current);
    }
  }, [])

  const onHide = () => {
    setShowModal(false);
  }

  const onDone = () => {
    timerRef.current = window.setTimeout(() => {
      setShowModal(false);
    }, 1000);
  }

  return (
    <>
      <Button {...buttonProps} onClick={() => setShowModal(true)}>
        {children}
      </Button>
      <SupportModal showModal={showModal} onDone={onDone} onHide={onHide} defaultSubject={defaultSubject} defaultMessage={defaultMessage} additionalInfo={additionalInfo} />
    </>
  )
}
