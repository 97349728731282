import React, {useContext} from "react";
import Flex from "../components/falcon/common/Flex";
import {ReactQueryError, ReactQuerySpinner} from "../components/common/ReactQueryLoader";
import AccountContext from "../components/context/AccountContext";
import {useListSubscriptions} from "../api/Subscription";
import useLogout from "../components/hooks/useLogout";
import RootInformationCard from "../components/common/RootInformationCard";
import {Alert, Button} from "react-bootstrap";
import SubscriptionContext from "../components/context/SubscriptionContext";

function NoActiveSubscriptionInterceptPage() {
  const {account} = useContext(AccountContext);
  const logout = useLogout();

  return (
    <RootInformationCard title="Secuma">
      <Alert variant="warning">The account <span className="fw-bold">{account.name !== "" ? account.name : account.id}</span> has no active subscription. <a href="mailto:info@secuma.be">Contact sales</a> to resolve the issue.</Alert>
      <Button className="my-3 w-100" onClick={logout}>Logout</Button>
    </RootInformationCard>
  )
}


interface SubscriptionInterceptPageProps {
  children?: React.ReactNode;
}

export default function SubscriptionInterceptPage({children}: SubscriptionInterceptPageProps) {
  const {account} = useContext(AccountContext);
  const subscriptionsQuery = useListSubscriptions(undefined, {active: "1", account_id: account.id});

  if (subscriptionsQuery.isIdle || subscriptionsQuery.isLoading) {
    return <Flex justifyContent="center" className="mt-4"><ReactQuerySpinner/></Flex>
  }
  else if (subscriptionsQuery.isError) {
    return <Flex justifyContent="center" className="mt-4"><ReactQueryError /></Flex>
  }
  else if (subscriptionsQuery.data) {
    if (subscriptionsQuery.data.results.length === 0) {
      // Return a page telling the user the account doesn't have an active subscription.
      return <NoActiveSubscriptionInterceptPage/>
    }
    return <SubscriptionContext.Provider value={{subscription: subscriptionsQuery.data.results[0]}}>{children}</SubscriptionContext.Provider>;
  }
  else {
    return <Flex justifyContent="center" className="mt-4"><ReactQuerySpinner/></Flex>
  }
}
