import {Col, Row} from "react-bootstrap";
import GovernanceDocumentation from "../../components/documentation/governance/GovernanceDocumentation";
import SAMMAssessmentsCard from "../../components/widgets/SAMMAssessmentsCard";

export default function GovernancePage() {
  return (
    <main>
      <Row>
        <Col>
          <h2 className="mt-2 mb-4">Governance</h2>
        </Col>
      </Row>
      <Row className="g-3">
        <Col>
          {/*<Row className="g-3 mb-3">*/}
          {/*  <Col lg={4} md={6}>*/}
          {/*    <SAMMMaturityWidget/>*/}
          {/*  </Col>*/}
          {/*  <Col lg={4} md={6}>*/}
          {/*    <SAMMLatestAnalysis/>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          <Row>
            <Col>
              <SAMMAssessmentsCard />
            </Col>
          </Row>
        </Col>
        <Col xxl={4}>
          <GovernanceDocumentation/>
        </Col>
      </Row>
    </main>
  )
}
