import {
  Invitation,
  useAcceptInvitationCreateUserOrganizationAndAccount,
  useRejectInvitation
} from "../../api/Invitations";
import {SubmitHandler, useForm} from "react-hook-form";
import {Alert, Button, Form} from "react-bootstrap";
import CreateUserForm from "./CreateUserForm";
import CreateOrganizationForm from "./CreateOrganizationForm";
import AdditionalInformationForm from "./AdditionalInformationForm";
import {Link, useParams} from "react-router-dom";
import {useState} from "react";
import {AcceptState} from "./AcceptInvitationComponent";
import {ReactQuerySpinner} from "../common/ReactQueryLoader";
import {buildRoute} from "../../routes/utils";
import {RootRoutes} from "../../routes/RootRoutes";
import {MainRoutes} from "../../routes/MainRoutes";
import {InvitationRoutes} from "../../routes/InvitationRoutes";

interface CreateUserAndAccountComponentProps {
  invitation: Invitation;
  code: string;
}

export default function CreateUserAndAccountComponent({invitation, code}: CreateUserAndAccountComponentProps) {
  const form = useForm();
  const acceptInvitation = useAcceptInvitationCreateUserOrganizationAndAccount({id: invitation.id});
  const rejectInvitation = useRejectInvitation({id: invitation.id});
  const [acceptState, setAcceptState] = useState<AcceptState>(AcceptState.NEW);

  const onAccept: SubmitHandler<any> = (data) => {
    acceptInvitation.mutate({
      code: code,
      organization_name: data.organizationName,
      organization_email: data.organizationEmail,
      organization_address_line_1: data.organizationAddress1,
      organization_address_line_2: data.organizationAddress2,
      organization_address_postal_code: data.organizationPostalCode,
      organization_address_city: data.organizationCity,
      organization_address_country: data.organizationCountry.value,
      organization_tax_number: data.organizationTaxNumber,
      account_name: data.accountName,
      user_first_name: data.userFirstName,
      user_last_name: data.userLastName,
      user_email: data.userEmail,
      user_password: data.userPassword,
      user_accepted_terms_and_conditions: "2022_10_01",
    })
    setAcceptState(AcceptState.ACCEPTED);
  }

  function onDecline() {
    rejectInvitation.mutate({
      code: code,
    })
    setAcceptState(AcceptState.DECLINED);
  }

  function retry() {
    rejectInvitation.reset();
    acceptInvitation.reset();
    setAcceptState(AcceptState.NEW);
  }

  if (acceptInvitation.isLoading || rejectInvitation.isLoading) {
    return <div className="d-flex flex-column justify-content-center"><ReactQuerySpinner /></div>
  }
  if (acceptInvitation.isError || rejectInvitation.isError) {
    return (
      <>
        <Alert variant="danger">Could not {acceptState === AcceptState.ACCEPTED ? "accept" : "reject"} the invitation. An unexpected error occurred.</Alert>
        {
          code
            ? <Button className="w-100" onClick={retry}>Retry</Button>
            : <Link to={buildRoute(RootRoutes.INVITATION)} className="btn btn-outline-primary w-100">Enter new code</Link>
        }
      </>
    )
  }
  if (acceptInvitation.isSuccess || rejectInvitation.isSuccess) {
    if (acceptState === AcceptState.ACCEPTED) {
      return (
        <>
          <Alert variant="success">Welcome to Secuma! You now have access to your new account.</Alert>
          <Link to={buildRoute(MainRoutes.DASHBOARD)} className="btn btn-primary w-100">Go to dashboard</Link>
        </>
      )
    }
    else if (acceptState === AcceptState.DECLINED) {
      return (
        <>
          <Alert variant="secondary">You declined the invitation. It is no longer active.</Alert>
          <Link to={buildRoute(MainRoutes.DASHBOARD)} className="btn btn-outline-primary w-100">Go to dashboard</Link>
        </>
      )
    }
    else {
      return (
        <>
          <Alert variant="danger">Could not accept the invitation. An unexpected error occurred.</Alert>
          {
            code
              ? <Link to={buildRoute(RootRoutes.INVITATION, InvitationRoutes.INVITATION_CODE).replace(":code", code)} className="btn btn-primary w-100">Retry</Link>
              : <Link to={buildRoute(RootRoutes.INVITATION)} className="btn btn-outline-primary w-100">Enter new code</Link>
          }
        </>
      )
    }
  }

  return (
    <>
      <p>
        Your invitation code is valid. Welcome to Secuma! Create a new account and enjoy our services. Your trial period
        starts immediately.
      </p>
      <hr/>
      <Form onSubmit={form.handleSubmit(onAccept)}>
        <h5 className="mb-3">User details</h5>
        <CreateUserForm form={form}/>
        <h5 className="mb-3 mt-4">Organization details</h5>
        <CreateOrganizationForm form={form}/>
        <h5 className="mb-3 mt-4">Additional details</h5>
        <AdditionalInformationForm form={form}/>
        <Button type="submit" className="w-100 my-3">Create account</Button>
        <button className="w-100 btn btn-outline-secondary" onClick={onDecline}>Decline</button>
      </Form>
    </>
  )
}
