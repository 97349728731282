import {Route, Routes} from "react-router-dom";
import Error404 from "../components/falcon/errors/Error404";
import {SecurityPolicyRoutes} from "./SecurityPolicyRoutes";
import SecurityPolicyPage from "../pages/security-policy/SecurityPolicyPage";
import MultipleActivePoliciesIntercept from "../components/security-policy/MultipleActiveSecurityPoliciesIntercept";
import SecurityPolicyDetailPage from "../pages/security-policy/SecurityPolicyDetailPage";
import NewSecurityPolicyPage from "../pages/security-policy/NewSecurityPolicyPage";

export default function SecurityPolicyRoutesComponent() {
  return (
    <MultipleActivePoliciesIntercept>
      <Routes>
        <Route path={SecurityPolicyRoutes.INDEX} element={<SecurityPolicyPage/>}/>
        <Route path={SecurityPolicyRoutes.NEW} element={<NewSecurityPolicyPage/>}/>
        <Route path={SecurityPolicyRoutes.DETAIL} element={<SecurityPolicyDetailPage/>}/>
        <Route path="/*" element={<Error404 hardLink={false}/>}/>
      </Routes>
    </MultipleActivePoliciesIntercept>
  )
}
