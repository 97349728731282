import {Alert, Button} from "react-bootstrap";
import {useAuth} from "react-oidc-context";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {ReactQuerySpinner} from "../components/common/ReactQueryLoader";


function hasFrom(obj: unknown): obj is {from: unknown} {
  if (!obj) return false;
  return (obj as {from: string}).from !== undefined;
}

export default function LoginPage() {
  const [errorOccurred, setErrorOccurred] = useState<any>(null);
  const [loginStarted, setLoginStarted] = useState(false);
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const userState = auth.user?.state;

  const startAuth = () => {
    const state = hasFrom(location.state) ? {from: location.state.from} : undefined;

    auth.signinRedirect({state: state}).catch((err) => {
      setErrorOccurred(err);
      console.log('Error redirecting to login', err);
    });
    setLoginStarted(true);
  }

  useEffect(() => {
    if (auth.isAuthenticated && location.search.includes("code")) {
      if (userState && hasFrom(userState) && typeof userState.from === "string") {
        navigate(userState.from, {replace: true});
      }
      else {
        navigate("/", {replace: true});
      }
    }
  }, [auth.isAuthenticated, userState, location, navigate])

  return (
    <section className="py-0">
      <div className="container">
        <div className="row flex-center min-vh-100 py-6">
          <div className="col col-xxl-4 col-xl-5 col-lg-6 col-md-8 col-sm-12">
            <a className="text-decoration-none" href="https://secuma.be">
              <div className="d-flex flex-center fw-bolder fs-5 mb-4">
                <span className="font-sans-serif">Secuma</span>
              </div>
            </a>
            <div className="card">
              <div className="card-body p-4 p-sm-5">
                {
                  errorOccurred && (
                    <Alert variant="danger">Login is currently disabled. Please try again later.</Alert>
                  )
                }
                {
                  auth.error && (
                    <Alert variant="danger">Error during login: {auth.error.message}.</Alert>
                  )
                }
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h5>Log in</h5>
                  <p className="fs--1 text-600 mb-0">
                    <a href="https://secuma.be">Return to website</a>
                  </p>
                </div>
                {
                  loginStarted || location.search.includes("code")
                    ? <Button className="my-3 w-100" disabled={true}><ReactQuerySpinner /></Button>
                    : <Button className="my-3 w-100" onClick={startAuth} disabled={loginStarted}>Continue <span className="d-inline-block ms-1"> &rarr;</span></Button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}
