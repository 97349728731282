import {Invitation, useAcceptInvitationCreateUser, useRejectInvitation} from "../../api/Invitations";
import {Account} from "../../api/Account";
import {SubmitHandler, useForm} from "react-hook-form";
import {Alert, Button, Form} from "react-bootstrap";
import InvitationDetails from "./InvitationDetails";
import CreateUserForm from "./CreateUserForm";
import AdditionalInformationForm from "./AdditionalInformationForm";
import {useState} from "react";
import {ReactQuerySpinner} from "../common/ReactQueryLoader";
import {Link} from "react-router-dom";
import {buildRoute} from "../../routes/utils";
import {RootRoutes} from "../../routes/RootRoutes";
import {MainRoutes} from "../../routes/MainRoutes";
import {InvitationRoutes} from "../../routes/InvitationRoutes";
import {AcceptState} from "./AcceptInvitationComponent";

interface CreateUserAndAcceptInvitationComponentProps {
  invitation: Invitation;
  account: Account;
  code: string;
}

export default function CreateUserAndAcceptInvitationComponent({invitation, account, code}: CreateUserAndAcceptInvitationComponentProps) {
  const form = useForm();
  const acceptInvitation = useAcceptInvitationCreateUser({id: invitation.id});
  const rejectInvitation = useRejectInvitation({id: invitation.id});
  const [acceptState, setAcceptState] = useState<AcceptState>(AcceptState.NEW);

  const onAccept: SubmitHandler<any> = (data) => {
    acceptInvitation.mutate({
      code: code,
      user_first_name: data.userFirstName,
      user_last_name: data.userLastName,
      user_email: data.userEmail,
      user_password: data.userPassword,
      user_accepted_terms_and_conditions: "2022_10_01",
    })
    setAcceptState(AcceptState.ACCEPTED);
  }

  function onDecline() {
    rejectInvitation.mutate({
      code: code,
    })
    setAcceptState(AcceptState.DECLINED);
  }

  function retry() {
    rejectInvitation.reset();
    acceptInvitation.reset();
    setAcceptState(AcceptState.NEW);
  }

  if (acceptInvitation.isLoading || rejectInvitation.isLoading) {
    return <div className="d-flex flex-column justify-content-center"><ReactQuerySpinner /></div>
  }
  if (acceptInvitation.isError || rejectInvitation.isError) {
    return (
      <>
        <Alert variant="danger">Could not {acceptState === AcceptState.ACCEPTED ? "accept" : "reject"} the invitation. An unexpected error occurred.</Alert>
        {
          code
            ? <Button className="w-100" onClick={retry}>Retry</Button>
            : <Link to={buildRoute(RootRoutes.INVITATION)} className="btn btn-outline-primary w-100">Enter new code</Link>
        }
      </>
    )
  }
  if (acceptInvitation.isSuccess || rejectInvitation.isSuccess) {
    if (acceptState === AcceptState.ACCEPTED) {
      return (
        <>
          <Alert variant="success">Welcome to Secuma! You now have access to the <span className="fw-bold">{account.name}</span> account.</Alert>
          <Link to={buildRoute(MainRoutes.DASHBOARD)} className="btn btn-primary w-100">Go to dashboard</Link>
        </>
      )
    }
    else if (acceptState === AcceptState.DECLINED) {
      return (
        <>
          <Alert variant="secondary">You declined the invitation. It is no longer active.</Alert>
          <Link to={buildRoute(MainRoutes.DASHBOARD)} className="btn btn-outline-primary w-100">Go to dashboard</Link>
        </>
      )
    }
    else {
      return (
        <>
          <Alert variant="danger">An unexpected error occurred.</Alert>
          {
            code
              ? <Link to={buildRoute(RootRoutes.INVITATION, InvitationRoutes.INVITATION_CODE).replace(":code", code)} className="btn btn-primary w-100">Retry</Link>
              : <Link to={buildRoute(RootRoutes.INVITATION)} className="btn btn-outline-primary w-100">Enter new code</Link>
          }
        </>
      )
    }
  }

  return (
    <Form onSubmit={form.handleSubmit(onAccept)}>
      <InvitationDetails invitation={invitation} account={account}/>
      <h5 className="mb-3">User details</h5>
      <CreateUserForm form={form}/>
      <AdditionalInformationForm form={form} includeAccountName={false}/>
      <Button type="submit" className="w-100 my-3">Create user and accept invitation</Button>
      <button className="w-100 btn btn-outline-secondary" onClick={onDecline}>Decline</button>
    </Form>
  )
}
