import React from "react";
import {CellProps} from "@jsonforms/core";
import {Form} from "react-bootstrap";
import {withJsonFormsCellProps} from "@jsonforms/react";
import {VanillaRendererProps} from "@jsonforms/vanilla-renderers";
import {withVanillaBooleanCellProps} from "./utils";

const BooleanCell = (props: CellProps & VanillaRendererProps) => {
    const { data, className, id, enabled, uischema, path, handleChange } = props;

    return (
      <Form.Check
        type='checkbox'
        checked={!!data}
        onChange={ev =>
          handleChange(path, ev.target.checked)
        }
        className={className}
        id={id}
        disabled={!enabled}
        autoFocus={uischema.options && uischema.options.focus}
      />
    );
  };

export default withJsonFormsCellProps(withVanillaBooleanCellProps(BooleanCell));
