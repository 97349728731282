import { merge } from "lodash";
import {Page, ResourceId} from "./baseApi";


export interface FetchOptions {
  accessToken?: string | null;
}

export class ApiError extends Error {
  status?: number;
  json?: unknown;

  constructor(message?: string, status?: number, json?: string) {
    super(message);

    Object.setPrototypeOf(this, ApiError.prototype);
    this.status = status;
    this.json = json;
  }
}

export interface ApiNonFieldErrors {
  non_field_errors: string[];
}

export function isApiError(error: unknown): error is ApiError {
  return (
    error !== undefined
    && error !== null
    && (error as ApiError).json !== undefined
  )
}

export function isApiErrorNonFieldErrors(json: unknown): json is ApiNonFieldErrors {
  return (
    json !== undefined
    && json !== null
    && (json as ApiNonFieldErrors).non_field_errors !== undefined
  )
}

export function api(endpoint: string): string {
  return endpoint.startsWith('http') ? endpoint : process.env.REACT_APP_API_URL! + endpoint;
}

export function fetchApi(endpoint: string, init?: RequestInit, options?: FetchOptions): Promise<Response> {
  if (options && options.accessToken) {
    init = merge(init, {
      headers: {
        Authorization: 'bearer ' + options.accessToken,
      }
    });
  }
  if (!endpoint.startsWith('http')) {
    endpoint = api(endpoint);
  }
  return window.fetch(endpoint, init);
}


export function postApi(endpoint: string, data?: any, options?: FetchOptions): Promise<Response> {
  return fetchApi(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data ? JSON.stringify(data) : '',
  }, options);
}

export function putApi(endpoint: string, data?: any, options?: FetchOptions): Promise<Response> {
  return fetchApi(endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data ? JSON.stringify(data) : '',
  }, options);
}

export function deleteApi(endpoint: string, data?: any, options?: FetchOptions): Promise<Response> {
  return fetchApi(endpoint, {
    method: 'DELETE',
  }, options);
}

/**
 * Flatten the results and filter possible duplicates from the page results.
 */
export function pagesResultToArray<T extends ResourceId>(pages: Page<T>[]): T[] {
  const result: T[] = [];
  const idSet: Set<string | number> = new Set();
  const urlSet: Set<string> = new Set();
  for (const page of pages) {
    for (const item of page.results) {
      let includeItem = true;
      if (item.id) {
        if (idSet.has(item.id)) {
          includeItem = false;
        }
        idSet.add(item.id);
      }
      if (item.url) {
        if(urlSet.has(item.url)) {
          includeItem = false;
        }
        urlSet.add(item.url);
      }
      if (includeItem) {
        result.push(item);
      }
    }
  }
  return result;
}
