import {Dropdown} from "react-bootstrap";
import Avatar from "../../common/Avatar";
import {useRetrieveUser} from "../../../../api/User";
import {faCircleUser} from "@fortawesome/free-solid-svg-icons";
import useLogout from "../../../hooks/useLogout";

export default function ProfileDropdown() {
  const logout = useLogout();
  const user = useRetrieveUser({id: "me"}); // Special id "me"

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={"button"}
        // to="#!"
        className="p-0 ms-2 btn-link btn"
      >
        <Avatar src={user.data ? user.data.avatar_url : ""} icon={faCircleUser} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2">
          {/*<Dropdown.Item as={Link} to="/user/profile/">Settings</Dropdown.Item>*/}
          {/*<Dropdown.Divider />*/}
          <button className="btn-link dropdown-item" onClick={logout}>Logout</button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
