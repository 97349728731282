import React, {HTMLAttributes} from "react";
import classNames from "classnames";

interface FlexProps extends HTMLAttributes<any> {
  children: React.ReactNode;
  justifyContent?: string;
  inline?: boolean;
  alignItems?: "start" | "end" | "center" | "baseline" | "stretch";
  alignContent?: string;
  wrap?: string;
  className?: string;
  tag?: keyof JSX.IntrinsicElements;
  breakpoint?: string;
  direction?: string;
}

export default function Flex({
                justifyContent,
                alignItems,
                alignContent,
                inline,
                wrap,
                className,
                tag: Tag = 'div',
                children,
                breakpoint,
                direction,
                ...rest
              }: FlexProps) {
  return (
    <Tag
      className={classNames(
        {
          [`d-${breakpoint ? breakpoint + '-' : ''}flex`]: !inline,
          [`d-${breakpoint ? breakpoint + '-' : ''}inline-flex`]: inline,
          [`flex-${direction}`]: direction,
          [`justify-content-${justifyContent}`]: justifyContent,
          [`align-items-${alignItems}`]: alignItems,
          [`align-content-${alignContent}`]: alignContent,
          [`flex-${wrap}`]: wrap
        },
        className
      )}
      {...rest}
    >
      {children}
    </Tag>
  );
};
