import FalconCardHeader from "../falcon/common/FalconCardHeader";
import {Alert, Button, Card, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faCheck, faPen} from "@fortawesome/free-solid-svg-icons";
import AccountContext from "../context/AccountContext";
import React, {useContext, useState} from "react";
import {Account, useUpdateAccount} from "../../api/Account";
import {UseFormReturn} from "react-hook-form/dist/types";
import {SubmitHandler, useForm} from "react-hook-form";
import {ReactQuerySpinner} from "../common/ReactQueryLoader";

interface EditButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

function EditButton({onClick}: EditButtonProps) {
  return (
    <button className="btn btn-link" onClick={onClick}><FontAwesomeIcon icon={faPen} /></button>
  )
}

interface AccountSettingsWidgetProps {
  editable?: boolean;
}


function AccountInformation({account}: {account: Account}) {
  return (
    <>
      <p className="fw-bold mb-1">Id</p>
      <p>{account.id}</p>
      <p className="fw-bold mb-1">Name</p>
      <p>{account.name !== "" ? account.name : "-"}</p>
    </>
  )
}

interface AccountEditFormInput {
  name: string;
}

interface AccountInformationEditProps {
  account: Account;
  form: UseFormReturn<AccountEditFormInput>;
  disabled?: boolean;
}

function AccountInformationEdit({account, form, disabled = false}: AccountInformationEditProps) {
  const {
    register,
    formState: {errors},
  } = form;

  return (
    <>
      <p className="fw-bold mb-1">Id</p>
      <p>{account.id}</p>
      <Form.Group className="mb-4 d-flex flex-column">
        <p className="fw-bold mb-1">Name</p>
        <Form.Control
          type="input"
          disabled={disabled}
          {...register('name', {required: 'A name is required'})}
          isInvalid={errors['name'] !== undefined}
          isValid={Object.keys(errors).length > 0 && errors['name'] === undefined}
        />
        <Form.Control.Feedback type="invalid">
          {errors['name']?.message}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  )
}

export default function AccountSettingsWidget({editable = false}: AccountSettingsWidgetProps) {
  const {account} = useContext(AccountContext);
  const [edit, setEdit] = useState(false);
  const form = useForm<AccountEditFormInput>({defaultValues: {name: account.name}});
  const accountMutation = useUpdateAccount();

  const onSubmit: SubmitHandler<AccountEditFormInput> = (data) => {
    accountMutation.mutate(
      {...account, name: data.name},
      {
        onSuccess: () => {
          setEdit(false);
          accountMutation.reset();
        }
      }
    )
  }

  let itemEl: undefined | React.ReactNode = undefined;
  if (editable) {
    if (edit) {
      if (accountMutation.isIdle) {
        itemEl = (
          <>
            <Button variant="outline-secondary me-2" onClick={() => {setEdit(false); form.reset();}}><FontAwesomeIcon icon={faBan} /></Button>
            <Button type="submit" form="accountEditForm">Save</Button>
          </>
        )
      }
      else if (accountMutation.isLoading || accountMutation.isPaused) {
        itemEl =  <Button type="submit" disabled={true} form="accountEditForm"><ReactQuerySpinner size="sm" /></Button>
      }
      else if (accountMutation.isError) {
        itemEl = <Button onClick={() => {accountMutation.reset(); setEdit(false); form.reset();}}>Cancel</Button>;
      }
      else if (accountMutation.isSuccess) {
        itemEl = <Button variant="success" disabled={true}><FontAwesomeIcon icon={faCheck} /></Button>;
      }
    }
    else {
      itemEl = <EditButton onClick={() => {setEdit(!edit)}}/>
    }
  }

  return (
    <Card>
      <FalconCardHeader title="Account" light={true} endEl={itemEl} titleTag="h5" titleClass="fw-bold" />
      <Card.Body>
        {
          edit
            ? (
              <>
                {
                  accountMutation.isError && (
                    <Alert variant="danger">Could not update account name</Alert>
                  )
                }
                <Form id="accountEditForm" onSubmit={form.handleSubmit(onSubmit)}>
                  <AccountInformationEdit account={account} form={form} disabled={!accountMutation.isIdle} />
                </Form>
              </>
            )
            : <AccountInformation account={account}/>
        }
      </Card.Body>
    </Card>
  )
}
