import {DateTime, Duration, DurationLikeObject, DurationUnit, Interval} from "luxon";

export function sortKey<T>(key: (item: T) => any): (a: T, b: T) => number {
  return ((a: T, b: T) => {
    return key(a) - key(b);
  })
}

export function reduceSum(a: number, b: number): number {
  return a + b;
}

export const SHOW_VALID_STYLE = false;

export function timeUntil(date: DateTime, now?: DateTime, depth: number = 2): Duration {
  if (depth < 1 || depth > 5) {
    throw new Error("Depth should be between 1 and 5");
  }
  if (now === undefined) {
    now = DateTime.now();
  }

  const interval = Interval.fromDateTimes(now, date);
  let duration = interval.toDuration();
  const units: DurationUnit[] = ["years", "months", "days", "hours", "minutes"];
  const durationResultObj: DurationLikeObject = {};
  let currentDepth = 0;
  for (const unit of units) {
    const unitLength = duration.as(unit);
    if (unitLength >= 1) {
      durationResultObj[unit] = Math.floor(unitLength);
      duration = interval.toDuration().minus(durationResultObj);
      currentDepth += 1;
      if (currentDepth >= depth) break;
    }
  }
  if (currentDepth < depth && durationResultObj.minutes === undefined) {
    durationResultObj.minutes = 0;
  }

  return Duration.fromDurationLike(durationResultObj);
}

export function timeSince(date: DateTime, now?: DateTime, depth: number = 2): Duration {
  if (now === undefined) {
    now = DateTime.now();
  }
  return timeUntil(now, date, depth);  // TimeSince is the same as timeUntil with reversed timestamps.
}

export function dateTimeCmp(dt1: DateTime, dt2: DateTime) {
  if (dt1 < dt2) {
    return -1;
  }
  else if (dt1 > dt2) {
    return 1;
  }
  return 0;
}
