import {Alert, Card, Col, Row} from "react-bootstrap";
import React, {useContext, useState} from "react";
import FalconCardHeader from "../../components/falcon/common/FalconCardHeader";
import SecurityPolicyDocumentation from "../../components/security-policy/SecurityPolicyDocumentation";
import SecurityPoliciesListCard from "../../components/security-policy/SecurityPoliciesListCard";
import NewSecurityPolicyForm, {
  CreateSecurityPolicyFormInputs
} from "../../components/security-policy/NewSecurityPolicyForm";
import {useCreateSecurityPolicy} from "../../api/SecurityPolicy";
import AccountContext from "../../components/context/AccountContext";
import {useNavigate} from "react-router-dom";
import {buildRoute} from "../../routes/utils";
import {MainRoutes} from "../../routes/MainRoutes";
import {GovernanceRoutes} from "../../routes/GovernanceRoutes";
import {SecurityPolicyRoutes} from "../../routes/SecurityPolicyRoutes";

export default function NewSecurityPolicyPage() {
  const createSecurityPolicyMutation = useCreateSecurityPolicy();
  const {account} = useContext(AccountContext);
  const navigate = useNavigate();


  const onSubmit = (data: CreateSecurityPolicyFormInputs) => {
    createSecurityPolicyMutation.mutate({
      account_id: account.id,
      name: data.name,
      description: data.description,
    }, {
      onSuccess: (data) => {
        navigate(buildRoute(MainRoutes.GOVERNANCE, GovernanceRoutes.SECURITY_POLICY, SecurityPolicyRoutes.DETAIL).replace(":securityPolicyId", data.id));
      }
    })
  }

  return (
    <main>
      <Row>
        <Col>
          <h2 className="mt-2 mb-4 d-flex flex-row align-items-center">
            New Security Policy
          </h2>
        </Col>
      </Row>
      <Row className="g-3">
        <Col>
          <Row>
            <Col>
              <Card className="mb-4">
                <FalconCardHeader light titleTag="h5" title="Security Policy Details"></FalconCardHeader>
                <Card.Body>
                  {
                    createSecurityPolicyMutation.isError && (
                      <Alert variant="danger" dismissible={true} onClose={() => {createSecurityPolicyMutation.reset()}}>Could not create Security Policy. Unexpected error.</Alert>
                    )
                  }
                  <NewSecurityPolicyForm handleValidSubmit={onSubmit} isDisabled={!createSecurityPolicyMutation.isIdle} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xxl={4}>
          <Row>
            <Col>
              <SecurityPoliciesListCard />
            </Col>
          </Row>
          {/*<Row>*/}
          {/*  <Col>*/}
          {/*    <SecurityPolicyDocumentation/>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
        </Col>
      </Row>
    </main>
  )
}
