import RootInformationCard from "../../components/common/RootInformationCard";
import {Link, useNavigate} from "react-router-dom";
import {RootRoutes} from "../../routes/RootRoutes";
import {Button, Form} from "react-bootstrap";
import {SubmitHandler, useForm} from "react-hook-form";
import {buildRoute} from "../../routes/utils";
import {InvitationRoutes} from "../../routes/InvitationRoutes";
import InvitationHeader from "../../components/invitation/InvitationHeader";


interface InvitationCodeFormInput {
  invitationCode: string;
}


export default function InvitationIndexPage() {
  const {
    register,
    formState: {errors},
    handleSubmit,
  } = useForm<InvitationCodeFormInput>();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<InvitationCodeFormInput> = (data) => {
    navigate(buildRoute(RootRoutes.INVITATION, InvitationRoutes.INVITATION_CODE).replace(":code", data.invitationCode));
  }

  return (
    <RootInformationCard title="Secuma">
      <InvitationHeader />
      <div>
        <p>
          Secuma is invitation only. Enter your invitation code or request one from sales.
        </p>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-4 d-flex flex-column">
          <Form.Label className="mb-0 fw-bold">Invitation code</Form.Label>
          <Form.Control
            type="input"
            {
              ...register(
                'invitationCode',
                {
                  required: 'An invitation code is required',
                  pattern: {
                    value: /^[A-Za-z0-9]+$/i,
                    message: "An invitation code contains characters a-z and/or numbers",
                  }
                }
              )
            }
            isInvalid={errors['invitationCode'] !== undefined}
            isValid={Object.keys(errors).length > 0 && errors['invitationCode'] === undefined}
          />
          <Form.Control.Feedback type="invalid">
            {errors['invitationCode']?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Button className="w-100" type="submit">Continue</Button>
      </Form>
    </RootInformationCard>
  )
}
