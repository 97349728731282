import {Col, Row} from "react-bootstrap";
import TrialInformation from "../components/widgets/TrialInformation";
import WelcomeWidget from "../components/widgets/WelcomeWidget";
import NeedSupportWidget from "../components/widgets/NeedSupportWidget";
import {SubscriptionPlan, useListSubscriptions} from "../api/Subscription";
import AccountContext from "../components/context/AccountContext";
import {useContext} from "react";
import SubscriptionInformation from "../components/widgets/SubscriptionInformation";
import NotificationsRow from "../components/notifications/NotificationsRow";


export default function DashboardWelcomePage() {
  const {account} = useContext(AccountContext);
  const subscriptionsQuery = useListSubscriptions(undefined, {active: "1", account_id: account.id});

  return (
    <main>
      <Row>
        <Col>
          <h2 className="mt-2 mb-4">Dashboard</h2>
        </Col>
      </Row>
      {/*<NotificationsRow />*/}
      <Row className="g-3">
        <Col>
          <Row>
            <Col>
              <WelcomeWidget />
            </Col>
          </Row>
        </Col>
        <Col xxl={3} lg={4}>
          {
            subscriptionsQuery.data && subscriptionsQuery.data.results.length > 0 && subscriptionsQuery.data.results[0].plan === SubscriptionPlan.TRIAL && (
              <Row className="mb-4">
              <Col>
                <TrialInformation subscription={subscriptionsQuery.data.results[0]}/>
              </Col>
              </Row>
            )
          }
          <Row className="mb-4">
            <Col>
              <NeedSupportWidget />
            </Col>
          </Row>
          {
            subscriptionsQuery.data && subscriptionsQuery.data.results.length > 0 && subscriptionsQuery.data.results[0].plan !== SubscriptionPlan.TRIAL && (
              <Row className="mb-4">
                <Col>
                  <SubscriptionInformation subscription={subscriptionsQuery.data.results[0]}/>
                </Col>
              </Row>
            )
          }        </Col>
      </Row>
    </main>
  )
}
