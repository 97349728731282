import {Account, useListAccounts} from "../api/Account";
import React, {ChangeEvent, useEffect, useState} from "react";
import {ReactQueryError, ReactQuerySpinner} from "../components/common/ReactQueryLoader";
import Flex from "../components/falcon/common/Flex";
import RootInformationCard from "../components/common/RootInformationCard";
import {Alert, Button, Form} from "react-bootstrap";
import useLogout from "../components/hooks/useLogout";
import AccountContext from "../components/context/AccountContext";


interface AccountsInterceptPageProps {
  children?: React.ReactNode;
}

function NoAccountsInterceptPage() {
  const logout = useLogout();

  return (
    <RootInformationCard title="Secuma">
      <Alert variant="danger">You are not a member of an account. You can ask an account administrator for an invitation or <a href="mailto:info@secuma.be">contact sales</a> to create a new account.</Alert>
      <Button className="my-3 w-100" onClick={logout}>Logout</Button>
    </RootInformationCard>
  )
}

interface AccountsSelectionPageProps {
  accounts: Account[];
  selectAccount: (account: Account) => void;
}

function AccountsSelectionPage({accounts, selectAccount}: AccountsSelectionPageProps) {
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);

  function onChange(event: ChangeEvent<HTMLSelectElement>) {
    const accountId = event.target.value;
    const account = accounts.find(account => account.id === accountId);
    setSelectedAccount(account ? account : null);
  }

  return (
    <RootInformationCard title="Secuma">
      <p>You are a member of multiple accounts. Please select the account you wish to work on.</p>
      <Form.Select aria-label="" value={selectedAccount ? selectedAccount.id : ""} onChange={onChange}>
        <option value=""></option>
        {
          accounts.map((account) => {
            return (
              <option key={account.id} value={account.id}>{account.name !== "" ? account.name : account.id}</option>
            )
          })
        }
      </Form.Select>
      <Button className="w-100 my-3" disabled={selectedAccount === null} onClick={() => {selectedAccount && selectAccount(selectedAccount)}}>Continue &rarr;</Button>
    </RootInformationCard>
  )
}

export default function AccountsInterceptPage({children}: AccountsInterceptPageProps) {
  const [account, setAccount] = useState<Account | null>(null);
  const accountsQuery = useListAccounts();

  useEffect(() => {
    if (accountsQuery.data && accountsQuery.data.results.length === 1) {
      setAccount(accountsQuery.data.results[0]);
    }
    if (account !== null) {
      if (!accountsQuery.data || !accountsQuery.data.results.find(acc => acc.id === account.id)) {
        setAccount(null);
      }
      else if (accountsQuery.data) {
        // This is an update of the account data. Check if the current account is still present and update if necessary.
        const newAccount = accountsQuery.data.results.find(acc => acc.id === account.id);
        if (newAccount) {
          setAccount(newAccount);
        }
      }
    }
  }, [account, accountsQuery.data]);

  if (accountsQuery.isIdle || accountsQuery.isLoading) {
    return <Flex justifyContent="center" className="mt-4"><ReactQuerySpinner/></Flex>
  }
  else if (accountsQuery.isError) {
    return <Flex justifyContent="center" className="mt-4"><ReactQueryError /></Flex>
  }
  else if (accountsQuery.data) {
    if (accountsQuery.data.results.length === 0) {
      // Return a page telling the user they don't have the required permissions.
      return <NoAccountsInterceptPage/>
    }
    else if (account === null) {
      if (accountsQuery.data.results.length > 1) {
        return <AccountsSelectionPage accounts={accountsQuery.data.results} selectAccount={(account) => {setAccount(account)}}/>
      }
      else {
        // Just wait a while. The default account will be chosen automatically.
        return <Flex justifyContent="center" className="mt-4"><ReactQuerySpinner/></Flex>
      }
    }
    return <AccountContext.Provider value={{account: account, setAccount: setAccount}}>{children}</AccountContext.Provider>;
  }
  else {
    return <Flex justifyContent="center" className="mt-4"><ReactQuerySpinner/></Flex>
  }
}
