import React from "react";
import {Invitation} from "../../api/Invitations";
import {Account} from "../../api/Account";

export default function InvitationDetails({invitation, account}: { invitation: Invitation, account: Account }) {
  return (
    <>
      <p>
        You are invited to join the account <span
        className="fw-bold">{account.name !== "" ? account.name : `unknown (${account.id})`}</span>.
      </p>
      {
        invitation.roles.length > 0 && (
          <p>
            You will have the roles {
            invitation.roles.map((role, index) => {
              return <React.Fragment key={role.id}>{index !== 0 && ", "}<span key={role.id} className="fw-bold">{role.name}</span></React.Fragment>
            })
          }
          </p>
        )
      }
    </>
  )
}
