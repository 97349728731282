import {Invitation, useLookupInvitation} from "../../api/Invitations";
import {ReactQuerySpinner} from "../common/ReactQueryLoader";
import {Alert, Button} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import {buildRoute} from "../../routes/utils";
import {RootRoutes} from "../../routes/RootRoutes";
import {InvitationRoutes} from "../../routes/InvitationRoutes";
import LoginRequiredCheckComponent from "./LoginRequiredCheckComponent";
import {useEffect, useState} from "react";

export function InvitationCodeQueryResultComponent() {
  const params = useParams();
  const invitationCode = params.code;
  const [invitation, setInvitation] = useState<Invitation | null>(null);
  const lookupMutation = useLookupInvitation({
    onSuccess: result => {
      if (invitation === null) {
        setInvitation(result);
      }
    }
  });

  useEffect(() => {
    if (invitationCode && invitation === null) {
      lookupMutation.mutate({
        code: invitationCode,
      })
    }
  }, [])


  if (!invitationCode || lookupMutation.isLoading) {
    return <div className="d-flex justify-content-center"><ReactQuerySpinner/></div>;
  }
  if (lookupMutation.error) {
    if (lookupMutation.error.status === 404) {
      return (
        <>
          <Alert variant="warning">This code is invalid</Alert>
          <Link to={buildRoute(RootRoutes.INVITATION, InvitationRoutes.INDEX)} className="btn btn-primary w-100">Enter
            other code</Link>
        </>
      )
    }
    return (
      <>
        <Alert variant="danger mb-4">Error checking code</Alert>
        <Button className="w-100 mb-2" variant="primary" onClick={() => {
          lookupMutation.mutate({code: invitationCode!})
        }}>Try again</Button>
        <Link to={buildRoute(RootRoutes.INVITATION, InvitationRoutes.INDEX)} className="btn btn-outline-primary w-100">Enter
          new code</Link>
      </>
    )
  }
  if (!lookupMutation.isSuccess) {
    return (
      <>
        <Alert variant="danger mb-4">Unexpected problem. Please wait...</Alert>
        <Link to={buildRoute(RootRoutes.INVITATION, InvitationRoutes.INDEX)} className="btn btn-outline-primary w-100">or
          enter other code</Link>
      </>
    )
  }
  if (!invitation) {
    return <div className="d-flex justify-content-center"><ReactQuerySpinner/></div>;
  }
  if (!invitation.is_active) {
    return (
      <>
        <Alert variant="warning">This code is not active</Alert>
        <Link to={buildRoute(RootRoutes.INVITATION, InvitationRoutes.INDEX)} className="btn btn-primary w-100">Enter
          other code</Link>
      </>
    )
  }
  return <LoginRequiredCheckComponent invitation={invitation} code={invitationCode}/>
}
