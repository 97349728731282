import {Card} from "react-bootstrap";
import FalconCardHeader from "../falcon/common/FalconCardHeader";
import SupportButton from "../common/SupportButton";


export default function NeedSupportWidget() {
  return (
    <Card>
      <FalconCardHeader title="Need some help?" titleTag="h4" light />
      <Card.Body>
        <p>
          Our tools are created with developers in mind. We are strong believers of <a href="https://en.wikipedia.org/wiki/DevOps#DevSecOps,_Shifting_Security_Left">DevSecOps</a> and
          <em> Shift Left</em> principles.
        </p>
        <p>
          However, sometimes you may want the added expertise of an external security professional. We can help you!
        </p>
        <SupportButton additionalInfo="NeedSupportWidget - contact us">Contact us</SupportButton>
      </Card.Body>
    </Card>
  )
}
