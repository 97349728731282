import {RankedTester} from "@jsonforms/core";
import {
  booleanCellTester,
  enumCellTester, inputControlTester,
  labelRendererTester, numberCellTester,
  textCellTester, VerticalLayout,
  verticalLayoutTester
} from "@jsonforms/vanilla-renderers";
import EnumCell from "./EnumCell";
import BooleanCell from "./BooleanCell";
import TextCell from "./TextCell";
// import {
//   InputControl,
//   inputControlTester, OneOfRadioGroupControl, oneOfRadioGroupControlTester,
//   RadioGroupControl,
//   radioGroupControlTester
// } from "@jsonforms/vanilla-renderers/src/controls";
// import {
//   ArrayControl,
//   arrayControlTester, Categorization, categorizationTester,
//   labelRendererTester, TableArrayControl, tableArrayControlTester
// } from "@jsonforms/vanilla-renderers/src/complex";
// import {
//   GroupLayout,
//   groupTester, HorizontalLayout,
//   horizontalLayoutTester,
//   VerticalLayout,
//   verticalLayoutTester
// } from "@jsonforms/vanilla-renderers/src/layouts";
import LabelRenderer from "./LabelRenderer";
import InputControl from "./InputControl";
import NumberCell from "./NumberCell";
import NumberCellWithRange from "./NumberCellWithRange";


export const bootstrapRenderers: { tester: RankedTester; renderer: any }[] = [
  { tester: inputControlTester, renderer: InputControl },
  // { tester: radioGroupControlTester, renderer: RadioGroupControl },
  // { tester: oneOfRadioGroupControlTester, renderer: OneOfRadioGroupControl },
  // { tester: arrayControlTester, renderer: ArrayControl },
  { tester: labelRendererTester, renderer: LabelRenderer },
  // { tester: categorizationTester, renderer: Categorization },
  // { tester: tableArrayControlTester, renderer: TableArrayControl },
  // { tester: groupTester, renderer: GroupLayout },
  { tester: verticalLayoutTester, renderer: VerticalLayout },
  // { tester: horizontalLayoutTester, renderer: HorizontalLayout }
];


export const bootstrapCells: { tester: RankedTester; cell: any }[] = [
  { tester: booleanCellTester, cell: BooleanCell },
  // { tester: dateCellTester, cell: DateCell },
  // { tester: dateTimeCellTester, cell: DateTimeCell },
  { tester: enumCellTester, cell: EnumCell },
  // { tester: integerCellTester, cell: IntegerCell },
  { tester: numberCellTester, cell: NumberCellWithRange },
  // { tester: sliderCellTester, cell: SliderCell },
  // { tester: textAreaCellTester, cell: TextAreaCell },
  { tester: textCellTester, cell: TextCell },
  // { tester: timeCellTester, cell: TimeCell }
];
