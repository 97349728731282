import {Alert, Button, Form, Modal} from "react-bootstrap";
import FalconCloseButton from "../falcon/common/FalconCloseButton";
import {Role, useListRoles} from "../../api/Role";
import {SubmitHandler, useForm} from "react-hook-form";
import {ReactQuerySpinner} from "../common/ReactQueryLoader";
import {SHOW_VALID_STYLE} from "../../helpers/utils";
import {useCreateInvitation} from "../../api/Invitations";
import {useContext} from "react";
import AccountContext from "../context/AccountContext";

interface InviteMemberFormResult {
  email: string;
  role: Role;
}

interface AddMemberFormInput {
  email: string;
  role: string;
}

interface AddMemberModalProps {
  show: boolean;
  onHide?: () => void;
}

interface InviteMemberContentProps {
  roles: Role[];
  onInvite: (data: InviteMemberFormResult) => void;
}

function InviteMemberContent({roles, onInvite}: InviteMemberContentProps) {
  const {
    handleSubmit,
    register,
    formState: {
      errors,
    }
  } = useForm<AddMemberFormInput>();

  const onSubmit: SubmitHandler<AddMemberFormInput> = (data) => {
    const role = roles.find(role => role.id === data.role);
    if (role) {
      onInvite({
        email: data.email,
        role: role,
      })
    }
    else {
      console.error("Invitation did not contain valid role", roles, data.role);
    }
  }

  return (
    <>
      <h4 className="mb-4">Invite member</h4>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-4 d-flex flex-column">
          <Form.Label className="mb-0 fw-bold">Email</Form.Label>
          <Form.Text className="text-700">
            The email address of the new member. An invitation will be sent to this address.
          </Form.Text>
          <Form.Control
            type="email"
            {...register('email', {required: 'An email address is required'})}
            isInvalid={errors['email'] !== undefined}
            isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors['email'] === undefined}
          />
          <Form.Control.Feedback type="invalid">
            {errors['email']?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-4 d-flex flex-column">
          <Form.Label className="mb-0 fw-bold">Role</Form.Label>
          <Form.Text className="text-700">
            Choose which role the member gets upon joining.
          </Form.Text>
          <Form.Select
            aria-label="select"
            {...register('role', {required: 'Select a role'})}
            isInvalid={errors['role'] !== undefined}
            isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors['role'] === undefined}
          >
            <option></option>
            {
              roles.map(role => {
                return (
                  <option key={role.id} value={role.id}>{role.name}</option>
                )
              })
            }
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors['role']?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Button type="submit" className="w-100">Send invitation</Button>
      </Form>
    </>
  )
}

export default function InviteMemberModal({show, onHide}: AddMemberModalProps) {
  const {account} = useContext(AccountContext);
  const rolesQuery = useListRoles(undefined, undefined, {enabled: show});
  const createInvitationMutation = useCreateInvitation();

  const onInvite = (data: InviteMemberFormResult) => {
    createInvitationMutation.mutate({
      email: data.email,
      role_ids: [data.role.id],
      account_id: account.id,
    })
  }

  const onHideInternal = () => {
    createInvitationMutation.reset();
    onHide && onHide();
  }

  let content: React.ReactNode;
  if (rolesQuery.isLoading || rolesQuery.isIdle) {
    content = <ReactQuerySpinner/>
  }
  else if (rolesQuery.isError) {
    content = <Alert variant="danger">An error occurred. Try again later or contact your administrator.</Alert>
  }
  else if (rolesQuery.isSuccess) {
    if (createInvitationMutation.isIdle) {
      content = (
        <InviteMemberContent roles={rolesQuery.data.results} onInvite={onInvite}/>
      )
    }
    else if (createInvitationMutation.isError) {
      content = <Alert variant="danger">Could not invite member. Try again later or contact your administrator.</Alert>
    }
    else if (createInvitationMutation.isSuccess) {
      content = (
        <>
          <Alert variant="success">Invitation was sent successfully.</Alert>
          <Button className="w-100" onClick={onHideInternal}>Close</Button>
        </>
      )
    }
    else {
      content = <ReactQuerySpinner />
    }
  }
  else {
    content = <ReactQuerySpinner/>
  }

  return (
    <Modal show={show} size="lg" onHide={onHideInternal}>
      <Modal.Body className="p-4">
        <FalconCloseButton
          size="sm"
          className="position-absolute top-0 end-0 me-2 mt-2"
          onClick={onHideInternal}
        />
        {content}
      </Modal.Body>
    </Modal>
  )
}
