import {SectionRoute} from "../components/falcon/common/Route";
import {
  faBriefcase,
  faChartPie,
  faClipboardList,
  faClipboardQuestion,
  faCog,
  faSignsPost
} from "@fortawesome/free-solid-svg-icons";
import {MainRoutes} from "./MainRoutes";
import {buildRoute} from "./utils";
import {GovernanceRoutes} from "./GovernanceRoutes";
import {SAMMAssessmentRoutes} from "./SAMMAssessmentRoutes";

export const routes: SectionRoute[] = [
  {
    label: "dashboard",
    labelDisable: true,
    children: [
      {
        name: "Dashboard",
        to: [MainRoutes.DASHBOARD],
        icon: faChartPie,
      },
      // {
      //   name: "Reports",
      //   to: "/reports/",
      //   icon: faClipboard,
      // },
    ]
  },
  {
    label: "governance",
    labelDisable: false,
    children: [
      {
        name: "Security Policy",
        to: [buildRoute(MainRoutes.GOVERNANCE, GovernanceRoutes.SECURITY_POLICY)],
        icon: faClipboardList,
      },
      // {
      //   name: "Strategic Plan",
      //   to: [buildRoute(MainRoutes.GOVERNANCE, GovernanceRoutes.STRATEGIC_PLAN)],
      //   icon: faSignsPost,
      // },
      {
        name: "Assess - SAMM",
        to: [buildRoute(MainRoutes.GOVERNANCE, GovernanceRoutes.SAMM_ASSESSMENTS)],
        icon: faClipboardQuestion,
      },
      // {
      //   name: "Integrations",
      //   to: "/integrations/",
      //   icon: faPlug,
      // },
    ]
  },
  {
    label: "configuration",
    labelDisable: false,
    children: [
      {
        name: "Settings",
        to: [MainRoutes.SETTINGS],
        icon: faCog,
      },
      // {
      //   name: "Integrations",
      //   to: "/integrations/",
      //   icon: faPlug,
      // },
    ]
  },
  // {
  //   label: "projects",
  //   labelDisable: false,
  //   children: [
  //     {
  //       name: "Telloport",
  //       to: "/projects/1/",
  //     },
  //     {
  //       name: "Magic Leap",
  //       to: "/projects/2/",
  //     },
  //   ]
  // },
]
