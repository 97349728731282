import {createContext} from "react";
import {Subscription, SubscriptionPlan} from "../../api/Subscription";

interface SubscriptionContextInterface {
  subscription: Subscription;
}

const SubscriptionContext = createContext<SubscriptionContextInterface>({
  subscription: {
    id: "",
    url: "",
    account: "",
    account_id: "",
    plan: SubscriptionPlan.TRIAL,
  },
});

export default SubscriptionContext;
