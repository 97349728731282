import {SubmitHandler, useForm} from "react-hook-form";
import {Button, Col, Form, Row} from "react-bootstrap";
import {SHOW_VALID_STYLE} from "../../helpers/utils";
import {CreateSAMMAssessmentFormInputs} from "../risk-management/samm-assessment/CreateSAMMAssessment";
import {ReactQuerySpinner} from "../common/ReactQueryLoader";
import React from "react";

export interface CreateSecurityPolicyFormInputs {
  name: string;
  description?: string;
}

interface NewSecurityPolicyFormProps {
  handleValidSubmit: (data: CreateSecurityPolicyFormInputs) => void;
  isDisabled?: boolean;
}

export default function NewSecurityPolicyForm({handleValidSubmit, isDisabled}: NewSecurityPolicyFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateSecurityPolicyFormInputs>();

  const onSubmit: SubmitHandler<CreateSAMMAssessmentFormInputs> = (data, event) => {
    event?.preventDefault();
    handleValidSubmit(data);
  }

    return (
    <Form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      // className="theme-wizard mb-5"
    >
      <Row>
        <Col xl={8}>
          <Form.Group className="mb-4 d-flex flex-column">
            <Form.Label className="mb-0 fw-bold">Name</Form.Label>
            <Form.Text className="text-700">
              The name for the Security Policy
            </Form.Text>
            <Form.Control
              type="input"
              disabled={isDisabled}
              {...register('name', {required: 'A name is required'})}
              isInvalid={errors['name'] !== undefined}
              isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors['name'] === undefined}
            />
            <Form.Control.Feedback type="invalid">
              {errors['name']?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4 d-flex flex-column">
            <Form.Label className="mb-0 fw-bold">Description</Form.Label>
            <Form.Text className="text-700">
              A description for the Security Policy
            </Form.Text>
            <Form.Control
              as="textarea"
              rows={4}
              type="input"
              disabled={isDisabled}
              {...register('description')}
              isInvalid={errors['description'] !== undefined}
              isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors['description'] === undefined}
            />
            <Form.Control.Feedback type="invalid">
              {errors['description']?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button type="submit" disabled={isDisabled}>Create</Button>
        </Col>
      </Row>
    </Form>
    )
}
