import {Alert, Card} from "react-bootstrap";
import FalconCardHeader from "../falcon/common/FalconCardHeader";
import {Link} from "react-router-dom";
import {buildRoute} from "../../routes/utils";
import {MainRoutes} from "../../routes/MainRoutes";
import {GovernanceRoutes} from "../../routes/GovernanceRoutes";
import {SecurityPolicyRoutes} from "../../routes/SecurityPolicyRoutes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import React, {useContext} from "react";
import {SecurityPolicy, useListSecurityPolicies} from "../../api/SecurityPolicy";
import AccountContext from "../context/AccountContext";
import {ReactQuerySpinner} from "../common/ReactQueryLoader";

function SecurityPoliciesList({securityPolicies}: {securityPolicies: SecurityPolicy[]}) {
  return (
    <>
      {
        securityPolicies.map((sp, index) => {
          return (
            <div key={sp.id} className="position-relative">
              <div
                className={
                  classNames({
                    "border-200 border-bottom": index !== securityPolicies.length - 1,
                    "pt-3": index !== 0,
                    "pb-3": index !== securityPolicies.length - 1,
                  })
                }
              >
                <div>
                  <Link className="fw-bold text-700 stretched-link" to={buildRoute(MainRoutes.GOVERNANCE, GovernanceRoutes.SECURITY_POLICY, SecurityPolicyRoutes.DETAIL).replace(":securityPolicyId", sp.id)}>{sp.name}</Link>
                </div>
                {
                  sp.description && (
                    <div className="fs--2 text-600 mt-1">{sp.description}</div>
                  )
                }
              </div>
            </div>
          )
        })
      }
    </>
  )
}

export default function SecurityPoliciesListCard() {
  const {account} = useContext(AccountContext);
  const securityPoliciesQuery = useListSecurityPolicies(undefined, {account_id: account.id});

  let listContent;
  if (securityPoliciesQuery.isError) {
    listContent = <Alert variant="danger">Could not load security policies</Alert>
  }
  else if (securityPoliciesQuery.isSuccess) {
    if (securityPoliciesQuery.data.results.length > 0) {
      listContent = <SecurityPoliciesList securityPolicies={securityPoliciesQuery.data.results} />
    }
    else {
      listContent = <div>No security policies.</div>
    }
  }
  else {
    listContent = <ReactQuerySpinner/>
  }

  return (
    <Card className="mb-4">
      <FalconCardHeader
        light
        titleTag="h5"
        title="Security Policies"
        endEl={
          <Link to={buildRoute(MainRoutes.GOVERNANCE, GovernanceRoutes.SECURITY_POLICY, SecurityPolicyRoutes.NEW)} className="btn btn-falcon-default btn-sm"><FontAwesomeIcon icon={faPlus}/> Add</Link>
        }
      />
      <Card.Body>
        {listContent}
      </Card.Body>
    </Card>
  )
}
