import {Button, Card, Table} from "react-bootstrap";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {useListUsers} from "../../api/User";
import React, {useContext, useState} from "react";
import AccountContext from "../context/AccountContext";
import ReactQueryLoader from "../common/ReactQueryLoader";
import {mergeUserRolesIntoUsers, useListUserRoles, UserWithUserRoles} from "../../api/UserRole";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InviteMemberModal from "./InviteMemberModal";
import {useListAllInvitations} from "../../api/Invitations";
import {MembersRow} from "./MembersRow";
import {InvitationsRow} from "./InvitationsRow";

export default function TeamMembersWidget() {
  const {account} = useContext(AccountContext);
  const [showAddMember, setShowAddMember] = useState(false);
  const [activeRowItemId, setActiveRowItemId] = useState<string | null>(null);
  const usersQuery = useListUsers(undefined, {account_id: account.id});
  const userRolesQuery = useListUserRoles(undefined, {account_id: account.id});
  const invitations = useListAllInvitations(undefined, {account_id: account.id, is_active: "1"});

  let usersWithUserRoles: UserWithUserRoles[] | undefined;
  if (usersQuery.data && userRolesQuery.data) {
    usersWithUserRoles = mergeUserRolesIntoUsers(usersQuery.data.results, userRolesQuery.data.results);
  }

  const createRowClickHandler = (itemId: string) => {
    return () => {
      if (activeRowItemId === itemId) {
        setActiveRowItemId(null);
      }
      else {
        setActiveRowItemId(itemId);
      }
    }
  }

  return (
    <Card>
      <Card.Body className="p-0">
        <ReactQueryLoader queryResult={usersQuery}>
          <Table
            responsive
            borderless
            className="mb-0 fs--1 border-200 rounded-3 table-dashboard table-member-info"
          >
            <thead className="bg-light">
            <tr className="text-900">
              <th>Team members</th>
              <th className="text-end">Last login</th>
            </tr>
            </thead>
            <tbody>
            {usersQuery.data && usersQuery.data.results.map((user, index) => (
              <MembersRow
                key={user.id}
                user={user}
                userWithUserRoles={usersWithUserRoles?.find(userWithUserRole => userWithUserRole.id === user.id)}
                isActive={user.id === activeRowItemId}
                onClick={createRowClickHandler(user.id)}
                isLast={false}
                // isLast={index === usersQuery.data.results.length - 1 && (invitations === null || invitations.length === 0)}
              />
            ))}
            {
              invitations && invitations.length > 0 && (
                <>
                  <tr className="bg-light text-900">
                    <th>Invitations</th>
                    <th className="text-end">Sent</th>
                  </tr>
                  {
                    invitations.map((invitation, index) => (
                      <InvitationsRow
                        key={invitation.id}
                        invitation={invitation}
                        isActive={invitation.id === activeRowItemId}
                        onClick={createRowClickHandler(invitation.id)}
                        // isLast={index === invitations.length - 1}
                        isLast={false}
                      />
                    ))
                  }
                </>
              )
            }
            </tbody>
          </Table>
        </ReactQueryLoader>
      </Card.Body>
      <Card.Footer className="bg-light p-0">
        <Button
          variant="link"
          size="sm"
          className="w-100 py-2"
          onClick={() => {setShowAddMember(true)}}
        >
          <FontAwesomeIcon
            icon={faPlusCircle}
            className="fs--2 me-1"
          />
          Invite new member
        </Button>
      </Card.Footer>
      <InviteMemberModal show={showAddMember} onHide={() => {setShowAddMember(false)}} />
    </Card>
  )
}
