import {Col, Row} from "react-bootstrap";
import AccountSettingsWidget from "../components/settings/AccountSettingsWidget";
import TeamMembersWidget from "../components/settings/TeamMembersWidget";


export default function AccountSettingsPage() {
  return (
    <main>
      <Row>
        <Col>
          <h2 className="mt-2 mb-4">Account settings</h2>
        </Col>
      </Row>
      <Row className="g-3">
        <Col>
          <TeamMembersWidget />
        </Col>
        <Col xxl={3} lg={4}>
          <AccountSettingsWidget editable={true}/>
        </Col>
      </Row>
    </main>
  )
}
