import {Account} from "./Account";
import {
  apiCreate,
  apiDelete,
  apiDetail,
  apiList,
  ApiViewSet,
  DetailOptions,
  getEndpoint,
  Page,
  useAll,
  useCreate,
  useDelete,
  useListInfinite,
  useRetrieve
} from "./baseApi";
import {ApiError, FetchOptions, postApi} from "./utils";
import {UseInfiniteQueryOptions, UseMutationOptions, UseQueryOptions} from "react-query";
import {Role} from "./Role";
import {ApiQueryParams} from "./baseQueryParams";

export interface Invitation {
  id: string;
  account?: string | null;
  account_id?: string | null;
  account_obj?: Account | null;
  user_id?: string | null;
  user_email_str: string;
  roles: Role[];
  is_active: boolean;
  created_date: string;
}

export interface LookupInvitation {
  code: string;
}

export interface RejectInvitation {
  code: string;
}

export interface AcceptInvitation {
  code: string;
}

export interface AcceptInvitationCreateUser extends AcceptInvitation {
  user_first_name: string;
  user_last_name: string;
  user_email: string;
  user_password: string;
  user_accepted_terms_and_conditions: "2022_10_01" | "";
}

export interface AcceptInvitationCreateOrganizationAndAccount extends AcceptInvitation {
  organization_name: string;
  organization_email: string;
  organization_address_line_1: string;
  organization_address_line_2?: string | null;
  organization_address_postal_code: string;
  organization_address_city: string;
  organization_address_country: string;
  organization_tax_number?: string | null;
  account_name?: string | null;
}

export interface CreateInvitation {
  account_id: string;
  role_ids: string[];
  email: string;
}

export interface AcceptInvitationCreateUserOrganizationAndAccount extends AcceptInvitationCreateUser, AcceptInvitationCreateOrganizationAndAccount {}

const invitationViewSet: ApiViewSet = {
  baseName: 'invitations',
  endpointPrefix: '/auth',
  endpoints: {
    lookup: "/auth/invitations/lookup/",
    accept: "/auth/invitations/{id}/accept/",
    reject: "/auth/invitations/{id}/reject/",
  }
}

enum InvitationQueryParams {
  ACCOUNT_ID = "account_id",
  IS_ACTIVE = "is_active",
}

function listInvitations(queryParams?: ApiQueryParams<InvitationQueryParams> | null, fetchOptions?: FetchOptions): () => Promise<Page<Invitation>> {
  return apiList<Invitation, InvitationQueryParams>(invitationViewSet, queryParams, fetchOptions);
}

function retrieveInvitation(options: DetailOptions, queryParams?: ApiQueryParams<InvitationQueryParams>, fetchOptions?: FetchOptions): () => Promise<Invitation> {
  return apiDetail({viewSet: invitationViewSet, options: options, queryParams: queryParams, fetchOptions: fetchOptions});
}

function createInvitation(fetchOptions?: FetchOptions): (variables: CreateInvitation) => Promise<Invitation> {
  return apiCreate<CreateInvitation, Invitation>(invitationViewSet, fetchOptions);
}

function deleteInvitation(fetchOptions?: FetchOptions): (variables: Invitation) => Promise<void> {
  return apiDelete<Invitation>(invitationViewSet, fetchOptions);
}

function acceptInvitation(options: DetailOptions, fetchOptions?: FetchOptions): (variables: AcceptInvitation) => Promise<Invitation> {
  return async (variables: AcceptInvitation): Promise<Invitation> => {
    const endpoint = getEndpoint(invitationViewSet, "accept", options.id);

    console.debug("Performing accept invitation operation", endpoint);
    let response = await postApi(endpoint, variables, fetchOptions);
    if (!response.ok) {
      let json;
      try {
        json = await response.json();
      } catch (e) {
        throw new ApiError(`Error accepting ${invitationViewSet.baseName}`, response.status)
      }
      throw new ApiError(`Error accepting ${invitationViewSet.baseName}`, response.status, json);
    }
    return await response.json();
  }
}

function rejectInvitation(options: DetailOptions, fetchOptions?: FetchOptions): (variables: RejectInvitation) => Promise<Invitation> {
  return async (variables: RejectInvitation): Promise<Invitation> => {
    const endpoint = getEndpoint(invitationViewSet, "reject", options.id);

    console.debug("Performing reject invitation operation", endpoint);
    let response = await postApi(endpoint, variables, fetchOptions);
    if (!response.ok) {
      let json;
      try {
        json = await response.json();
      } catch (e) {
        throw new ApiError(`Error rejecting ${invitationViewSet.baseName}`, response.status)
      }
      throw new ApiError(`Error rejecting ${invitationViewSet.baseName}`, response.status, json);
    }
    return await response.json();
  }
}

function lookupInvitation(fetchOptions?: FetchOptions): (variables: LookupInvitation) => Promise<Invitation> {
  return async (variables: AcceptInvitation): Promise<Invitation> => {
    const endpoint = getEndpoint(invitationViewSet, "lookup");

    console.debug("Performing lookup invitation operation", endpoint);
    let response = await postApi(endpoint, variables, fetchOptions);
    if (!response.ok) {
      let json;
      try {
        json = await response.json();
      } catch (e) {
        throw new ApiError(`Error lookup ${invitationViewSet.baseName}`, response.status)
      }
      throw new ApiError(`Error lookup ${invitationViewSet.baseName}`, response.status, json);
    }
    return await response.json();
  }
}

export function useListAllInvitations(url?: string, queryParams?: ApiQueryParams<InvitationQueryParams> | null, queryOptions?: UseInfiniteQueryOptions<Page<Invitation>, unknown, Page<Invitation>>, fetchOptions?: FetchOptions): Invitation[] | null {
  const query = useListInfinite({
    viewSet: invitationViewSet,
    queryParamsEnum: InvitationQueryParams,
    fetchFn: listInvitations,
    url: url,
    queryParams: queryParams,
    queryOptions: queryOptions,
    fetchOptions: fetchOptions,
  })

  return useAll(query);
}

export function useRetrieveInvitation(code?: string, queryOptions?: UseQueryOptions<Invitation, ApiError>,fetchOptions?: FetchOptions) {
  const qOptions = {
    enabled: !!code,
    ...queryOptions,
  }

  return useRetrieve({
    viewSet: invitationViewSet,
    queryParamsEnum: InvitationQueryParams,
    fetchFn: retrieveInvitation,
    detail: {id: code ? code : ""},  // The code is used as the id/pk
    queryOptions: qOptions,
    fetchOptions: fetchOptions,
  });
}

export function useCreateInvitation(options?: UseMutationOptions<Invitation, unknown, CreateInvitation>, fetchOptions?: FetchOptions) {
  return useCreate({
    viewSet: invitationViewSet,
    fetchFn: createInvitation,
    options: options,
    fetchOptions: fetchOptions,
  });
}

export function useLookupInvitation(options?: UseMutationOptions<Invitation, ApiError, LookupInvitation>, fetchOptions?: FetchOptions) {
  return useCreate({
    viewSet: invitationViewSet,
    fetchFn: lookupInvitation,
    options: options,
    fetchOptions: fetchOptions,
  })
}

export function useRejectInvitation(detailOptions: DetailOptions, options?: UseMutationOptions<Invitation, unknown, RejectInvitation>, fetchOptions?: FetchOptions) {
  return useCreate({
    viewSet: invitationViewSet,
    fetchFn: rejectInvitation,
    detailOptions: detailOptions,
    options: options,
    fetchOptions: fetchOptions,
  })
}

export function useAcceptInvitation(detailOptions: DetailOptions, options?: UseMutationOptions<Invitation, unknown, AcceptInvitation>, fetchOptions?: FetchOptions) {
  return useCreate({
    viewSet: invitationViewSet,
    fetchFn: acceptInvitation,
    detailOptions: detailOptions,
    options: options,
    fetchOptions: fetchOptions,
  })
}

export function useAcceptInvitationCreateUser(detailOptions: DetailOptions, options?: UseMutationOptions<Invitation, unknown, AcceptInvitationCreateUser>, fetchOptions?: FetchOptions) {
  return useCreate({
    viewSet: invitationViewSet,
    fetchFn: acceptInvitation,
    detailOptions: detailOptions,
    options: options,
    fetchOptions: fetchOptions,
  })
}

export function useAcceptInvitationCreateOrganizationAndAccount(detailOptions: DetailOptions, options?: UseMutationOptions<Invitation, unknown, AcceptInvitationCreateOrganizationAndAccount>, fetchOptions?: FetchOptions) {
  return useCreate({
    viewSet: invitationViewSet,
    fetchFn: acceptInvitation,
    detailOptions: detailOptions,
    options: options,
    fetchOptions: fetchOptions,
  })
}

export function useAcceptInvitationCreateUserOrganizationAndAccount(detailOptions: DetailOptions, options?: UseMutationOptions<Invitation, unknown, AcceptInvitationCreateUserOrganizationAndAccount>, fetchOptions?: FetchOptions) {
  return useCreate({
    viewSet: invitationViewSet,
    fetchFn: acceptInvitation,
    detailOptions: detailOptions,
    options: options,
    fetchOptions: fetchOptions,
  })
}

export function useDeleteInvitation(options?: UseMutationOptions<void, unknown, Invitation>, fetchOptions?: FetchOptions) {
  return useDelete({
    viewSet: invitationViewSet,
    deleteFn: deleteInvitation,
    options: options,
    fetchOptions: fetchOptions,
  })
}
