import {useParams} from "react-router-dom";
import React, {useContext, useState} from "react";
import AccountContext from "../../components/context/AccountContext";
import {useRetrieveSecurityPolicy, useUpdateSecurityPolicy} from "../../api/SecurityPolicy";
import {Alert, Badge, Button, Col, Row} from "react-bootstrap";
import Flex from "../../components/falcon/common/Flex";
import {ReactQuerySpinner} from "../../components/common/ReactQueryLoader";
import SecurityPolicyDocumentation from "../../components/security-policy/SecurityPolicyDocumentation";
import {RiskClassificationCard} from "../../components/security-policy/RiskClassificationCard";
import {DevelopmentSecurityPracticesCard} from "../../components/security-policy/DevelopmentSecurityPracticesCard";
import SecurityPoliciesListCard from "../../components/security-policy/SecurityPoliciesListCard";
import {ASVSInformationCard} from "../../components/security-policy/ASVSInformationCard";
import {GeneralSettingsCard} from "../../components/security-policy/GeneralSettingsCard";
import SecurityPolicyActivationButton from "../../components/security-policy/activation/SecurityPolicyActivationButton";
import SecurityPolicyActivationModal from "../../components/security-policy/activation/SecurityPolicyActivationModal";


export default function SecurityPolicyDetailPage() {
  const params = useParams();
  const {account} = useContext(AccountContext);
  const securityPolicyQuery = useRetrieveSecurityPolicy(params.securityPolicyId, undefined, {account_id: account.id});

  const [showActivationModal, setShowActivationModal] = useState(false)
  const securityPolicyMutation = useUpdateSecurityPolicy();

  if (securityPolicyQuery.isError) {
    if (securityPolicyQuery.error.status === 404) {
      return <Alert variant="danger">Security policy not found or incorrect permissions to access it.</Alert>
    }
    return <Alert variant="danger">Unexpected error. Could not retrieve Security policy information.</Alert>
  }
  else if (!securityPolicyQuery.isSuccess) {
    return <Flex justifyContent="center"><ReactQuerySpinner /></Flex>
  }

  const onConfirmActivation = () => {
    securityPolicyMutation.mutate({
      ...securityPolicyQuery.data,
      active: true,
    })
    setShowActivationModal(false);
  }

  return (
    <main>
      <SecurityPolicyActivationModal
        securityPolicy={securityPolicyQuery.data}
        show={showActivationModal}
        onHide={() => {setShowActivationModal(false)}}
        onConfirm={onConfirmActivation}
      />
      <Row>
        <Col>
          <h2 className="mt-2 mb-4 d-flex flex-row align-items-center justify-content-between">
            <div className="d-flex flex-row align-items-center">
              {securityPolicyQuery.data.name}
              {
                securityPolicyQuery.data.active
                  ? <Badge pill={true} bg="primary" className="ms-2 fs--1">active</Badge>
                  : <Badge pill={true} bg="secondary" className="ms-2 fs--1">inactive</Badge>
              }
            </div>
            {
              !securityPolicyQuery.data.active && (
                <SecurityPolicyActivationButton
                  onClick={() => {setShowActivationModal(true)}}
                  disabled={securityPolicyMutation.isLoading}
                />
              )
            }
          </h2>
          {
            securityPolicyMutation.isError && (
              <Alert variant="danger" dismissible={true} onClose={() => {securityPolicyMutation.reset()}}>
                Unable to activate security policy. Unknown error.
              </Alert>
            )
          }
        </Col>
      </Row>
      <Row className="g-3">
        <Col>
          <Row>
            <Col>
              <RiskClassificationCard securityPolicy={securityPolicyQuery.data}/>
              <DevelopmentSecurityPracticesCard securityPolicy={securityPolicyQuery.data}/>
              <ASVSInformationCard securityPolicy={securityPolicyQuery.data}/>
              <GeneralSettingsCard securityPolicy={securityPolicyQuery.data}/>
              {/*<Card className="mb-4">*/}
              {/*  <FalconCardHeader light titleTag="h5" title="Role Definitions"></FalconCardHeader>*/}
              {/*  <Card.Body>*/}
              {/*    <h5 className="mb-4 fw-bold">Simple Admin/Member</h5>*/}
              {/*    <p>*/}
              {/*      This role scheme assigns a user in the organization either the Member role with*/}
              {/*      default permissions to most actions, or the Admin role with elevated permissions allowing*/}
              {/*      configuring*/}
              {/*      account settings or managing users.*/}
              {/*    </p>*/}
              {/*    <p>*/}
              {/*      While this role scheme is very simple, it is often sufficient for small teams or organizations.*/}
              {/*    </p>*/}
              {/*  </Card.Body>*/}
              {/*</Card>*/}
            </Col>
          </Row>
        </Col>
        <Col xxl={4}>
          <Row>
            <Col>
              <SecurityPoliciesListCard/>
            </Col>
          </Row>
          {/*<Row>*/}
          {/*  <Col>*/}
          {/*    <SecurityPolicyDocumentation/>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
        </Col>
      </Row>
    </main>
  )

}
