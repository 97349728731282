import {isIterableArray} from "../helpers/utils";
import Flex from "./Flex";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import React from "react";

interface AvatarProps {
  size?: 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
  rounded?: string;
  src?: string | string[];
  name?: string;
  emoji?: string;
  className?: string;
  mediaClass?: string;
  isExact?: boolean;
  icon?: IconProp;
}

export default function Avatar({
  size = 'xl',
  rounded = 'circle',
  src,
  name,
  emoji = '😊',
  className,
  mediaClass,
  isExact = false,
  icon
}: AvatarProps) {
  const mainClassNames = ['avatar', `avatar-${size}`, className].join(' ');
  const mediaClasses = [
    rounded ? `rounded-${rounded}` : 'rounded',
    mediaClass
  ].join(' ');

  const getAvatar = () => {
    if (src) {
      if (isIterableArray(src)) {
        return (
          <div className={`${mediaClasses} overflow-hidden h-100 d-flex`}>
            <div className="w-50 border-right">
              <img src={src[0]} alt="" />
            </div>
            <div className="w-50 d-flex flex-column">
              <img src={src[1]} alt="" className="h-50 border-bottom" />
              <img src={src[2]} alt="" className="h-50" />
            </div>
          </div>
        );
      } else {
        return <img className={mediaClasses} src={src} alt="" />;
      }
    }

    if (name) {
      const nameMatch = name.match(/\b\w/g);
      return (
        <div className={`avatar-name ${mediaClasses}`}>
          <span>{isExact ? name : nameMatch? nameMatch.join('') : name}</span>
        </div>
      );
    }

    if (icon) {
      return (
        <Flex className={classNames("text-secondary", mediaClasses)}>
          <FontAwesomeIcon icon={icon} className="fs-4" />
        </Flex>
      );
    }

    return (
      <div className={`avatar-emoji ${mediaClasses}`}>
        <span role="img" aria-label="Emoji">
          {emoji}
        </span>
      </div>
    );
  };

  return <div className={mainClassNames}>{getAvatar()}</div>;
};

interface AvatarGroupProps {
  children: React.ReactNode;
  className?: string;
  dense?: boolean;
}

export function AvatarGroup({ children, dense, className }: AvatarGroupProps) {
  return (
    <div
      className={classNames(className, 'avatar-group', {
        'avatar-group-dense': dense
      })}
    >
      {children}
    </div>
  );
}
