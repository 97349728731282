import {Nav} from "react-bootstrap";
import ProfileDropdown from "./ProfileDropdown";

export default function TopNavRightSideNavItem() {
  // const {
  //   config: { isDark, isRTL },
  //   setConfig
  // } = useContext(AppContext);
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {/*<NotificationDropdown />*/}
      <ProfileDropdown />
    </Nav>
  );
};
