import React, {FunctionComponent} from "react";
import {LabelProps} from "@jsonforms/core";
import {withJsonFormsLabelProps} from "@jsonforms/react";
import {VanillaRendererProps, withVanillaControlProps} from "@jsonforms/vanilla-renderers";
import {Form} from "react-bootstrap";

const LabelRenderer: FunctionComponent<LabelProps & VanillaRendererProps> =
  ({ text, visible }) => {
    const isHidden = !visible;

    return (
      <Form.Label
        hidden={isHidden}
      >
        {text}
      </Form.Label>
    );
  };

export default withVanillaControlProps(withJsonFormsLabelProps(LabelRenderer));
