import {Route, Routes} from "react-router-dom";
import InvitationIndexPage from "../pages/invitation/InvitationIndexPage";
import InvitationCodePage from "../pages/invitation/InvitationCodePage";
import Error404 from "../components/falcon/errors/Error404";
import {InvitationRoutes} from "./InvitationRoutes";

export function InvitationRoutesComponent() {
  return (
    <Routes>
      <Route path={InvitationRoutes.INDEX} element={<InvitationIndexPage/>}/>
      <Route path={InvitationRoutes.INVITATION_CODE} element={<InvitationCodePage/>}/>
      <Route path="/*" element={<Error404 hardLink={false}/>}/>
    </Routes>

  )
}
