import {Invitation} from "../../api/Invitations";
import {useAuth} from "react-oidc-context";
import CreateAccountComponent from "./CreateAccountComponent";
import CreateUserAndAcceptInvitationComponent from "./CreateUserAndAcceptInvitationComponent";
import CreateUserAndAccountComponent from "./CreateUserAndAccountComponent";
import AcceptInvitationComponent from "./AcceptInvitationComponent";

interface NewAccountCheckComponentProps {
  invitation: Invitation;
  code: string;
}

export default function NewAccountCheckComponent({invitation, code}: NewAccountCheckComponentProps) {
  const auth = useAuth();

  if (auth.isAuthenticated) {
    if (invitation.account_obj) {
      // Authenticated and account is known. Show accept invitation component.
      return <AcceptInvitationComponent invitation={invitation} account={invitation.account_obj} code={code}/>
    } else {
      // Authenticated but no account. Create a new one.
      return <CreateAccountComponent invitation={invitation} code={code}/>
    }
  } else {
    if (invitation.account_obj) {
      // Not authenticated but account is known. Create user and accept invitation.
      return <CreateUserAndAcceptInvitationComponent invitation={invitation} account={invitation.account_obj} code={code}/>
    } else {
      return <CreateUserAndAccountComponent invitation={invitation} code={code}/>
    }
  }
}
