import React, {useContext, useState} from "react";
import AccountContext from "../context/AccountContext";
import {Role, useListRoles} from "../../api/Role";
import {useQueryClient} from "react-query";
import {useCreateUserRolesReplaceRequest} from "../../api/UserRolesReplaceRequest";
import {ReactQuerySpinner} from "../common/ReactQueryLoader";
import {Alert, Button, Col, Form, Modal, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightFromBracket} from "@fortawesome/free-solid-svg-icons";
import FalconCloseButton from "../falcon/common/FalconCloseButton";
import {userFullName} from "../../api/User";
import Flex from "../falcon/common/Flex";
import {SubmitHandler, useForm} from "react-hook-form";
import {SHOW_VALID_STYLE} from "../../helpers/utils";
import {UserWithUserRoles} from "../../api/UserRole";

interface UpdateMemberFormInput {
  role: string;
}

interface MembersRowUpdateFormProps {
  roles: Role[];
  user: UserWithUserRoles;
  isInProgress?: boolean;
  onChangeRole: (role: Role) => void;
}

function MembersRowUpdateForm({roles, user, isInProgress, onChangeRole}: MembersRowUpdateFormProps) {
  const {
    handleSubmit,
    register,
    formState: {
      dirtyFields,
      errors,
    }
  } = useForm<UpdateMemberFormInput>({
    defaultValues: {
      role: user.user_roles.length > 0 ? user.user_roles[0].role_id : undefined,
    }
  });
  const canChangeRole = !isInProgress && dirtyFields.role === true;

  const onSubmit: SubmitHandler<UpdateMemberFormInput> = (data, event) => {
    const role = roles.find(role => role.id === data.role);
    if (role && (user.user_roles.length !== 1 || user.user_roles[0].role_id !== role.id)) {
      onChangeRole(role);
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Role
        </Form.Label>
        <Col sm={6}>
          <Form.Select
            aria-label="select"
            {...register('role', {required: 'Select a role'})}
            disabled={isInProgress}
            isInvalid={errors['role'] !== undefined}
            isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors['role'] === undefined}
          >
            {
              roles.map(role => {
                return (
                  <option key={role.id} value={role.id}>{role.name}</option>
                )
              })
            }
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors['role']?.message}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Button type="submit" disabled={!canChangeRole} variant="primary">Save</Button>
        </Col>
      </Form.Group>
    </Form>
  )
}

interface MembersRowActiveRowProps {
  user?: UserWithUserRoles;
}

export function MembersRowActiveRow({user}: MembersRowActiveRowProps) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {account} = useContext(AccountContext)
  const rolesQuery = useListRoles();
  const queryClient = useQueryClient();
  const replaceRolesMutation = useCreateUserRolesReplaceRequest({
    onSuccess: () => {
      queryClient.invalidateQueries(["user-roles"])
      queryClient.invalidateQueries(["invitations"])
    }
  });

  let updateFormContent;
  if (rolesQuery.isLoading || !user) {
    updateFormContent = <ReactQuerySpinner/>;
  } else if (rolesQuery.isSuccess) {
    const onChangeRole = (role: Role) => {
      replaceRolesMutation.mutate({
        account_id: account.id,
        user_id: user.id,
        user_roles: [{
          role_id: role.id,
        }]
      })
    }

    updateFormContent = <MembersRowUpdateForm roles={rolesQuery.data.results} user={user} onChangeRole={onChangeRole}
                                              isInProgress={replaceRolesMutation.isLoading}/>
  } else {
    updateFormContent = <Alert variant="danger">An error occurred. Try again later.</Alert>
  }

  let removeUserButtonContent;
  if (user) {
    const onHide = () => {
      setShowDeleteModal(false)
    }

    const onShowModal = () => {
      setShowDeleteModal(true)
    }

    const onRemove = () => {
      setShowDeleteModal(false)
      replaceRolesMutation.mutate({
        account_id: account.id,
        user_id: user.id,
        user_roles: [],
      })
    }
    removeUserButtonContent = (
      <>
        <Button
          variant="danger"
          size="sm"
          className="ms-3 flex-grow-0"
          disabled={replaceRolesMutation.isLoading}
          onClick={onShowModal}
        >
          Remove <FontAwesomeIcon icon={faArrowRightFromBracket}/>
        </Button>
        <Modal size="sm" show={showDeleteModal} onHide={onHide}>
          <Modal.Body className="p-4">
            <FalconCloseButton
              size="sm"
              className="position-absolute top-0 end-0 me-2 mt-2"
              onClick={onHide}
            />
            <p>Do you want to remove user <span className="fw-bold">{userFullName(user)}</span> from your account?</p>
            <Button className="w-100 mb-3 mt-4" variant="secondary" onClick={onHide}>Cancel</Button>
            <Button className="w-100" variant="danger" onClick={onRemove}>Yes, remove from account</Button>
          </Modal.Body>
        </Modal>
      </>
    )
  }

  return (
    <tr className="border-bottom border-200">
      <td colSpan={2} className="p-0">
        <Flex>
          <div style={{width: "0.5rem"}} className="bg-soft-primary"></div>
          <Row className="flex-grow-1 py-3 px-4">
            <Col>
              {
                replaceRolesMutation.isError && (
                  <Row>
                    <Alert variant="danger" dismissible={true} onClose={() => {
                      replaceRolesMutation.reset()
                    }}>Unexpected error</Alert>
                  </Row>
                )
              }
              <Row className="justify-content-between">
                <Col>
                  {updateFormContent}
                </Col>
                <Col className="d-flex flex-row justify-content-end align-items-start">
                  {/*<Button variant="outline-secondary" size="sm"><FontAwesomeIcon icon={faEnvelope}/> Resend*/}
                  {/*  invitation</Button>*/}
                  {removeUserButtonContent}
                </Col>
              </Row>
            </Col>
          </Row>
        </Flex>
      </td>
    </tr>
  )
}