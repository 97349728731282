import AccountsInterceptPage from "../pages/AccountsInterceptPage";
import NavbarVertical from "../components/falcon/navbar/vertical/NavbarVertical";
import {routes} from "./routes";
import NavbarTop from "../components/falcon/navbar/top/NavbarTop";
import {Route, Routes} from "react-router-dom";
import Error404 from "../components/falcon/errors/Error404";
import {MainRoutes} from "./MainRoutes";
import DashboardWelcomePage from "../pages/DashboardWelcomePage";
import SubscriptionInterceptPage from "../pages/SubscriptionInterceptPage";
import AccountSettingsPage from "../pages/AccountSettingsPage";
import {GovernanceRoutesComponent} from "./GovernanceRoutesComponent";
import PricingPage from "../pages/PricingPage";

export function MainRoutesComponent() {
  return (
    <div className="container-fluid">
      <AccountsInterceptPage>
        <SubscriptionInterceptPage>
          <NavbarVertical routes={routes}/>
          <div className="content">
            <NavbarTop/>
            <Routes>
              <Route path={MainRoutes.DASHBOARD} element={<DashboardWelcomePage/>}/>
              <Route path={MainRoutes.GOVERNANCE} element={<GovernanceRoutesComponent/>}/>
              <Route path={MainRoutes.SETTINGS} element={<AccountSettingsPage/>}/>
              <Route path={MainRoutes.PRICING} element={<PricingPage/>}/>
              <Route path="/*" element={<Error404 hardLink={false}/>}/>
            </Routes>
          </div>
        </SubscriptionInterceptPage>
      </AccountsInterceptPage>
    </div>
  )
}