import {apiCreate, ApiViewSet, useCreate} from "./baseApi";
import {FetchOptions} from "./utils";
import {UseMutationOptions} from "react-query";


export interface CreateSupportRequest {
  name?: string;
  email?: string;
  account_id?: string;
  subject: string;
  message: string;
  additional_info?: string;
}

export interface SupportRequest extends CreateSupportRequest {
  id: string;
}

const supportRequestViewSet: ApiViewSet = {
  baseName: 'support-requests',
  endpointPrefix: '/support',
}

// enum SupportRequestQueryParams {
// }

function createSupportRequest(fetchOptions?: FetchOptions): (variables: CreateSupportRequest) => Promise<SupportRequest> {
  return apiCreate<CreateSupportRequest, SupportRequest>(supportRequestViewSet, fetchOptions);
}

export function useCreateSupportRequest(options?: UseMutationOptions<SupportRequest, unknown, CreateSupportRequest>, fetchOptions?: FetchOptions) {
  return useCreate({
    viewSet: supportRequestViewSet,
    fetchFn: createSupportRequest,
    options: options,
    fetchOptions: fetchOptions,
  });
}
