import {Control, DispatchCell, withJsonFormsControlProps} from "@jsonforms/react";
import {
  computeLabel,
  ControlProps,
  ControlState, isControl,
  isDescriptionHidden,
  NOT_APPLICABLE,
  RankedTester, rankWith
} from "@jsonforms/core";
import merge from "lodash/merge";
import maxBy from "lodash/maxBy";
import React from "react";
import {VanillaRendererProps, withVanillaControlProps} from "@jsonforms/vanilla-renderers";
import {Form} from "react-bootstrap";
import classNames from "classnames";

class InputControl extends Control<
  ControlProps & VanillaRendererProps,
  ControlState
> {
  render() {
    const {
      classNames: classNamesControl,
      description,
      id,
      errors,
      label,
      uischema,
      schema,
      rootSchema,
      visible,
      enabled,
      required,
      path,
      cells,
      config
    } = this.props;

    const isValid = errors.length === 0;

    const divClassNames = (
      classNamesControl
        ? ([classNamesControl.validation]
          .concat(isValid ? classNamesControl.description : classNamesControl.validationError)
          .join(' ')
        ) : ""
    )

    const appliedUiSchemaOptions = merge({}, config, uischema.options);
    const showDescription = !isDescriptionHidden(
      visible,
      description,
      this.state.isFocused,
      appliedUiSchemaOptions.showUnfocusedDescription
    );
    const testerContext = {
      rootSchema: rootSchema,
      config: config
    };
    const cell = maxBy(cells, r => r.tester(uischema, schema, testerContext));
    if (
      cell === undefined ||
      cell.tester(uischema, schema, testerContext) === NOT_APPLICABLE
    ) {
      console.warn('No applicable cell found.', uischema, schema);
      return null;
    } else {
      return (
        <Form.Group
          className={classNamesControl && classNames(classNamesControl.wrapper, "mb-3")}
          hidden={!visible}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          id={id}
        >
          <Form.Label htmlFor={id + '-input'} className={classNames(classNamesControl?.label, "fw-bold", {"mb-0": showDescription})}>
            {computeLabel(
              label,
              required === true,
              appliedUiSchemaOptions.hideRequiredAsterisk
            )}
          </Form.Label>
          <DispatchCell
            uischema={uischema}
            schema={schema}
            path={path}
            id={id + '-input'}
            enabled={enabled}
          />
          {
            showDescription && (
              <Form.Text className={divClassNames}>{description}</Form.Text>
            )
          }
          {
            !isValid && (
                <Form.Control.Feedback className={divClassNames} type="invalid">{errors}</Form.Control.Feedback>
              )
          }
        </Form.Group>
      );
    }
  }
}

export default withVanillaControlProps(withJsonFormsControlProps(InputControl));
