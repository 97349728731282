import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";

interface Error404Props {
  hardLink?: boolean;
}

export default function Error404({hardLink = true}: Error404Props) {
  const linkContent = (
    <>
      <FontAwesomeIcon icon={faHome} className="me-2" />
      Take me home
    </>
  )
  const link = hardLink
    ? (
      <a className="btn btn-primary btn-sm mt-3" href="/">
        {linkContent}
      </a>
    )
    : (
      <Link className="btn btn-primary btn-sm mt-3" to="/">
        {linkContent}
      </Link>
    )
  return (
    <Card className="text-center">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">404</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          The page you're looking for is not found.
        </p>
        <hr />
        <p>
          Make sure the address is correct and that the page hasn't moved. If
          you think this is a mistake,
          <a href="mailto:info@secuma.be" className="ms-1">
            contact us
          </a>
          .
        </p>
        {link}
      </Card.Body>
    </Card>
  );
};
