import {UserRole} from "./UserRole";
import {apiCreate, ApiViewSet, useCreate} from "./baseApi";
import {FetchOptions} from "./utils";
import {CreateSAMMAssessment, SAMMAssessment} from "./SAMMAssessment";
import {UseMutationOptions, useQueryClient} from "react-query";

interface UserRolesReplaceRequestUserRole {
  role_id: string;
}

export interface UserRolesReplaceRequest {
  account_id: string;
  user_id: string;
  user_roles?: UserRolesReplaceRequestUserRole[];
}

export interface UserRolesReplaceRequestResult extends UserRolesReplaceRequest {
  account: string;
  user: string;
  user_roles: UserRole[];
}

const userRolesReplaceRequestViewSet: ApiViewSet = {
  baseName: 'user-roles-replace-requests',
  endpointPrefix: '/auth',
}

enum UserRolesReplaceRequestQueryParams {
  ACCOUNT_ID = "account_id"
}

function createUserRolesReplaceRequest(fetchOptions?: FetchOptions): (variables: UserRolesReplaceRequest) => Promise<UserRolesReplaceRequestResult> {
  return apiCreate<UserRolesReplaceRequest, UserRolesReplaceRequestResult>(userRolesReplaceRequestViewSet, fetchOptions);
}

export function useCreateUserRolesReplaceRequest(options?: UseMutationOptions<UserRolesReplaceRequestResult, unknown, UserRolesReplaceRequest>, fetchOptions?: FetchOptions) {
  return useCreate({
    viewSet: userRolesReplaceRequestViewSet,
    fetchOptions: fetchOptions,
    options: options,
    fetchFn: createUserRolesReplaceRequest,
  })
}
