import React from "react";
import {Button, Col} from "react-bootstrap";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import SoftBadge from "../falcon/common/SoftBadge";
import {Link} from "react-router-dom";

export interface OptionFeature {
  id: string;
  title: string;
  tag?: {
    label: string;
    type: "primary" | "secondary" | 'success' | 'info' | 'warning' | 'danger' | 'light' | 'dark';
  };
}

interface OptionSelectionColProps {
  title: React.ReactNode;
  description: React.ReactNode;
  heroComponent?: React.ReactNode;
  buttonText?: string;
  isDisabled?: boolean;
  disabledDescription?: React.ReactNode;
  isFeatured?: boolean;
  featureTitle?: string;
  colSize?: number;
  features?: OptionFeature[];
  onSelect: () => void;
  moreInfoLink?: string;
  moreInfoText?: string;
}

export default function OptionSelectionCol({
  title,
  description,
  heroComponent,
  buttonText,
  isDisabled,
  disabledDescription,
  isFeatured,
  featureTitle,
  colSize = 4,
  features,
  onSelect,
  moreInfoLink,
  moreInfoText,
}: OptionSelectionColProps) {
  return (
    <Col
      lg={colSize}
      className={classNames('border-top border-bottom', {
        'dark__bg-1000 px-4 px-lg-0 border-400': isFeatured && !isDisabled,
        'bg-light text-500 border-200': isDisabled,
      })}
      style={{
        backgroundColor: !isDisabled && isFeatured ? 'var(--falcon-badge-soft-primary-background-color)' : undefined
      }}
    >
      <div className="h100">
        <div className="text-center p-4">
          <h3
            className={classNames(
              "fw-normal my-0",
              {"text-500": isDisabled},
            )}
          >{title}</h3>
          <p className="mt-3">{description}</p>
          {heroComponent}
          <Button
            variant={isDisabled ? 'outline-secondary' : isFeatured ? 'primary' : 'outline-primary'}
            disabled={isDisabled}
            onClick={onSelect}
            className="mb-4"
          >
            {buttonText || "Select"}
          </Button>
          {
            disabledDescription && (
              <p className="text-danger">{disabledDescription}</p>
            )
          }
        </div>
        <hr
          className={classNames(
            "border-bottom-0 m-0",
            {"text-400": isFeatured && !isDisabled},
            {"text-300": isDisabled},
          )}
        />
        <div className="text-start px-sm-4 py-4">
          <h5
            className={classNames(
              "fw-semi-bold fs-0",
              {"text-500": isDisabled},
            )}
          >{featureTitle}</h5>
          {
            features && (
              <ul className="list-unstyled mt-3">
                {features.map(feature => (
                  <li className="py-1" key={feature.id}>
                    <FontAwesomeIcon icon={faCheck} className="me-2 text-success" />{' '}
                    {feature.title}{' '}
                    {feature.tag && (
                      <SoftBadge pill bg={feature.tag.type}>
                        {feature.tag.label}
                      </SoftBadge>
                    )}
                  </li>
                ))}
              </ul>
            )
          }
          {
            moreInfoLink && (
              <p className="text-center mb-0">
                <Link to={moreInfoLink} className="text-center">
                  {moreInfoText || "More info"}
                </Link>
              </p>
            )
          }
        </div>
      </div>
    </Col>
  )
}
