import {DevelopmentSecurityPracticesFramework} from "../../api/DevelopmentSecurityPracticesFramework";
import React, {useContext} from "react";
import SubscriptionContext from "../context/SubscriptionContext";
import {getDevelopmentSecurityPracticesFrameworksForSubscription} from "../../api/Subscription";
import {Alert, Row} from "react-bootstrap";
import OptionSelectionCol from "../common/OptionSelectionCol";


interface DevelopmentSecurityPracticesFrameworkSelectionComponentProps {
  developmentSecurityPracticesFrameworks: DevelopmentSecurityPracticesFramework[];
  onSelect: (framework: DevelopmentSecurityPracticesFramework) => void;
}


export default function DevelopmentSecurityPracticesFrameworkSelectionComponent({developmentSecurityPracticesFrameworks, onSelect}: DevelopmentSecurityPracticesFrameworkSelectionComponentProps) {
  const {subscription} = useContext(SubscriptionContext)
  const developmentSecurityPracticesFrameworkAvailable = getDevelopmentSecurityPracticesFrameworksForSubscription(subscription) || [];
  const featuredItem = developmentSecurityPracticesFrameworks.find((item) => {
    return developmentSecurityPracticesFrameworkAvailable.includes(item.id);
  })

  return (
    <>
      <p>
        Choose a Development Security Practices Framework. Some commonly used examples are shown below.
      </p>
      {
        developmentSecurityPracticesFrameworks.length > 0
          ? (
            <Row className="g-0">
              {
                developmentSecurityPracticesFrameworks.map((developmentSecurityPracticesFramework, index) => {
                  const isAvailable = developmentSecurityPracticesFrameworkAvailable.includes(developmentSecurityPracticesFramework.id)
                  return (
                    <OptionSelectionCol
                      key={developmentSecurityPracticesFramework.id}
                      title={developmentSecurityPracticesFramework.name}
                      description={developmentSecurityPracticesFramework.description}
                      onSelect={() => {
                        onSelect(developmentSecurityPracticesFramework)
                      }}
                      isFeatured={featuredItem === developmentSecurityPracticesFramework}
                      isDisabled={!isAvailable}
                      disabledDescription={!isAvailable ? "Not available on your current plan." : undefined}
                      featureTitle={developmentSecurityPracticesFramework.tagline}
                      features={developmentSecurityPracticesFramework.features.map(feature => {
                        return {
                          id: feature,
                          title: feature,
                        }
                      })}
                    />
                  )
                })
              }
            </Row>
          )
          : (
            <Alert variant="warning">No usable Development Security Practices Framework available.</Alert>
          )
      }
    </>
  )
}
