import {Link, useLocation, useNavigate} from "react-router-dom";
import {RootRoutes} from "../../routes/RootRoutes";
import {useAuth} from "react-oidc-context";
import useLogout from "../hooks/useLogout";

export default function InvitationHeader() {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const logout = useLogout();

  return (
    <div className="d-flex justify-content-between align-items-center mb-4">
      <h5>Your invitation</h5>
      {
        !auth.isAuthenticated
          ? (
            <p className="fs--1 text-600 mb-0">
              Already a user? <Link to={RootRoutes.LOGIN} onClick={() => {navigate("/login", {state: {from: location.pathname}});}}>Login</Link>
            </p>
          )
          : (
            <p className="fs--1 text-600 mb-0">
              <button className="btn btn-link fs--1" onClick={() => {logout()}}>Logout</button>
            </p>
          )
      }
    </div>
  )
}
