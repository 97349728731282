import {Card} from "react-bootstrap";
import FalconCardHeader from "../falcon/common/FalconCardHeader";
import {useRetrieveUser} from "../../api/User";
import {Link} from "react-router-dom";
import {buildRoute} from "../../routes/utils";
import {GovernanceRoutes} from "../../routes/GovernanceRoutes";
import {MainRoutes} from "../../routes/MainRoutes";
import {useContext, useMemo} from "react";
import {cyrb128, mulberry32} from "../../helpers/rand";
import AccountContext from "../context/AccountContext";

const signatures = [
  "Linsy & Olivier",
  "Olivier & Linsy",
]

export default function WelcomeWidget() {
  const {account} = useContext(AccountContext);
  const user = useRetrieveUser({id: "me"});
  const signature = useMemo(() => {
    const seed = cyrb128(account.id);
    const rand = mulberry32(seed[0]);
    return signatures[Math.floor(rand() * signatures.length)]
  }, [account.id]);

  return (
    <Card>
      <FalconCardHeader title="Welcome to Secuma" light />
      <Card.Body>
        <p className="mb-4">
          Hi{(user.data && user.data.first_name !== "") ? ` ${user.data.first_name}` : ""}!
        </p>
        <p>
          Welcome to the beta period of Secuma - security management for developers. We are very happy that you've chosen
          to give Secuma a test run and hope that it makes your software more secure.
        </p>
        <p>
          Every security program starts with an assessment of the current security state. That's why the
          first tool available helps you perform what's called a <Link to={buildRoute(MainRoutes.GOVERNANCE, GovernanceRoutes.INDEX)}>SAMM assessment</Link>,
          a model designed by the <a href="https://owaspsamm.org/" target="_blank" rel="noreferrer">OWASP organization</a>.
          This exercise will give you insight in your current security practices and more importantly suggests a number
          of improvements. It is eventually up to you to select which improvements to implement, but Secuma will give
          a list of recommendations which it thinks will benefit you most.
        </p>
        <p>
          With a SAMM assessment you are off to a great start on your security management journey. Over time you will
          increase the security of your software, reduce incidents, and boost the confidence of your customers in your
          ability to protect their vital information. Our mission is to create software that will help you integrate
          security in the software development lifecycle and reduce or even eliminate the need for (costly) external
          expertise.
        </p>
        <p className="mb-4">
          If you find the service useful, consider a subscription. It will keep your data accessible after your trial
          ends and allow you instant access to our future tools and services. And while we're in the beta period all
          subscription prices are 50% off.
        </p>
        <p>
          Thank you for your support!
        </p>
        <p>
          {signature}<br/>
          Secuma founders
        </p>
      </Card.Body>
    </Card>
  )
}
