import {Modal} from "react-bootstrap";
import SupportFormComponent from "./SupportFormComponent";

interface SupportModalProps {
  showModal?: boolean;
  onHide?: () => void;
  onDone?: () => void;
  defaultSubject?: string;
  defaultMessage?: string;
  additionalInfo?: string;
}

export function SupportModal({
                               showModal,
                               onHide,
                               onDone,
                               defaultSubject,
                               defaultMessage,
                               additionalInfo
                             }: SupportModalProps) {
  return (
    <Modal show={showModal} onHide={onHide}>
      <Modal.Header closeButton={true}>
        <h4>Contact us</h4>
      </Modal.Header>
      <Modal.Body>
        <SupportFormComponent
          onDone={onDone}
          defaultSubject={defaultSubject}
          defaultMessage={defaultMessage}
          additionalInfo={additionalInfo}
        />
      </Modal.Body>
    </Modal>
  )
}
