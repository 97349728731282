import classNames from "classnames";
import {Card, Col, Row} from "react-bootstrap";
import React from "react";

interface TitleProps {
  titleTag?: keyof JSX.IntrinsicElements;
  breakPoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  className?: string;
  children?: React.ReactNode;
}

const Title = ({ titleTag: TitleTag = 'h5', className, breakPoint, children }: TitleProps) => (
  <TitleTag
    className={classNames(
      {
        'mb-0': !breakPoint,
        [`mb-${breakPoint}-0`]: !!breakPoint
      },
      className
    )}
  >
    {children}
  </TitleTag>
);

interface FalconCardHeaderProps {
  title: React.ReactNode;
  light?: boolean;
  breakPoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  endEl?: React.ReactNode;
  titleTag?: keyof JSX.IntrinsicElements;
  titleClass?: string;
  className?: string;
  children?: React.ReactNode;
}

export default function FalconCardHeader({
  title,
  light,
  titleTag,
  titleClass,
  className,
  breakPoint,
  endEl,
  children
}: FalconCardHeaderProps) {
  return (
    <Card.Header className={classNames(className, {'bg-light': light})}>
      {endEl ? (
        <Row className="align-items-center">
          <Col>
            <Title
              breakPoint={breakPoint}
              titleTag={titleTag}
              className={titleClass}
            >
              {title}
            </Title>
            {children}
          </Col>
          <Col
            {...{[breakPoint ? breakPoint : 'xs']: 'auto'}}
            className={`text${breakPoint ? `-${breakPoint}` : ''}-right`}
          >
            {endEl}
          </Col>
        </Row>
      ) : (
        <Title breakPoint={breakPoint} titleTag={titleTag} className={titleClass}>
          {title}
        </Title>
      )}
    </Card.Header>
  )
}
