import {Alert, Button, Card, Col, Form, Row} from "react-bootstrap";
import FalconCardHeader from "../falcon/common/FalconCardHeader";
import React, {useEffect} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {SecurityPolicy, SecurityPolicyReviewPeriod, useUpdateSecurityPolicy} from "../../api/SecurityPolicy";
import {SHOW_VALID_STYLE} from "../../helpers/utils";


interface SecurityPolicyGeneralSettingsFormInput {
  name?: string;
  description?: string;
  review_period?: SecurityPolicyReviewPeriod;
}

export function GeneralSettingsCard({securityPolicy}: {securityPolicy: SecurityPolicy}) {
  const updateSecurityPolicyMutation = useUpdateSecurityPolicy();

  const defaultValues = {
    name: securityPolicy.name,
    description: securityPolicy.description,
    review_period: securityPolicy.review_period,
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: {
      errors,
    }
  } = useForm<SecurityPolicyGeneralSettingsFormInput>({
    defaultValues: defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
    updateSecurityPolicyMutation.reset();
  }, [securityPolicy.id])

  const onSubmit: SubmitHandler<SecurityPolicyGeneralSettingsFormInput> = (data, event) => {
    event?.preventDefault();
    console.log("Submitted", data);
    updateSecurityPolicyMutation.mutate({
      ...securityPolicy,
      name: data.name || "",
      description: data.description || "",
      review_period: data.review_period || SecurityPolicyReviewPeriod.UNKNOWN,
    })
  }

  return (
    <Card className="mb-4">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FalconCardHeader light titleTag="h5" title="General settings"></FalconCardHeader>
        <Card.Body>
          {
            updateSecurityPolicyMutation.isError && (
              <Alert variant="danger" dismissible={true} onClose={() => {updateSecurityPolicyMutation.reset()}}>Could not update security policy general settings.</Alert>
            )
          }
          {
            updateSecurityPolicyMutation.isSuccess && (
              <Alert variant="success" dismissible={true} onClose={() => {updateSecurityPolicyMutation.reset()}}>Security policy updated successfully.</Alert>
            )
          }
          <Row>
            <Col xxl={8}>
              <Form.Group className="mb-4 d-flex flex-column">
                <Form.Label className="mb-0 fw-bold">Name</Form.Label>
                <Form.Text className="text-700 mb-1">
                  The name of this security policy
                </Form.Text>
                <Form.Control
                  type="input"
                  disabled={updateSecurityPolicyMutation.isLoading}
                  {...register('name')}
                  isInvalid={errors['name'] !== undefined}
                  isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors['name'] === undefined}
                >
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors['name']?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-4 d-flex flex-column">
                <Form.Label className="mb-0 fw-bold">Description</Form.Label>
                <Form.Text className="text-700 mb-1">
                  A longer description for the security policy.
                </Form.Text>
                <Form.Control
                  type="input"
                  disabled={updateSecurityPolicyMutation.isLoading}
                  {...register('description')}
                  isInvalid={errors['description'] !== undefined}
                  isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors['description'] === undefined}
                >
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors['description']?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-4 d-flex flex-column">
                <Form.Label className="mb-0 fw-bold">Policy review term</Form.Label>
                <Form.Text className="text-700 mb-1">
                  How often is this policy up for review.
                </Form.Text>
                <Form.Select
                  disabled={updateSecurityPolicyMutation.isLoading}
                  {...register('review_period')}
                  isInvalid={errors['review_period'] !== undefined}
                  isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors['review_period'] === undefined}
                >
                  <option value={SecurityPolicyReviewPeriod.UNKNOWN}>-----</option>
                  <option value={SecurityPolicyReviewPeriod.MONTHLY}>Monthly</option>
                  <option value={SecurityPolicyReviewPeriod.QUARTERLY}>Quarterly</option>
                  <option value={SecurityPolicyReviewPeriod.BI_ANNUAL}>Bi-annual</option>
                  <option value={SecurityPolicyReviewPeriod.ANNUAL}>Annual</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors['review_period']?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Button
            type="submit"
            disabled={updateSecurityPolicyMutation.isLoading}
          >
            Save
          </Button>
        </Card.Body>
      </Form>
    </Card>
  )
}
