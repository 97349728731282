import {apiList, ApiViewSet, Page, useList} from "./baseApi";
import {ApiQueryParams} from "./baseQueryParams";
import {FetchOptions} from "./utils";
import {User} from "./User";
import {UseQueryOptions, UseQueryResult} from "react-query";
import {Role} from "./Role";

export interface UserRole {
  url: string;
  id: string;
  role_obj: Role;
  role: string;
  role_id: string;
  user: string;
  user_id: string;
  account: string;
  account_id: string;
}

const userRoleViewSet: ApiViewSet = {
  baseName: 'user-roles',
  endpointPrefix: '/auth',
}

enum UserRoleQueryParams {
  ACCOUNT_ID = "account_id"
}

function listUserRoles(queryParams?: ApiQueryParams<UserRoleQueryParams> | null, fetchOptions?: FetchOptions): () => Promise<Page<UserRole>> {
  return apiList<UserRole, UserRoleQueryParams>(userRoleViewSet, queryParams, fetchOptions);
}

export function useListUserRoles(url?: string, queryParams?: ApiQueryParams<UserRoleQueryParams> | null, queryOptions?: UseQueryOptions<Page<UserRole>>, fetchOptions?: FetchOptions): UseQueryResult<Page<UserRole>> {
  return useList({
    viewSet: userRoleViewSet,
    queryParamsEnum: UserRoleQueryParams,
    fetchFn: listUserRoles,
    url: url,
    queryParams: queryParams,
    queryOptions: queryOptions,
    fetchOptions: fetchOptions,
  });
}

export interface UserWithUserRoles extends User {
  user_roles: UserRole[];
}

export function mergeUserRolesIntoUsers(users: User[], userRoles: UserRole[]): UserWithUserRoles[] {
  const userIdUserRoleMap = userRoles.reduce((result, item) => {
    let userRoles: UserRole[] | undefined = result.get(item.user_id);
    if (!userRoles) {
      userRoles = [];
    }
    userRoles.push(item);
    result.set(item.user_id, userRoles);
    return result;
  }, new Map<string, UserRole[]>());
  return users.map(user => {
    return {
      ...user,
      user_roles: userIdUserRoleMap.get(user.id) || [],
    }
  })
}
