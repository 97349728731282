import {UseFormReturn} from "react-hook-form/dist/types";
import {Form} from "react-bootstrap";
import {SHOW_VALID_STYLE} from "../../helpers/utils";

export default function CreateUserForm({form}: { form: UseFormReturn }) {
  const {
    register,
    formState: {
      errors,
    }
  } = form;

  return (
    <>
      <Form.Group className="mb-2 d-flex flex-column">
        <Form.Label className="mb-0 fw-bold">First name</Form.Label>
        <Form.Text>Your first name</Form.Text>
        <Form.Control
          type="input"
          {
            ...register(
              "userFirstName",
              {
                required: "A first name is required",
              }
            )
          }
          isInvalid={errors["userFirstName"] !== undefined}
          isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors["userFirstName"] === undefined}
        />
        <Form.Control.Feedback type="invalid">
          {errors["userFirstName"]?.message?.toString()}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2 d-flex flex-column">
        <Form.Label className="mb-0 fw-bold">Last name</Form.Label>
        <Form.Text>Your last name</Form.Text>
        <Form.Control
          type="input"
          {
            ...register(
              "userLastName",
              {
                required: "A last name is required",
              }
            )
          }
          isInvalid={errors["userLastName"] !== undefined}
          isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors["userLastName"] === undefined}
        />
        <Form.Control.Feedback type="invalid">
          {errors["userLastName"]?.message?.toString()}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2 d-flex flex-column">
        <Form.Label className="mb-0 fw-bold">Email</Form.Label>
        <Form.Text>Your email address. You will use this to login.</Form.Text>
        <Form.Control
          type="email"
          {
            ...register(
              "userEmail",
              {
                required: "An email address is required",
              }
            )
          }
          isInvalid={errors["userEmail"] !== undefined}
          isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors["userEmail"] === undefined}
        />
        <Form.Control.Feedback type="invalid">
          {errors["userEmail"]?.message?.toString()}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2 d-flex flex-column">
        <Form.Label className="mb-0 fw-bold">Password</Form.Label>
        <Form.Text>Choose a password. It must be at least 10 characters long.</Form.Text>
        <Form.Control
          type="password"
          {
            ...register(
              "userPassword",
              {
                required: "A password is required",
                minLength: {
                  value: 10,
                  message: "The password must be 10 characters long."
                },
              }
            )
          }
          isInvalid={errors["userPassword"] !== undefined}
          isValid={SHOW_VALID_STYLE && Object.keys(errors).length > 0 && errors["userPassword"] === undefined}
        />
        <Form.Control.Feedback type="invalid">
          {errors["userPassword"]?.message?.toString()}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  )
}
