import {Link, LinkProps} from "react-router-dom";
// import logo from "*.png";
import classNames from "classnames";

export enum LogoLocation {
  NavbarVertical = 'navbar-vertical',
  NavbarTop = 'navbar-top',
  Auth = 'auth',
}

interface LogoProps extends Partial<LinkProps> {
  at: LogoLocation;
  width?: number;
  className?: string;
  textClass?: string;
}

export default function Logo({ at, width, className, textClass, ...rest }: LogoProps) {
  const linkProps = {
    to: rest.to ? rest.to : "/",
    ...rest
  }
  return (
    <Link
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...linkProps}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center fw-bolder fs-5 mb-4': at === 'auth'
          },
          className
        )}
      >
        {/*<img className="me-2" src={logo} alt="Logo" width={width} />*/}
        <span className={classNames('font-sans-serif', textClass)}>Secuma</span>
      </div>
    </Link>
  );
}
