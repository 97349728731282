import {Collapse, Nav} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import {useContext, useState} from "react";
import classNames from "classnames";
import NavbarVerticalMenuItem from "./NavbarVerticalMenuItem";
import Route from "../../common/Route";
import {buildRoute, useIsActive} from "../../../../routes/utils";
import AppContext from "../../../context/AppContext";

interface RouteWithChildren extends Route {
  children: Route[];
}

const CollapseItems = ({ route }: { route: RouteWithChildren }) => {
  const { pathname } = useLocation();

  const openCollapse = (children: Route[]) => {
    const checkLink = (child: Route) => {
      if (buildRoute(...child.to) === pathname) {
        return true;
      }
      return (
        child.children &&
        child.children.some(checkLink)
      );
    };
    return children.some(checkLink);
  };

  const [open, setOpen] = useState(openCollapse(route.children));

  return (
    <Nav.Item as="li">
      <Nav.Link
        onClick={() => {
          setOpen(!open);
        }}
        className={classNames('dropdown-indicator cursor-pointer', {
          'text-500': !route.active
        })}
        aria-expanded={open}
        // {...route}
      >
        <NavbarVerticalMenuItem route={route} />
      </Nav.Link>
      <Collapse in={open}>
        <Nav className="flex-column nav" as="ul">
          <NavbarVerticalMenu routes={route.children} />
        </Nav>
      </Collapse>
    </Nav.Item>
  );
};

export default function NavbarVerticalMenu({ routes }: { routes: Route[] }) {
  const {
    showBurgerMenu,
    setShowBurgerMenu,
  } = useContext(AppContext);

  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setShowBurgerMenu(false);
    }
  };

  const isActive = useIsActive();

  return (
    <>
      {
        routes.map((route: Route) => {
          if (!route.children) {
            const active = isActive(...route.to);
            return (
              <Nav.Item as="li" key={route.name}>
                <Link
                  to={buildRoute(...route.to)}
                  onClick={handleNavItemClick}
                  className={
                    active ? 'active nav-link' : 'nav-link'
                  }
                >
                  <NavbarVerticalMenuItem route={route} />
                </Link>
              </Nav.Item>
            );
          }
          return <CollapseItems route={route as RouteWithChildren} key={route.name} />;
        })
      }
    </>
  );
};
