import {Nav} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Account} from "../../api/Account";
import Flex from "../falcon/common/Flex";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useContext} from "react";
import AccountContext from "../context/AccountContext";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import AppContext from "../context/AppContext";


interface NavbarAccountMenuItemProps {
  account: Account;
}

export default function NavbarAccountMenuItem({account}: NavbarAccountMenuItemProps) {
  const {account: currentAccount, setAccount: setCurrentAccount} = useContext(AccountContext);
  const {showNavbar} = useContext(AppContext);
  const active = currentAccount.id === account.id;

  return (
    <Nav.Item as="li" key={account.id}>
      <Link
        // end={route.exact}
        to={`/account/${account.id}`}
        className={
          active ? 'active nav-link' : 'nav-link'
        }
        onClick={(event) => {
          event.preventDefault();
          setCurrentAccount(account);
        }}
      >
        <Flex alignItems="center">
          {active && showNavbar && (
            <span className="nav-link-icon">
          <FontAwesomeIcon icon={faArrowRight} />
        </span>
          )}
          <span className="nav-link-text ps-1">{account.name !== "" ? account.name : account.id}</span>
          {/*{route.badge && (*/}
          {/*  <SoftBadge pill bg={route.badge.type} className="ms-2">*/}
          {/*    {route.badge.text}*/}
          {/*  </SoftBadge>*/}
          {/*)}*/}
        </Flex>
      </Link>
    </Nav.Item>
  )
}
