import React, {Suspense} from "react";
import {Route, Routes} from "react-router-dom";
import GovernancePage from "../pages/samm-assessment/GovernancePage";
import Error404 from "../components/falcon/errors/Error404";
import {GovernanceRoutes} from "./GovernanceRoutes";
import {ReactQuerySpinner} from "../components/common/ReactQueryLoader";
import SecurityPolicyRoutesComponent from "./SecurityPolicyRoutesComponent";

const SAMMAssessmentRoutesComponent = React.lazy(() => import("./SAMMAssessmentRoutesComponent"));

export function GovernanceRoutesComponent() {
  return (
    <Routes>
      <Route path={GovernanceRoutes.INDEX} element={<GovernancePage/>}/>
      <Route
        path={GovernanceRoutes.SAMM_ASSESSMENTS}
        element={
        <Suspense fallback={<ReactQuerySpinner/>}>
          <SAMMAssessmentRoutesComponent/>
        </Suspense>
      }
      />
      <Route
        path={GovernanceRoutes.SECURITY_POLICY}
        element={<SecurityPolicyRoutesComponent/>}
      />
      <Route path="/*" element={<Error404 hardLink={false}/>}/>
    </Routes>
  )
}
