import {CellProps} from "@jsonforms/core";
import merge from "lodash/merge";
import React from "react";
import {Form} from "react-bootstrap";
import {VanillaRendererProps} from "@jsonforms/vanilla-renderers";
import {withJsonFormsCellProps} from "@jsonforms/react";
import {withVanillaCellProps} from "./utils";

const TextCell = (props: CellProps & VanillaRendererProps) => {
  const {
    config,
    data,
    className,
    id,
    enabled,
    uischema,
    schema,
    path,
    handleChange
  } = props;
  const maxLength = schema.maxLength;
  const appliedUiSchemaOptions = merge({}, config, uischema.options);

  console.log("ClassName", className);

  return (
    <Form.Control
      type='text'
      value={data || ''}
      onChange={ev => handleChange(path, ev.target.value === '' ? undefined : ev.target.value)}
      className={className}
      id={id}
      disabled={!enabled}
      autoFocus={appliedUiSchemaOptions.focus}
      placeholder={appliedUiSchemaOptions.placeholder}
      maxLength={appliedUiSchemaOptions.restrict ? maxLength : undefined}
      // size={appliedUiSchemaOptions.trim ? maxLength : undefined}
    />
  );
};

export default withJsonFormsCellProps(withVanillaCellProps(TextCell));
