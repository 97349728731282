import {Card, Col, Row} from "react-bootstrap";
import SupportButton from "../common/SupportButton";
import SoftBadge from "../falcon/common/SoftBadge";
import PricingDefaultCard from "../falcon/pricing/PricingDefaultCard";
import {useState} from "react";
import {SubscriptionPlan} from "../../api/Subscription";
import SubscriptionPurchaseModal from "./SubscriptionPurchaseModal";
import {OptionFeature} from "../common/OptionSelectionCol";

export interface Plan {
  type: SubscriptionPlan;
  price: number | string;
  reducedPrice?: number | string;
  isFeatured?: boolean;
  features: PlanFeature[];
  title: string;
  subTitle: string;
  featureTitle: string;
}

export interface PlanFeature extends OptionFeature {}

const starterPlan: Plan = {
  type: SubscriptionPlan.STARTER,
  title: "Starter",
  subTitle: "For small teams that are just getting started with security management.",
  price: 40,
  reducedPrice: 20,
  featureTitle: "Assess and improve your security posture:",
  features: [
    {
      id: "accounts",
      title: "1 app",
    },
    {
      id: "users",
      title: "3 users",
    },
    {
      id: "reports",
      title: "standard reports",
    },
    {
      id: "support",
      title: "standard support",
    },
  ]
}

const proPlan: Plan = {
  type: SubscriptionPlan.PRO,
  title: "Pro",
  subTitle: "For when security becomes a serious part of your growing business.",
  price: 200,
  reducedPrice: 100,
  featureTitle: "Collaborate on different apps with multiple contributors:",
  isFeatured: true,
  features: [
    {
      id: "accounts",
      title: "3 apps",
    },
    {
      id: "users",
      title: "10 users",
    },
    {
      id: "reports",
      title: "full reports",
    },
    {
      id: "support",
      title: "premium support",
    },
  ]
}

const enterprisePlan: Plan = {
  type: SubscriptionPlan.ENTERPRISE,
  title: "Enterprise",
  subTitle: "For large enterprises in need of additional compliance options.",
  price: "contact us",
  featureTitle: "Increased authentication and compliance options:",
  features: [
    {
      id: "accounts",
      title: "multiple apps",
    },
    {
      id: "users",
      title: "multiple users",
    },
    {
      id: "compliance",
      title: "SSO and compliance options",
    },
    {
      id: "support",
      title: "premium support",
    },
  ]
}

const plans = [
  starterPlan,
  proPlan,
  enterprisePlan,
]

function PricingDefaultHeader() {
  return (
    <Col xs={12} className="mb-3">
      <Row className="justify-content-center justify-content-sm-between">
        <Col sm="auto" className="text-center">
          <h5 className="d-inline-block">Pricing</h5>
          <SoftBadge bg="success" className="ms-2" pill>
            Save 50% during beta
          </SoftBadge>
        </Col>
        {/*<Col*/}
        {/*  sm="auto"*/}
        {/*  as={Flex}*/}
        {/*  justifyContent="center"*/}
        {/*  alignItems="center"*/}
        {/*  className="mt-1 mt-sm-0"*/}
        {/*>*/}
        {/*  <FormCheck.Label htmlFor="custom-switch" className="me-2">*/}
        {/*    Monthly*/}
        {/*  </FormCheck.Label>*/}
        {/*  <Form.Check type="switch" id="yearly">*/}
        {/*    <Form.Check.Input type="checkbox" defaultChecked />*/}
        {/*    <Form.Check.Label className="ms-2 align-top">*/}
        {/*      Yearly*/}
        {/*    </Form.Check.Label>*/}
        {/*  </Form.Check>*/}
        {/*</Col>*/}
      </Row>
    </Col>
  )
}


export default function PricingDefaultContainer() {
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null)

  return (
    <Card className="mb-3">
      <Card.Body>
        <Row className="g-0">
          <PricingDefaultHeader />
          {
            plans.map(plan => (
              <PricingDefaultCard
                key={plan.title}
                title={plan.title}
                subTitle={plan.subTitle}
                reducedPrice={plan.reducedPrice}
                price={plan.price}
                isFeatured={plan.isFeatured}
                colSize={Math.max(12/plans.length, 3)}
                featureTitle={plan.featureTitle}
                features={plan.features}
                onSelect={() => {setSelectedPlan(plan)}}
              />
            ))
          }
          <Col xs={12} className="text-center">
            <h5 className="mt-5 mb-3">
              Want to use Secuma as a security consultant?
            </h5>
            <SupportButton additionalInfo="security consultant - contact us">Contact us</SupportButton>
          </Col>
        </Row>
      </Card.Body>
      <SubscriptionPurchaseModal plan={selectedPlan} show={selectedPlan !== null} onHide={() => {setSelectedPlan(null)}} />
    </Card>
  )
}
