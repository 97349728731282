import {apiList, ApiViewSet, Page, useList} from "./baseApi";
import {ApiQueryParams} from "./baseQueryParams";
import {FetchOptions} from "./utils";
import {UseQueryOptions, UseQueryResult} from "react-query";

export interface Role {
  url: string;
  id: string;
  name: string;
  account: string;
  account_id: string;
}


const roleViewSet: ApiViewSet = {
  baseName: 'roles',
  endpointPrefix: '/auth',
}

enum RoleQueryParams {
  ACCOUNT_ID = "account_id"
}

function listRoles(queryParams?: ApiQueryParams<RoleQueryParams> | null, fetchOptions?: FetchOptions): () => Promise<Page<Role>> {
  return apiList<Role, RoleQueryParams>(roleViewSet, queryParams, fetchOptions);
}

export function useListRoles(url?: string, queryParams?: ApiQueryParams<RoleQueryParams> | null, queryOptions?: UseQueryOptions<Page<Role>>, fetchOptions?: FetchOptions): UseQueryResult<Page<Role>> {
  return useList({
    viewSet: roleViewSet,
    queryParamsEnum: RoleQueryParams,
    fetchFn: listRoles,
    url: url,
    queryParams: queryParams,
    queryOptions: queryOptions,
    fetchOptions: fetchOptions,
  });
}
