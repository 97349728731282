import {apiDetail, apiList, apiUpdate, ApiViewSet, DetailOptions, Page, useList, useRetrieve} from "./baseApi";
import {ApiQueryParams} from "./baseQueryParams";
import {ApiError, FetchOptions} from "./utils";
import {UseQueryOptions, UseQueryResult} from "react-query";
import {SAMMAssessment, UpdateSAMMAssessment} from "./SAMMAssessment";


export interface RiskClassificationScheme {
  url: string;
  id: string;
  account: string;
  account_id: string;
  name: string;
  description: string;
  tagline: string;
  features: string[];
  parameters_json_schema: any;
}

const riskClassificationSchemeViewSet: ApiViewSet = {
  baseName: 'risk-classification-schemes',
  endpointPrefix: '/security-policy',
}

enum RiskClassificationSchemeQueryParams {
}

function listRiskClassificationSchemes(queryParams?: ApiQueryParams<RiskClassificationSchemeQueryParams> | null, fetchOptions?: FetchOptions): () => Promise<Page<RiskClassificationScheme>> {
  return apiList<RiskClassificationScheme, RiskClassificationSchemeQueryParams>(riskClassificationSchemeViewSet, queryParams, fetchOptions);
}

function retrieveRiskClassificationScheme(options: DetailOptions, queryParams?: ApiQueryParams<RiskClassificationSchemeQueryParams>, fetchOptions?: FetchOptions): () => Promise<RiskClassificationScheme> {
  return apiDetail({
    viewSet: riskClassificationSchemeViewSet,
    options: options,
    queryParams: queryParams,
    fetchOptions: fetchOptions,
  });
}

export function useListRiskClassificationSchemes(url?: string, queryParams?: ApiQueryParams<RiskClassificationSchemeQueryParams> | null, queryOptions?: UseQueryOptions<Page<RiskClassificationScheme>>, fetchOptions?: FetchOptions): UseQueryResult<Page<RiskClassificationScheme>> {
  return useList({
    viewSet: riskClassificationSchemeViewSet,
    queryParamsEnum: RiskClassificationSchemeQueryParams,
    fetchFn: listRiskClassificationSchemes,
    url: url,
    queryParams: queryParams,
    queryOptions: queryOptions,
    fetchOptions: fetchOptions,
  });
}


export function useRetrieveRiskClassificationScheme(riskClassificationSchemeId?: string | null, options?: UseQueryOptions<RiskClassificationScheme, ApiError>, queryParams?: ApiQueryParams<RiskClassificationSchemeQueryParams>, fetchOptions?: FetchOptions) {
  return useRetrieve({
    viewSet: riskClassificationSchemeViewSet,
    queryParamsEnum: RiskClassificationSchemeQueryParams,
    queryParams: queryParams,
    fetchFn: retrieveRiskClassificationScheme,
    detail: riskClassificationSchemeId ? {id: riskClassificationSchemeId} : undefined,
    fetchOptions: fetchOptions,
  })
}
