import {Card} from "react-bootstrap";
import FalconCardHeader from "../falcon/common/FalconCardHeader";
import React from "react";
import {SecurityPolicy} from "../../api/SecurityPolicy";
import {RiskClassificationCardController} from "./RiskClassificationCardController";


interface RiskClassificationCardProps {
  securityPolicy: SecurityPolicy;
}

export function RiskClassificationCard({securityPolicy}: RiskClassificationCardProps) {
  return (
    <Card className="mb-4">
      <FalconCardHeader light titleTag="h5" title="Risk Classification"></FalconCardHeader>
      <Card.Body>
        <RiskClassificationCardController securityPolicy={securityPolicy}/>
      </Card.Body>
    </Card>
  )
}
