import PricingDefaultContainer from "../components/pricing/PricingDefaultContainer";
import {Col, Row} from "react-bootstrap";


export default function PricingPage() {
  return (
    <main>
      <Row>
        <Col xxl={8} xl={10}>
          <PricingDefaultContainer />
        </Col>
      </Row>
    </main>
  )
}
