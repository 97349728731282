import {Alert, Button, Modal} from "react-bootstrap";
import {SecurityPolicy, useListSecurityPolicies} from "../../../api/SecurityPolicy";
import {useContext} from "react";
import AccountContext from "../../context/AccountContext";


interface SecurityPolicyActivationModalProps {
  securityPolicy: SecurityPolicy;
  show?: boolean;
  onHide?: () => void;
  onConfirm?: () => void;
}

export default function SecurityPolicyActivationModal({securityPolicy, show, onHide, onConfirm}: SecurityPolicyActivationModalProps) {
  const {account} = useContext(AccountContext);
  const activeSecurityPoliciesQuery = useListSecurityPolicies(undefined, {account_id: account.id, active: "1"})
  let deactivationWarning;
  if (activeSecurityPoliciesQuery.isSuccess) {
    if (activeSecurityPoliciesQuery.data.results.length > 0) {
      if (activeSecurityPoliciesQuery.data.results[0].name && activeSecurityPoliciesQuery.data.results[0].name !== "") {
        deactivationWarning = (
          <Alert variant="warning">
            Currently active security policy <span className="fw-bold">{activeSecurityPoliciesQuery.data.results[0].name}</span> will be deactivated.
          </Alert>
        )
      }
      else {
        deactivationWarning = (
          <Alert variant="warning">
            Currently active security policy <span className="fw-bold">{activeSecurityPoliciesQuery.data.results[0].id}</span> will be deactivated.
          </Alert>
        )
      }
    }
  }
  else {
    deactivationWarning = (
      <Alert variant="warning">
        Other active security policies will be deactivated.
      </Alert>
    )
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton={true}>Confirm Security Policy Activation</Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to activate this security policy{securityPolicy.name && securityPolicy.name !== "" && <span className="fw-bold"> {securityPolicy.name}</span>}?
        </p>
        {deactivationWarning}
      </Modal.Body>
      <Modal.Footer className="border-top-0 d-flex flex-row justify-content-between">
        <Button variant="secondary" onClick={() => {onHide && onHide()}}>Cancel</Button>
        <Button onClick={() => {onConfirm && onConfirm()}}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  )
}
