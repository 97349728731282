import {timeSince} from "../../helpers/utils";
import {DateTime} from "luxon";
import classNames from "classnames";
import Flex from "../falcon/common/Flex";
import Avatar from "../falcon/common/Avatar";
import {faBan, faCircleUser, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import SoftBadge from "../falcon/common/SoftBadge";
import React, {useState} from "react";
import {Alert, Button, Col, Modal, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FalconCloseButton from "../falcon/common/FalconCloseButton";
import {Invitation, useDeleteInvitation} from "../../api/Invitations";

interface InvitationsRowActiveRowProps {
  invitation: Invitation;
}

function InvitationsRowActiveRow({invitation}: InvitationsRowActiveRowProps) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deleteInvitationMutation = useDeleteInvitation();

  const onHide = () => {
    setShowDeleteModal(false)
  }

  const onShowModal = () => {
    setShowDeleteModal(true)
  }

  const onRemove = () => {
    setShowDeleteModal(false)
    deleteInvitationMutation.mutate(invitation);
  }

  return (
    <tr className="border-bottom border-200">
      <td colSpan={2} className="p-0">
        <Flex>
          <div style={{width: "0.5rem"}} className="bg-soft-primary"></div>
          <Row className="flex-grow-1 py-3 px-4">
            <Col>
              {
                deleteInvitationMutation.isError && (
                  <Row>
                    <Alert variant="danger" dismissible={true} onClose={() => {
                      deleteInvitationMutation.reset()
                    }}>Unexpected error</Alert>
                  </Row>
                )
              }
              <Row className="justify-content-between">
                {/*<Col>*/}
                {/*  <Button variant="outline-secondary" size="sm">*/}
                {/*    <FontAwesomeIcon icon={faEnvelope}/> Resend invitation*/}
                {/*  </Button>*/}
                {/*</Col>*/}
                <Col className="d-flex flex-row justify-content-end align-items-start">
                  <Button
                    variant="danger"
                    size="sm"
                    className="ms-3 flex-grow-0"
                    disabled={deleteInvitationMutation.isLoading}
                    onClick={onShowModal}
                  >
                    Cancel <FontAwesomeIcon icon={faBan}/>
                  </Button>
                  <Modal size="sm" show={showDeleteModal} onHide={onHide}>
                    <Modal.Body className="p-4">
                      <FalconCloseButton
                        size="sm"
                        className="position-absolute top-0 end-0 me-2 mt-2"
                        onClick={onHide}
                      />
                      <p>Do you want to cancel the invitation for <span className="fw-bold">{invitation.user_email_str}</span>?</p>
                      <Button className="w-100 mb-3 mt-4" variant="secondary" onClick={onHide}>Close</Button>
                      <Button className="w-100" variant="danger" onClick={onRemove}>Yes, cancel invitation</Button>
                    </Modal.Body>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
        </Flex>
      </td>
    </tr>
  )
}

interface InvitationsRowProps {
  invitation: Invitation;
  isActive: boolean;
  onClick: () => void;
  isLast: boolean;
}

export function InvitationsRow({invitation, isActive, onClick, isLast}: InvitationsRowProps) {
  const timeSinceInvitationSent = timeSince(DateTime.fromISO(invitation.created_date), undefined, 1);
  let sentColor: "warning" | "danger" | "success" = "danger";
  if (timeSinceInvitationSent) {
    if (timeSinceInvitationSent.as("days") < 7) {
      sentColor = "success";
    } else if (timeSinceInvitationSent.as("days") < 21) {
      sentColor = "warning";
    }
  }

  const onClickInternal = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    onClick();
  }

  return (
    <>
      <tr
        className={
          classNames(
            "position-relative",
            {
              'border-bottom border-200': !isLast && !isActive,
              "bg-soft-primary": isActive,
            }
          )
        }
      >
        <td>
          <Flex alignItems="center">
            <a href="/settings/" className="stretched-link" onClick={onClickInternal}>
              <Avatar
                size="2xl"
                icon={faCircleUser}
              />
            </a>
            <div className="flex-1 ms-3">
              <h6 className="mb-0 fw-semi-bold">
                <span className="text-dark">
                  {invitation.user_email_str}
                </span>
              </h6>
              <p className="fs--2 mb-0 text-500">Invited as {invitation.roles.map(role => role.name).join(", ")}</p>
            </div>
          </Flex>
        </td>
        <td className="align-middle">
          <Flex justifyContent="end">
            <SoftBadge pill bg={sentColor}>
              {
                timeSinceInvitationSent
                  ? `${timeSinceInvitationSent.reconfigure({locale: "en"}).toHuman()} ago`
                  : "never"
              }
            </SoftBadge>
          </Flex>
        </td>
      </tr>
      {
        isActive && (
          <InvitationsRowActiveRow invitation={invitation} />
        )
      }
    </>
  )
}