import {
  apiCreate,
  apiDetail,
  apiList,
  apiUpdate,
  ApiViewSet,
  DetailOptions,
  Page,
  useCreate,
  useList,
  useRetrieve,
  useUpdate
} from "./baseApi";
import {ApiQueryParams} from "./baseQueryParams";
import {ApiError, FetchOptions} from "./utils";
import {UseMutationOptions, UseQueryOptions, UseQueryResult} from "react-query";

export enum SecurityPolicyReviewPeriod {
  UNKNOWN = "",
  MONTHLY = "monthly",
  QUARTERLY = "quarterly",
  BI_ANNUAL = "bi-annual",
  ANNUAL = "annual",
}

export interface SecurityPolicy {
  url: string;
  id: string;
  account: string;
  account_id: string;
  name: string;
  description: string;
  review_period: SecurityPolicyReviewPeriod;
  active: boolean;
  active_datetime?: string | null;
  updated_datetime: string;
  risk_classification_scheme?: string | null;
  risk_classification_scheme_id?: string | null;
  risk_classification_parameters: any;
  development_security_practices_framework?: string | null;
  development_security_practices_framework_id?: string | null;
  development_security_practices_framework_parameters: any;
}

export interface UpdateSecurityPolicy {
  url: string;
  id: string;
  name: string;
  description: string;
  review_period: SecurityPolicyReviewPeriod;
  active: boolean;
  risk_classification_scheme_id?: string | null;
  risk_classification_parameters: any;
  development_security_practices_framework_id?: string | null;
  development_security_practices_framework_parameters: any;
}

export interface CreateSecurityPolicy {
  account_id: string;
  name?: string;
  description?: string;
}

const securityPolicyViewSet: ApiViewSet = {
  baseName: 'security-policies',
  endpointPrefix: '/security-policy',
}

enum SecurityPolicyQueryParams {
  ACCOUNT_ID = "account_id",
  ACTIVE = "active",
}

function listSecurityPolicies(queryParams?: ApiQueryParams<SecurityPolicyQueryParams> | null, fetchOptions?: FetchOptions): () => Promise<Page<SecurityPolicy>> {
  return apiList<SecurityPolicy, SecurityPolicyQueryParams>(securityPolicyViewSet, queryParams, fetchOptions);
}

function retrieveSecurityPolicy(options: DetailOptions, queryParams?: ApiQueryParams<SecurityPolicyQueryParams>, fetchOptions?: FetchOptions): () => Promise<SecurityPolicy> {
  return apiDetail({
    viewSet: securityPolicyViewSet,
    options: options,
    queryParams: queryParams,
    fetchOptions: fetchOptions,
  });
}

function createSecurityPolicy(fetchOptions?: FetchOptions): (variables: CreateSecurityPolicy) => Promise<SecurityPolicy> {
  return apiCreate<CreateSecurityPolicy, SecurityPolicy>(securityPolicyViewSet, fetchOptions);
}

function updateSecurityPolicy(fetchOptions?: FetchOptions): (variables: UpdateSecurityPolicy) => Promise<SecurityPolicy> {
  return apiUpdate<UpdateSecurityPolicy, SecurityPolicy>(securityPolicyViewSet, fetchOptions);
}

export function useListSecurityPolicies(url?: string, queryParams?: ApiQueryParams<SecurityPolicyQueryParams> | null, queryOptions?: UseQueryOptions<Page<SecurityPolicy>>, fetchOptions?: FetchOptions): UseQueryResult<Page<SecurityPolicy>> {
  return useList({
    viewSet: securityPolicyViewSet,
    queryParamsEnum: SecurityPolicyQueryParams,
    fetchFn: listSecurityPolicies,
    url: url,
    queryParams: queryParams,
    queryOptions: queryOptions,
    fetchOptions: fetchOptions,
  });
}


export function useRetrieveSecurityPolicy(securityPolicyId?: string, options?: UseQueryOptions<SecurityPolicy, ApiError>, queryParams?: ApiQueryParams<SecurityPolicyQueryParams>, fetchOptions?: FetchOptions) {
  return useRetrieve({
    viewSet: securityPolicyViewSet,
    queryParamsEnum: SecurityPolicyQueryParams,
    queryParams: queryParams,
    fetchFn: retrieveSecurityPolicy,
    detail: securityPolicyId ? {id: securityPolicyId} : undefined,
    fetchOptions: fetchOptions,
  })
}

export function useCreateSecurityPolicy(options?: UseMutationOptions<SecurityPolicy, unknown, CreateSecurityPolicy>, fetchOptions?: FetchOptions) {
  return useCreate({
    viewSet: securityPolicyViewSet,
    fetchOptions: fetchOptions,
    options: options,
    fetchFn: createSecurityPolicy,
  })
}

export function useUpdateSecurityPolicy(options?: UseMutationOptions<SecurityPolicy, unknown, UpdateSecurityPolicy>, fetchOptions?: FetchOptions) {
  return useUpdate({
    viewSet: securityPolicyViewSet,
    updateFn: updateSecurityPolicy,
    options: options,
    fetchOptions: fetchOptions,
  })
}
