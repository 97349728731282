import React, {useEffect} from "react";
import {useAuth} from "react-oidc-context";
import {useLocation, useNavigate} from "react-router-dom";


interface PrivateRouteProps {
  children?: React.ReactNode;
}

export default function PrivateRoute({ children }: PrivateRouteProps) {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!auth.isAuthenticated && !auth.isLoading && !auth.activeNavigator) {
      navigate("/login", {state: {from: location.pathname}, replace: true});
    }
  }, [auth.isAuthenticated, auth.isLoading, auth.activeNavigator, navigate, location])

  if (auth.isAuthenticated) {
    return <>{children}</>
  }
  else if (auth.isLoading || auth.activeNavigator) {
    return <div>Please wait...</div>
  }

  return <div>Authentication required. Redirecting you to login now. Please wait...</div>
}
