import {StyleDef} from "@jsonforms/vanilla-renderers/src/styles";
import filter from "lodash/filter";
import reduce from "lodash/reduce";
import join from "lodash/join";

export const findStyle = (styles: StyleDef[]) => (
  style: string,
  ...args: any[]
): string[] => {
  const foundStyles = filter(styles, s => s.name === style);
  return reduce(foundStyles, (res: string[], style: StyleDef) => {
    if (typeof style.classNames === 'function') {
      return res.concat(style.classNames(args));
    }
    return res.concat(style.classNames);
  }, []);
};

export const findStyleAsClassName = (styles: StyleDef[]) => (
  style: string,
  ...args: any[]
): string => join(findStyle(styles)(style, args), ' ');
