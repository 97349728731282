import {ApiQueryParams, queryEnabled, queryParamsToCacheKeys} from "./baseQueryParams";
import {ApiError, fetchApi, FetchOptions} from "./utils";
import {
  apiCreate,
  apiDetail,
  apiList,
  apiUpdate,
  ApiViewSet,
  DetailOptions,
  Page,
  useCreate,
  useList,
  useRetrieve,
  useUpdate
} from "./baseApi";
import {UseMutationOptions, useQuery, useQueryClient, UseQueryOptions} from "react-query";
import {useAuth} from "react-oidc-context";
import {SAMMImprovement} from "./SAMMImprovement";

export interface SAMMAssessment {
  id: string;
  url: string;
  name: string;
  description: string;
  quick_mode: boolean;
  answers: string[];
  account: string;
  account_id: string;
  answer_ids: string[];
  answers_url: string;
  stakeholders_ids: string[];
  suggested_improvements_url: string;
  assessment_improvements_url: string;
  selected_improvements_url: string;
}

export interface CreateSAMMAssessment {
  account_id: string;
  name?: string;
  description?: string;
}

export interface UpdateSAMMAssessment {
  id: string;
  url: string;
  name: string;
  description: string;
  quick_mode: boolean;
  stakeholders_ids: string[];
}

export interface SAMMAssessmentScoredImprovement extends SAMMImprovement {
  samm_maturity_improvement_score: number;
}

export interface SAMMAssessmentSelectedImprovement extends SAMMAssessmentScoredImprovement {
}

export interface SAMMAssessmentSuggestedImprovement extends SAMMAssessmentScoredImprovement{
  score: number;
}

const sammAssessmentViewSet: ApiViewSet = {
  baseName: 'samm-assessments',
  endpointPrefix: '/owasp-samm',
  endpoints: {
    suggested_improvements: "/owasp-samm/samm-assessments/{id}/suggested_improvements/",
    selected_improvements: "/owasp-samm/samm-assessments/{id}/selected_improvements/",
  }
}


enum SAMMAssessmentQueryParams {
  ACCOUNT_ID = "account_id"
}


export function listSAMMAssessments(queryParams?: ApiQueryParams<SAMMAssessmentQueryParams> | null, fetchOptions?: FetchOptions): () => Promise<Page<SAMMAssessment>> {
  return apiList<SAMMAssessment, SAMMAssessmentQueryParams>(sammAssessmentViewSet, queryParams, fetchOptions);
}

function retrieveSAMMAssessment(options: DetailOptions, queryParams?: ApiQueryParams<SAMMAssessmentQueryParams>, fetchOptions?: FetchOptions): () => Promise<SAMMAssessment> {
  return apiDetail({
    viewSet: sammAssessmentViewSet,
    options: options,
    queryParams: queryParams,
    fetchOptions: fetchOptions,
  });
}

function createSAMMAssessment(fetchOptions?: FetchOptions): (variables: CreateSAMMAssessment) => Promise<SAMMAssessment> {
  return apiCreate<CreateSAMMAssessment, SAMMAssessment>(sammAssessmentViewSet, fetchOptions);
}

function updateSAMMAssessment(fetchOptions?: FetchOptions): (variables: UpdateSAMMAssessment) => Promise<SAMMAssessment> {
  return apiUpdate<UpdateSAMMAssessment, SAMMAssessment>(sammAssessmentViewSet, fetchOptions);
}

function listSAMMAssessmentSuggestedImprovements(options: DetailOptions, fetchOptions?: FetchOptions): () => Promise<SAMMAssessmentSuggestedImprovement[]> {
  return async (): Promise<SAMMAssessmentSuggestedImprovement[]> => {
    let endpoint;
    if (options.url) {
      endpoint = options.url;
    }
    else {
      endpoint = sammAssessmentViewSet.endpoints!["suggested_improvements"]!.replace("{id}", `${options.id}`);
    }

    console.debug("Performing suggested_improvements operation", endpoint);
    let response = await fetchApi(endpoint, undefined, fetchOptions);
    if (!response.ok) {
      let json;
      try {
        json = await response.json();
      } catch (e) {
        throw new ApiError(`Error fetching suggested_improvements of ${sammAssessmentViewSet.baseName}`, response.status)
      }
      throw new ApiError(`Error fetching suggested_improvements of ${sammAssessmentViewSet.baseName}`, response.status, json);
    }
    return await response.json();
  };
}

function listSAMMAssessmentSelectedImprovements(options: DetailOptions, fetchOptions?: FetchOptions): () => Promise<SAMMAssessmentSelectedImprovement[]> {
  return async (): Promise<SAMMAssessmentSelectedImprovement[]> => {
    let endpoint;
    if (options.url) {
      endpoint = options.url;
    }
    else {
      endpoint = sammAssessmentViewSet.endpoints!["selected_improvements"]!.replace("{id}", `${options.id}`);
    }

    console.debug("Performing selected_improvements operation", endpoint);
    let response = await fetchApi(endpoint, undefined, fetchOptions);
    if (!response.ok) {
      let json;
      try {
        json = await response.json();
      } catch (e) {
        throw new ApiError(`Error fetching selected_improvements of ${sammAssessmentViewSet.baseName}`, response.status)
      }
      throw new ApiError(`Error fetching selected_improvements of ${sammAssessmentViewSet.baseName}`, response.status, json);
    }
    return await response.json();
  };
}

export function useListSAMMAssessments(queryParams?: ApiQueryParams<SAMMAssessmentQueryParams> | null, queryOptions?: UseQueryOptions<Page<SAMMAssessment>>, fetchOptions?: FetchOptions) {
  return useList({
    viewSet: sammAssessmentViewSet,
    queryParamsEnum: SAMMAssessmentQueryParams,
    fetchFn: listSAMMAssessments,
    queryParams: queryParams,
    queryOptions: queryOptions,
    fetchOptions: fetchOptions,
  });
}

export function useListSAMMAssessmentSuggestedImprovements(options?: DetailOptions, queryParams?: ApiQueryParams<SAMMAssessmentQueryParams> | null, queryOptions?: UseQueryOptions<SAMMAssessmentSuggestedImprovement[]>, fetchOptions?: FetchOptions) {
  const queryClient = useQueryClient();
  const auth = useAuth();

  const config = {
    enabled: !!options && queryEnabled(queryParams),
    onSuccess: (results: SAMMAssessmentSuggestedImprovement[]) => {
      // Set the slot detail cache as well
      for (const result of results) {
        if (result.id) {
          queryClient.setQueryData([sammAssessmentViewSet.baseName, "suggested_improvements", result.id], result);
        }
        if (result.url) {
          queryClient.setQueryData([sammAssessmentViewSet.baseName, "suggested_improvements", result.url], result);
        }
      }
    },
    ...queryOptions,
  };

  if (auth.user) {
    fetchOptions = {
      accessToken: auth.user.access_token,
      ...fetchOptions,
    };
  }

  return useQuery<SAMMAssessmentSuggestedImprovement[]>(
    [sammAssessmentViewSet.baseName, "suggested_improvements", queryParamsToCacheKeys(SAMMAssessmentQueryParams, queryParams)],
    listSAMMAssessmentSuggestedImprovements(options || {id: ""}, fetchOptions),
    config,
  );
}

export function useListSAMMAssessmentSelectedImprovements(options?: DetailOptions, queryParams?: ApiQueryParams<SAMMAssessmentQueryParams> | null, queryOptions?: UseQueryOptions<SAMMAssessmentSelectedImprovement[]>, fetchOptions?: FetchOptions) {
  const queryClient = useQueryClient();
  const auth = useAuth();

  const config = {
    enabled: !!options && queryEnabled(queryParams),
    onSuccess: (results: SAMMAssessmentSelectedImprovement[]) => {
      // Set the slot detail cache as well
      for (const result of results) {
        if (result.id) {
          queryClient.setQueryData([sammAssessmentViewSet.baseName, "selected_improvements", result.id], result);
        }
        if (result.url) {
          queryClient.setQueryData([sammAssessmentViewSet.baseName, "selected_improvements", result.url], result);
        }
      }
    },
    ...queryOptions,
  };

  if (auth.user) {
    fetchOptions = {
      accessToken: auth.user.access_token,
      ...fetchOptions,
    };
  }

  return useQuery<SAMMAssessmentSelectedImprovement[]>(
    [sammAssessmentViewSet.baseName, "selected_improvements", queryParamsToCacheKeys(SAMMAssessmentQueryParams, queryParams)],
    listSAMMAssessmentSelectedImprovements(options || {id: ""}, fetchOptions),
    config,
  );
}

export function useRetrieveSAMMAssessment(sammAssessmentId?: string, options?: UseQueryOptions<SAMMAssessment, ApiError>, queryParams?: ApiQueryParams<SAMMAssessmentQueryParams>, fetchOptions?: FetchOptions) {
  return useRetrieve({
    viewSet: sammAssessmentViewSet,
    queryParamsEnum: SAMMAssessmentQueryParams,
    queryParams: queryParams,
    fetchFn: retrieveSAMMAssessment,
    detail: {id: sammAssessmentId || ''},
    queryOptions: {
      enabled: sammAssessmentId !== undefined,
      ...options,
    },
    fetchOptions: fetchOptions,
  })
}


export function useCreateSAMMAssessment(options?: UseMutationOptions<SAMMAssessment, unknown, CreateSAMMAssessment>, fetchOptions?: FetchOptions) {
  return useCreate({
    viewSet: sammAssessmentViewSet,
    fetchOptions: fetchOptions,
    options: options,
    fetchFn: createSAMMAssessment,
  })
}

export function useUpdateSAMMAssessment(options?: UseMutationOptions<SAMMAssessment, unknown, UpdateSAMMAssessment>, fetchOptions?: FetchOptions) {
  return useUpdate({
    viewSet: sammAssessmentViewSet,
    updateFn: updateSAMMAssessment,
    options: options,
    fetchOptions: fetchOptions,
  })
}
