import {Button, Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {ButtonProps} from "react-bootstrap/Button";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

interface FalconCardFooterLinkProps extends Partial<ButtonProps> {
  title: string;
  bg?: string;
  borderTop?: boolean;
  to?: string;
  className?: string;
  icon?: IconProp;
  iconPosition?: "before" | "after"
}

export default function FalconCardFooterLink({
  title,
  bg = "light",
  borderTop,
  to = "#!",
  className,
  icon,
  iconPosition,
  ...rest
}: FalconCardFooterLinkProps) {
  const iconProp: IconProp = icon || faChevronRight;
  const iconComponent = (
    <FontAwesomeIcon
      icon={iconProp}
      className={
        classNames(
          "fs--2",
          {
            "me-1": iconPosition === "before",
            "ms-1": iconPosition !== "before",
          }
        )
      }
    />
  )

  return (
    <Card.Footer
      className={classNames(className, `bg-${bg} p-0`, {
        'border-top': borderTop
      })}
    >
      <Button
        as={Link as any}
        variant="link"
        size="lg"
        to={to}
        className="w-100 py-2"
        {...rest}
      >
        {iconPosition === "before" && iconComponent}
        {title}
        {iconPosition !== "before" && iconComponent}
      </Button>
    </Card.Footer>
  )
}
