import {useAuth} from "react-oidc-context";

export default function useLogout(): () => void {
  const auth = useAuth();
  return () => {
    auth.removeUser().then(() => {
      window.location.href = `${process.env.REACT_APP_API_URL!}/accounts/logout/`;
    })
  }
}
