import Flex from "../../common/Flex";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SoftBadge from "../../common/SoftBadge";
import Route from "../../common/Route";

export default function NavbarVerticalMenuItem({ route }: { route: Route }) {
  return (
    <Flex alignItems="center">
      {route.icon && (
        <span className="nav-link-icon">
          <FontAwesomeIcon icon={route.icon} />
        </span>
      )}
      <span className="nav-link-text ps-1">{route.name}</span>
      {route.badge && (
        <SoftBadge pill bg={route.badge.type} className="ms-2">
          {route.badge.text}
        </SoftBadge>
      )}
    </Flex>
  );
};
