import {ApiQueryParams} from "./baseQueryParams";
import {FetchOptions} from "./utils";
import {apiCreate, apiList, ApiViewSet, Page, useList} from "./baseApi";
import {useMutation, UseMutationOptions, UseQueryOptions, UseQueryResult} from "react-query";
import {useAuth} from "react-oidc-context";
import {RiskClassificationScheme} from "./RiskClassificationScheme";

export enum SubscriptionPlan {
  TRIAL = "trial",
  STARTER = "starter",
  PRO = "pro",
  ENTERPRISE = "enterprise",
}

export interface Subscription {
  url: string;
  id: string;
  account: string;
  account_id: string;
  plan: SubscriptionPlan;
  start_date?: string | null;
  end_date?: string | null;
}

export interface CreateSubscription {
  account_id: string;
  plan: SubscriptionPlan;
}

export interface CreateSubscriptionResult {
  account: string;
  account_id: string;
  plan: SubscriptionPlan;
  start_date: null;
  end_date: null;
}

const subscriptionViewSet: ApiViewSet = {
  baseName: 'subscriptions',
  endpointPrefix: '/billing',
}

enum SubscriptionQueryParams {
  ACCOUNT_ID = "account_id",
  ACTIVE = "active",
}


function listSubscriptions(queryParams?: ApiQueryParams<SubscriptionQueryParams> | null, fetchOptions?: FetchOptions): () => Promise<Page<Subscription>> {
  return apiList<Subscription, SubscriptionQueryParams>(subscriptionViewSet, queryParams, fetchOptions);
}

function createSubscription(fetchOptions?: FetchOptions): (variables: CreateSubscription) => Promise<CreateSubscriptionResult> {
  return apiCreate<CreateSubscription, CreateSubscriptionResult>(subscriptionViewSet, fetchOptions);
}

export function useListSubscriptions(url?: string, queryParams?: ApiQueryParams<SubscriptionQueryParams> | null, queryOptions?: UseQueryOptions<Page<Subscription>>, fetchOptions?: FetchOptions): UseQueryResult<Page<Subscription>> {
  return useList({
    viewSet: subscriptionViewSet,
    queryParamsEnum: SubscriptionQueryParams,
    fetchFn: listSubscriptions,
    url: url,
    queryParams: queryParams,
    queryOptions: queryOptions,
    fetchOptions: fetchOptions,
  });
}

export function useCreateSubscription(options?: UseMutationOptions<CreateSubscriptionResult, unknown, CreateSubscription>, fetchOptions?: FetchOptions) {
  const auth = useAuth();

  if (auth.user) {
    fetchOptions = {
      accessToken: auth.user.access_token,
      ...fetchOptions,
    };
  }

  let mutationProps: UseMutationOptions<CreateSubscriptionResult, unknown, CreateSubscription> = {
    mutationFn: createSubscription(fetchOptions),
    ...options,
  };

  return useMutation(mutationProps);
}


const riskClassificationSchemeSimpleQualitative = "a28ad3d0-4bbe-4481-b230-b558eb931591";
const riskClassificationSchemeRiskMatrix = "1057a202-683a-4dae-8920-f8eb7bef2b32";
const riskClassificationSchemeQuantitativeModel = "f496ec21-d13b-4b03-8031-d1c9e3105341";
const planRiskClassificationSchemesAvailableMap = new Map<SubscriptionPlan, string[]>();
planRiskClassificationSchemesAvailableMap.set(
  SubscriptionPlan.TRIAL,
  [
    riskClassificationSchemeSimpleQualitative,
  ]
);
planRiskClassificationSchemesAvailableMap.set(
  SubscriptionPlan.STARTER,
  [
    riskClassificationSchemeSimpleQualitative,
  ]
);
planRiskClassificationSchemesAvailableMap.set(
  SubscriptionPlan.PRO,
  [
    riskClassificationSchemeSimpleQualitative,
    riskClassificationSchemeRiskMatrix,
  ]
);
planRiskClassificationSchemesAvailableMap.set(
  SubscriptionPlan.ENTERPRISE,
  [
    riskClassificationSchemeSimpleQualitative,
    riskClassificationSchemeRiskMatrix,
    riskClassificationSchemeQuantitativeModel,
  ]
);

export function getRiskClassificationSchemesForSubscription(subscription: Subscription): string[] | undefined {
  return planRiskClassificationSchemesAvailableMap.get(subscription.plan)
}

const developmentSecurityPracticesFrameworkSAMM = "5dd86218-b135-4b36-90de-931708394bbb";
const planDevelopmentSecurityPracticesFrameworkAvailableMap = new Map<SubscriptionPlan, string[]>();
planDevelopmentSecurityPracticesFrameworkAvailableMap.set(
  SubscriptionPlan.TRIAL,
  [
    developmentSecurityPracticesFrameworkSAMM,
  ]
);
planDevelopmentSecurityPracticesFrameworkAvailableMap.set(
  SubscriptionPlan.STARTER,
  [
    developmentSecurityPracticesFrameworkSAMM,
  ]
);
planDevelopmentSecurityPracticesFrameworkAvailableMap.set(
  SubscriptionPlan.PRO,
  [
    developmentSecurityPracticesFrameworkSAMM
  ]
);
planDevelopmentSecurityPracticesFrameworkAvailableMap.set(
  SubscriptionPlan.ENTERPRISE,
  [
    developmentSecurityPracticesFrameworkSAMM,
    // "b99afebc-508a-48f0-b8b6-cf79ac302652",  // SSDF
  ]
);


export function getDevelopmentSecurityPracticesFrameworksForSubscription(subscription: Subscription): string[] | undefined {
  return planDevelopmentSecurityPracticesFrameworkAvailableMap.get(subscription.plan)
}
