import {SecurityPolicy} from "../../api/SecurityPolicy";
import {Card} from "react-bootstrap";
import FalconCardHeader from "../falcon/common/FalconCardHeader";
import React from "react";
import DevelopmentSecurityPracticesCardController from "./DevelopmentSecurityPracticesCardController";

interface DevelopmentSecurityPracticesCardProps {
  securityPolicy: SecurityPolicy;
}

export function DevelopmentSecurityPracticesCard({securityPolicy}: DevelopmentSecurityPracticesCardProps) {
  return (
    <Card className="mb-4">
      <FalconCardHeader light titleTag="h5" title="Development Security Practices Framework"></FalconCardHeader>
      <Card.Body>
        <DevelopmentSecurityPracticesCardController securityPolicy={securityPolicy}/>
      </Card.Body>
    </Card>
  )
}
